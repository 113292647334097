

.attendee-list,
.attendee-list-user-profile {
    /* width: 500px; */
    /* max-height: 750px; */
}

.attendee-list {
    padding-bottom: 16px;    
}

.attendee-list .cbit-label {
    color: var(--cbit-bondi-blue);
    cursor: pointer;
}

.attendees-table-container {
    max-height: 500px;
    overflow-y: auto;
}

.attendee-list ul {
    text-decoration: none;
    list-style: none;
    padding: 0;
}

.attendee-list ul li {
    padding: 0;
}

.attendee-list .cbit-data-grid-container {
    width: 750px;
}

.attendee-list-user-profile.cbit-dialog {
    max-width: 500px;    
}

.attendee-list-user-profile {
    overflow-y: auto;
}

.attendee-list .btn-cbit-link {
    text-transform: capitalize;
}

.attendee-list .btn-cbit-link:hover {
    color: var(--cbit-bondi-blue);
}

.attendee-list .cbit-table-cell {
    width: 100%;
}

.attendee-list-sort-icon  {
    width: 10px;
}

.attendee-list-sort-icon img {
    width: 100%;
}

.attendee-list-sort-container {
    cursor: pointer;
    color: var(--cbit-bondi-blue);    
    display: flex;
    gap: 10px;
}