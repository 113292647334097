.days-of-week-container {
    display: flex;
    flex-direction: row;
    margin: 0 0 16px;    
    gap: 12px;
}

.recurrence-checkbox-label {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    cursor: pointer;
    border: 1px solid var(--cbit-light-gray);
    color: var(--cbit-dark-blue);
    border-radius: 100%;
    font-size: 12px;
    text-align: center;
    width: 40px;
    height: 40px;
    /* background-color: var(--cbit-light-pastel-blue); */
    background-color: #fff;
}

.recurrence-checkbox-label:hover {
    border-color: var(--cbit-light-pastel-blue);
    background-color: var(--cbit-light-pastel-blue);
}

input.recurrence-checkbox[type="checkbox"]:checked + .recurrence-checkbox-label {
    border-color: var(--cbit-water);
    background-color: var(--cbit-water);
}

.recurrence-checkbox {
    position:absolute;
    height: 0;
    width: 0;
    opacity: 0;
}