.lesson-course-item-title {
    display: flex;
}

.admin-question-number {
    padding-right: 19px;    
}

.lesson-content-caret-down,
.lesson-content-caret-left  {
    height: 100%;
    width: 12px;
}

.lesson-content-caret-down img, 
.lesson-content-caret-left img {
    height: 100%;
    width: 100%;
}

.lesson-content-caret-down img {
    height: 9px;
}

.lesson-content-caret-left img {
    width: 9px;
}

.lesson-content-viewer-container {
    max-width: 704px;
    padding-top: 24px;        
}
