.referral-details {
    /* width: 45%;     */
    overflow-y: auto;
}

.referral-details .referral-info-container {
    gap:16px;
}

.referral-details .referral-image-container, 
.referral-details .referral-image-container .empty-image-container {
    width: 165px;
}

.referral-details .referral-profile-info .referral-image {
    width: auto;
}

.referral-details .referral-image-container .empty-image-container {
    width: 100%;
    height: 165px;
    
}
.referral-details .referral-image-container .referral-image {
    height: auto;
}

.referral-bio-container.text-ellipse {
    -webkit-line-clamp: 11;
}