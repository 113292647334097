.hidden-player-duration {
  display: none;
}

.video-form {
  width: 840px;
  min-height: 673px;  
  padding: 40px 32px 40px;
}

.video-form .form-input {
  width: 100%;
  height: 48px;
}

.video-form .form-input::placeholder {
  color: #afc6cc;
  font: normal normal normal 18px/32px var(--cbit-font);
}

.video-form .form-textarea {
  height: 116px;
}

.video-form-title {
  font: normal normal 600 24px/42px var(--cbit-font);
  letter-spacing: 0px;
  color: #00a3c1;
  text-transform: uppercase;
}

.video-btn-container {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  width: 100%;
  padding-top: 24px;
}

.video-btn-container .btn-cbit-minor,
.video-btn-container .btn-cbit-primary {
  height: 44px;
  text-align: center;
  font: normal normal 600 18px/34px var(--cbit-font);
  letter-spacing: 1.8px;
  text-transform: uppercase;
}

.video-btn-container .btn-cbit-minor {
  width: 134px;
}

.video-btn-container .btn-cbit-primary {
  width: 144px;
}

.tag-form-group {
  height: 136px;
  margin-bottom: 0;
}