.profile-image-container-temp {
    display: block;
    background-color: white;
    height: 125px;
    width: 125px;
    border-radius: 50%;
}

.profile-image-container-temp .local-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}