@font-face {
  font-family: 'Roboto';
  src: url('../resources/font/Roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto-Thin';
  src: url('../resources/font/Roboto/Roboto-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 300;
  src: url('../resources/font/OpenSans/OpenSans-Light.ttf');
  font-display: block;
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 400;
  src: url('../resources/font/OpenSans/OpenSans-Regular.ttf');
  font-display: block;
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 500;
  src: url('../resources/font/OpenSans/OpenSans-Medium.ttf');
  font-display: block;
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 600;
  src: url('../resources/font/OpenSans/OpenSans-SemiBold.ttf');
  font-display: block;
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 700;
  src: url('../resources/font/OpenSans/OpenSans-Bold.ttf');
  font-display: block;
}

html,
body,
#root {
  height: 100vh;
  width: 100%;
  margin: 0px;
  padding: 0px;
  font-size: 100%; /**To use rems**/
  font-family: 'Open Sans', sans-serif;
  color: var(--cbit-black);
  --cbit-font: 'Open Sans';
  --dashboardLongWidth: calc(100vw - 400px);
  --dashboardShortWidth:calc(100vw - 800px );
  
  --cbit-white: #FFFFFF;
  --cbit-blue: #00A3C1;
  --cbit-dark-blue: #00516b;
  --cbit-orange: #ff6f36;
  --cbit-black: #353b3c;
  --cbit-light-gray: #C2C4C4;
  --cbit-transparent-black: #000000cc;
  --cbit-pale-blue: #BEE7EE;
  --cbit-coral-red: #d73030;
  --cbit-pantone-green: #0BAA45;
  --cbit-inner-accordion-color:#EBF3F5;
  --cbit-yellow-green:#89C447;
  --cbit-water-blue:#D6EFF5;
  --cbit-orange-disabled: #F7C1AB;
  --cbit-crystal-disabled:#abd3da;
  --cbit-hover-state:#DDEFF3;

  /*Previously Light Blue*/
  --cbit-bondi-blue: #00a3c1;
  
  /*Previously secondary light blue*/
  --cbit-battery-charged-blue: #17B4D1;
  
  /*Previously Light gray*/
  --cbit-pastel-blue: #AFC6CC;

  /*Previously gray*/
  --cbit-cadet-blue: #9ab5bf;
  
  /*NEW COLORS FROM DATA SHEET*/
  --cbit-tuscany: #BFA49A;
  --cbit-anti-flash-white: #F5EDEB;
  --cbit-light-pastel-blue: #EBF3F5;

  /**New Color for Navigation Side panels*/
  --cbit-water:#D6F2F9;
  --cbit-side-panel-hover: #D6F2F9;

  --cbit-nav-bar-height: 80px;
  --cbit-full-height: calc(100vh - var(--cbit-nav-bar-height));
}

.alphabet-label {
  background-color: #919aa8;
  text-align: center;
  vertical-align: middle;
  color: #ffffff;
  font-size: 1rem;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem 0 0 0.25rem;
}

.btn-cbit-minor,
.btn-cbit-icon {
  border: 2px solid var(--cbit-bondi-blue);
  background-color: #fff;
  color: var(--cbit-bondi-blue);  
}

.btn-cbit-minor:hover:enabled,
.btn-cbit-icon:hover:enabled {
  background-color: var(--cbit-water-blue);
}

.btn-cbit-minor:disabled,
.btn-cbit-icon:disabled {
  color: var(--cbit-crystal-disabled);
  border: 2px solid var(--cbit-crystal-disabled);
  cursor: not-allowed;
}

a.btn-cbit-primary {
  display: flex;
  align-items: center;
  padding: 1px 6px;
}

a.btn-cbit-primary,
a.btn-cbit-primary:hover {
  text-decoration: none; 
}
a.btn-cbit-primary:hover {
  color:#fff;
  display: flex;
  align-items: center;
}

.btn-cbit-minor,
.btn-cbit-primary {
  letter-spacing: 1.8px;
  font-weight: 600;
  font-size: 18px;
}

.btn-cbit-minor,
.btn-cbit-primary,
.btn-cbit-icon,
.btn-cbit-link,
.btn-cbit-danger {
  text-align: center;
  text-transform: uppercase;
  border-radius: 3px;
  cursor: pointer;
}

.btn-cbit-primary,
.btn-cbit-danger {
  border: none;
  color: #ffffff;
  opacity: 1;
  background-color: #ff6f36;
}

.btn-cbit-primary:hover {
  background-color: #de5a26;
}

.btn-cbit-primary:disabled,
.btn-cbit-primary[disabled] /*For browsers that only support CSS2*/
{
  background-color: var(--cbit-orange-disabled);
  cursor: not-allowed;
}

.btn-cbit-text,
.btn-cbit-link {
  color: var(--cbit-dark-blue);
  letter-spacing: 0;
  padding: 0;
  border: none;
  background-color: transparent;
}

.btn-cbit-icon {
  background-color: #fff;
  /* color: var(--cbit-black); */
}

.btn-cbit-link {
  color: var(--cbit-dark-blue);
  font-weight: bold;
}

.btn-cbit-link:hover {
  color: var(--cbit-blue);
}

.btn-close-content-item {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px 60px 0 0;
  z-index: 0;
  font-size: 20px;
  color: #393e46;
  align-self: flex-end;
}

.btn-close-content-item:hover {
  color: #ec255a;
  cursor: pointer;
}

.btn-edit-form {
  position: absolute;
  top: 0;
  right: 100px;
  margin-top: 10px;
  padding: 0;
}

.btn-new-section {
  color: #5cb85c;
  font-size: 50px;
  height: 100%;
  vertical-align: middle;
  text-align: center;
}

.btn-new-section:hover {
  cursor: pointer;
  color: #6bd36b;
}

.btn-close-modal {
  position: absolute;
  top: 5px;
  right: 16px;        
  font-size: 24px;
  /* color: #fff; */
  /* padding: 8px 16px; */
  /* border-radius: 100%; */
  /* background-color: var(--cbit-transparent-black); */
}

.card-footer {
  background-color: #ffffff;
  border: none;
}

.card-header {
  background-color: #ffffff;
}

.cbit-full-height {
  height: var(--cbit-full-height);
}

.cbit-dashboard-header {
  font-size: 32px;
  font-weight: normal;
  margin-bottom: 32px;
  color: var(--cbit-dark-blue);
}

.choice-input {
  padding-right: 3.5rem;
}

.clear-floats {
  clear: both;
}

.container-new-section {
  display: inline-block;
  vertical-align: middle;
  line-height: 232px;
  margin-left: 12px;
}

.correct-answ-btn,
.selected-answ-btn {
  position: absolute;
  text-align: center;
  top: 5px;
  right: 4rem;
  z-index: 3;
  font-size: 1.2rem;
  cursor: pointer;
}

.correct-answ-btn {
  color: #919aa8;
}

.correct-answ-btn:hover {
  color: #5cb85c;
}

.custom-spinner {
  align-self: center;
  margin-top: 16px;
}

.cbit-container {
  padding: 48px 56px;
  width: 100%;
}


.form, .cbit-dialog,
.cbit-container {  
  background-color: #fff;
  border-radius: 9px;
  box-shadow: 0 0 5px -2px var(--cbit-black);
}

#consult-my-profile.cbit-container {
  box-shadow: none;
}

.form, .cbit-dialog {
  padding: 40px 32px 40px;
}

.cbit-dialog {
  padding-bottom: 32px;
}

.cbit-dialog-btn-container,
.cbit-btn-container {
  display: flex;
  justify-content: flex-end;
  gap:16px
}
.cbit-dialog-btn-container .btn-cbit-primary {
  min-width: 200px;
  
}

.cbit-dialog-btn-container .btn-cbit-minor {
  min-width: 134px;
}

.cbit-dialog-btn-container .btn-cbit-primary,
.cbit-dialog-btn-container .btn-cbit-minor {
  height: 44px;
  font-size: 18px;
  line-height: 34px;
  letter-spacing: 1.8px;
  font-weight: 600;
}

.cbit-text {
  font-size: 18px;
  color: var(--cbit-black);
  line-height: 32px;
}

.form-control.is-invalid {
  background-image: none;
}

.form-btn-container {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  width: 100%; 
}

.form-btn-container .btn-cbit-minor,
.form-btn-container .btn-cbit-primary {  
  text-align: center;
  font: normal normal 600 18px/34px var(--cbit-font);
  letter-spacing: 1.8px;
  text-transform: uppercase;
}

.form-btn-container .btn-cbit-minor {
  width: 134px;
}

.form-btn-container .btn-cbit-primary {
  width: 144px;
}

.form-label,
.cbit-label {
  color: var(--cbit-dark-blue);
  font: normal normal 600 18px/32px var(--cbit-font);
  text-transform: uppercase;
}

.form-input {
  padding: 0.375rem 0.75rem;
  border: 2px solid var( --cbit-cadet-blue);
  border-radius: 7px;
}

.form-input:focus {
  outline: none;
  border-color: var(--cbit-blue);
}

/* .form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
} */

.form-input::placeholder {
  color: var(--cbit-pastel-blue);
  font: normal normal normal 18px/32px var(--cbit-font);
}

.form-title,
.cbit-header {
  font: normal normal 600 24px/42px var(--cbit-font);
  letter-spacing: 0px;
  color: #00a3c1;
  text-transform: uppercase;
  margin: 0 0 24px;
}

.cbit-small-header {
  font-size: 24px;
  color: var(--cbit-dark-blue);
  font-weight: bold;
}

.required-field {
  color:red;
}

input:disabled {
  cursor: not-allowed;
}

/** Move to learning section css**/
.index-card {
  width: 100%;
  margin: 1rem 0;
  color: #000;
  text-decoration: none;
}

.index-card:hover {
  cursor: pointer;
  color: #007bff;
  /* background-color: #f6f6f6; */
}

.link-to,
.link-to:hover {
  color: #9bb6c0;
}

.link-to {
  text-transform: uppercase;
  text-decoration: underline;
}

.modal-form {
  min-width: 450px;
  min-height: 100px;
}

.section-card {
  height: 200px;
  margin-bottom: 12px;
}

.dot-background {
  background-image: url('DotPattern.png');
  background-color: #ffffff;
  overflow-y: auto;
}

.radio-cbit-text{
  margin-left:15px;
  top:-3px;
  position:relative;

  text-align: left;
  font: normal normal normal 14px/16px Open Sans;
  letter-spacing: 0px;
  color: #353B3C;
}
.radio-cbit{
  box-sizing: border-box;
  border:1px solid var(--cbit-dark-blue);
  border-radius:3px;
  width:16px;
  height:16px;
  appearance: none;
  background:var(--cbit-light-pastel-blue);
}
.radio-cbit:checked{
  background: var(--cbit-dark-blue);
}

.white-background {
  background-color: #ffffff;
}

.checkbox-cbit {
  height: 22px;
  width: 22px;
  padding: 0;
  margin: 0;
  vertical-align: bottom;
  accent-color: var(--cbit-dark-blue);
  
}

.checkbox-label-cbit {
  margin: 0;
}

.flex-break {
  flex-basis: 100%;
  height: 0;
}

.full-height {
  height: calc(100vh - 80px);
}

.full-width {
  width: 100%;
}

.temp-page-container {
  height: calc(100vh - 80px);
  width: 100%;
}

.temp-page-container img {  
  width: 100%;
}

/**
  React Player Styles
**/
.react-player-wrapper {
  background-color: rgba(0, 0, 0, 0.8);
  max-width: 640px;  
}
.react-player-wrapper,
.react-player {
  border-radius: 12px;
}
.react-player {    
  overflow: hidden;
}

.react-player-title-container {
  padding: 20px 27px;
  display: flex;
  justify-content: space-between;  
}

.react-player-title {
  color: #FFF;
  font-weight: 600;
  font-size: 24px;
  padding: 0;
  margin: 0;
}


/**
Navigation Panels
**/
.navigation-side-panel {
  min-width: 400px;
}

.navigation-side-panel-header {
  width: 100%;
  min-height: 200px;
  background-color: var(--cbit-dark-blue);
}

.navigation-side-panel-inner {
  background-color: var(--cbit-inner-accordion-color);
}

.nav-panel-title {
  margin: 0;
  font: normal normal 300 40px/44px "Open Sans";
  letter-spacing: 0px;
  color: #FFFFFF;
  align-self: center;
}

.navigation-side-panel {
  background-color: var(--cbit-inner-accordion-color);
  font-family: var(--cbit-font) !important;
}

.default-navigation-side-panel .faded-navigation-section-label {
  font-weight: 600;
  line-height: 34px;
  font-size: 16px;
  letter-spacing: 0.8px;
  color: var( --cbit-cadet-blue);
  text-transform: uppercase;
  text-decoration: underline;
  cursor: pointer;
  font-family: var(--cbit-font) !important;
  padding: 0;

}

.default-navigation-side-panel .navigation-side-panel-header {
  padding: 16px 16px 16px 24px;
  display: flex;
}

.default-navigation-side-panel .navigation-side-panel-inner {
  height: calc(100vh - 380px);
  overflow: auto;
}

.default-navigation-side-panel .navigation-side-panel-inner-header {
  width: 100%;
}

.navigation-side-panel-inner-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.navigation-side-panel-actions {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.nav-menu-collapse-icon-container {
  width: 34.59px;
  align-self: center;
}

.nav-menu-collapse-icon-container img {
  width: 100%;
}
.nav-side-panel-action-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 64px;
  padding: 10px 0 10px 24px;
  width: 100%;
  cursor: pointer;
  border-bottom: 1px solid var(--cbit-cadet-blue);
}

.nav-side-panel-action-container:hover {
  background-color: var(--cbit-side-panel-hover);
  text-decoration: none;
}

.nav-inner-panel-text {
  text-align: left;
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: 0.9px;
  color: var(--cbit-dark-blue);
  text-transform: capitalize;
}

.active-nav-item .nav-inner-panel-text
{
  font-weight: bold;
}

.text-transform-normal {
  text-transform: none;
}

/** Image **/
.profile-image-icon {
  width: 100%;
  border-radius: 50%;
}

/** Cbit Rows and Columns **/
.cbit-row {
  display: flex;
  flex-direction: row;        
}
.cbit-column {
  margin: 0 8px;
  width: 100%;
  flex-grow: 1;
}

.cbit-column:first-child {
  margin-left: 0;
}

.cbit-column:last-child {
  margin-right: 0;
}

.error-message-container {
  height: 20px;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%; 
  color: var(--cbit-coral-red);
}

.error-message-container
.cbit-invalid-feedback,
.text-character-limit-error
{
  color: #dc3545;  
}

.cbit-form-group {
  margin-bottom: 16px;
}

.form-checkbox-input {
  position: inherit;
  margin-right: 10px;
}

.cbit-invalid-feedback {    
  height: 20px;
  width: 100%;
  margin: 4px 0 11px;    
  font-size: 80%;
}

.text-field-error,
.text-field-error:focus
{
  outline: var(--cbit-coral-red);
  border-color: var(--cbit-coral-red);
}

.text-character-limit-container {
  font-size: 18px;
}

.capitalize-text {
  text-transform: capitalize;
}

.italicize-text {
  font-style: italic;
}

.bg-transparent {
  background-color: transparent;
}

.cbit-layout {
  display: flex;
  width: 100%;
}

.cbit-view-layout {
  background-color: #fff;
  width: 100%;
  padding: 48px 56px;
  overflow: auto;
}

.empty-image-container {
  width: 192px;
  height: 192px;
  border: solid 2px var(--cbit-cadet-blue);
  border-radius: 50%;
}

.cbit-divider {
  border-top: 2px solid var(--cbit-pastel-blue);
  margin: 8px 0;
}

.cbit-form-group.referral-user-list.mobile {
  display: none;
}

.text-ellipse {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-bold {
  font-weight: bold;
}
.mobile-session-info,
.mobile-consultation-type {
  display: none;
}

/* Training */
/* Layout with Sidebar */
.MainContent {
 width: 100%;
}

/* Layout with Sidebar */
/* Layout Container */
.social-connection-layout,
.continuing-education-layout,
.inbox-layout,
.consultation-layout,
.therapist-support-layout,
.referral-layout {
  background-color: #fff;
  padding: 0;
  overflow: hidden;
  position: relative;
}

/* Main content container */
.social-connection-main-content,
.continuing-education-main-content,
.inbox-main-content,
.consultation-main-content,
.therapist-support-main-content,
.referral-main-content {
  position: relative;
  height: calc(100vh - 80px);
  width: calc(100vw);
  overflow-y: scroll;
  padding: 48px 56px;
}

.isOpen .social-connection-main-content,
.isOpen .continuing-education-main-content,
.isOpen .inbox-main-content,
.isOpen .consultation-main-content,
.isOpen .therapist-support-main-content,
.isOpen .referral-main-content {
  left: 400px;
  height: calc(100vh - 80px);
  width: calc(100vw - 400px);
  overflow-y: scroll;
}

/* Navbar */
.menu-item.mobile {
  display: none;
}

/* Menu */
.AccordionContent {
  position: relative;
  width: 400px;
  background: lightblue;
  height: 100%;
}

.nav-sidebar {
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100vh - 80px);
  width: 400px;
  visibility: hidden;
  background-color: var(--cbit-inner-accordion-color);
  font-family: var(--cbit-font) !important;
  transition: all 0.1s linear 0s;
  transform: translate3d(-100%, 0px, 0px);
}

.isOpen .nav-sidebar {
  transition: transform 0.1s linear 0s;
  transform: translate3d(0px, 0px, 0px);
  visibility: visible;
}

.buttonIcon {
  transform: rotate(90deg) scale3d(4,2,1);
  --webkit-transform: rotate(90deg) scale3d(4,2,1);
  transition: all 0s;
  margin: 5px 0 0 2px;
}

/* Menu tab */
.nav-sidebar-menu-tab {
  display: none;
  border: none;
  color: #fff;
  position: absolute;
  top: 40px;
  height: 96px;
  width: 48px;
  left: 0;
  box-shadow: 0 3px 6px #00000029 !important;
  border-radius: 10px 0 0 10px !important;
  z-index: 10;
  background: var(--cbit-blue) !important;
  transition: all 0.1s linear 0s;
  -webkit-transition: all 0.1s linear 0s;
  transform: rotate(180deg);
}

.isOpen .nav-sidebar-menu-tab {
  --webkit-transform: translate3d(-345px,0px,0px);
  transform: translate3d(-345px,0px,0px);
  right: 17px;
}

.nav-sidebar-menu-tab:hover {
  background: var(--cbit-bondi-blue) !important;
}

/* Sidebar content */
.nav-sidebar .title-container {
  width: 100%;
  min-height: 200px;
  background-color: var(--cbit-dark-blue);
  color: var(--cbit-white);
  padding: 16px 16px 16px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.nav-sidebar .dashboard-link-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.nav-sidebar .back-to-dashboard {
  font-weight: 600;
  line-height: 34px;
  font-size: 16px;
  letter-spacing: 0.8px;
  color: var(--cbit-cadet-blue);
  text-transform: uppercase;
  text-decoration: underline;
  cursor: pointer;
  font-family: var(--cbit-font) !important;
  padding: 0;
}

.back-to-dashboard:hover {
  color: var(--cbit-cadet-blue);
}

button.nav-sidebar-collapse {
  display: none;
  background-color: transparent;
  border: none;
  padding: 0;
}

button.nav-sidebar-collapse img {
  width: 34.59px;
  align-self: center;
}

h2.nav-sidebar-title {
  margin: 0;
  font: normal normal 300 40px/44px "Open Sans";
  letter-spacing: 0px;
  color: #FFFFFF;
}

.nav-sidebar .links-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.nav-sidebar .sidebar-link {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 64px;
  padding: 10px 0 10px 24px;
  cursor: pointer;
  border-bottom: 1px solid var(--cbit-cadet-blue);
}

.nav-sidebar .sidebar-link:hover {
  background-color: var(--cbit-side-panel-hover);
  text-decoration: none;
}

.nav-sidebar .sidebar-link-text {
  text-align: left;
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: 0.9px;
  color: var(--cbit-dark-blue);
  text-transform: capitalize;
}

/* Active sidebar link */
.nav-sidebar .active-nav-item {
  background-color: #D6F2F9 !important;
}

.nav-sidebar .active-nav-item:hover {
  cursor: pointer;
}

.nav-sidebar .active-nav-item .sidebar-link-text {
  font-weight: bold !important;;
}

/*  Loading Spinner  */
.spinner-container {
  width: 100%;
  height: calc(100vh - 80px);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Right Menu tab */
.menuTab {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
    border: none;
    color: #fff;
    position: absolute;
    top: 40px;
    height: 96px;
    width: 48px;
    right: 0;
    line-height: 48px;
    z-index: 10;
    background: var(--cbit-blue) !important;
    transition: all 0.1s linear 0s;
    -webkit-transition: all 0.1s linear 0s;
    transform: rotate(360deg);
}

.menuTab:focus-within {
  border: 2px solid #007bff;
  outline: 1px #fff solid;
}

.text-red {
  color:var(--cbit-coral-red);
}

/* Form Front Facing Styles */
.form-label-input-container {
  display: flex;
  flex-direction: column;
}

.vertical-resize-only {
  resize: vertical;
  min-height:62px;
  max-height: 600px;
}

.cbit-section-label {
  font-size: 24px;
  font-weight: 600;
  color: var(--cbit-blue);
  text-transform: capitalize;
  margin: 0;
}

.cbit-link,
.cbit-link:hover {
  color: var(--cbit-blue);
  text-decoration: underline;
}

.btn-form-container,
.recaptcha-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
}
