.faq-page {        
    height: var(--cbit-full-height);
    overflow-y: auto;
    background-color: var(--cbit-dark-blue);        
    scrollbar-gutter: stable;
    padding: 108px 28% 140px; 
    padding: 108px 28% 20px;
    box-sizing: border-box;               
}

.faq-page .cbit-header {
    color: var(--cbit-white);
    margin-bottom: 36px;
    font-size: 56px;
    font-weight: 300;        
    line-height: 72px;
}

.faq-items-container {
  display: flex;
  flex-direction: column;
}

.faq-contact-message {
  align-self: flex-end;
}

.cbit-accordion {
    /**We are only doing this because we need to override mui accordion styles**/
    background-color: var(--cbit-blue) !important;
    color: var(--cbit-white) !important;
    margin-bottom: 16px;
    border-radius: 0 !important; 
}

.cbit-accordion-summary {
    min-height: 64px;
    text-transform: uppercase !important;
}

.cbit-accordion-summary i {
    font-size: 14px;
    
}

.cbit-accordion-details {
    background-color: var(--cbit-white);
    color: var(--cbit-black);
    font-size: 16px;
    line-height: 28px;
}

/* TODO Remove when you get the copy**/
#panel3-header {
    color: red !important;
}

.faq-contact-message {
    color: var(--cbit-white);
    font-size: 18px;
    float: right;
    min-height: 25px;
}

.faq-contact-message a {
    color: var(--cbit-blue);
    font-weight: 600;
}

@media only screen and (max-width: 921px) {
    .faq-page { 

        height: calc(100vh - 80px);
        padding-left: 20%;
        padding-right: 20%;
    }

}
@media only screen and (max-width: 768px) {
    .faq-page {
        
        height: calc(100vh - 64px);
        padding-left: 5%;
        padding-right: 5%;
    }
}