.consultation-info {
    text-transform: capitalize;
    margin-bottom: 24px;
}

.consultation-info span {
    font-weight: bold;
}

.consultation-action-container {
    display: flex;
    gap:16px;
    margin-bottom: 28px;
}

.btn-consultation-minor.btn-cbit-minor {
    font-size: 18px;
    font-weight: 600;
    line-height: 34px;
    height: 44px;
    width: 226px;
}

.btn-consultation-edit {
    width: 192px;
    height: 42px;
}
.btn-consultation-delete {
    height: 42px;
    width: 168px;
}