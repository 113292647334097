.action-full-item.cbit-container  {
    border-radius: 0;
    display: flex;
    gap:24px;
    padding: 32px 40px;
}

.action-icon-container {
    width: 64px;    
}

.action-icon-container img {
    width: 100%;
}