.custom-alert {
    font-family: var(--cbit-font);
    padding: 40px 32px 40px;
    width: 720px;
    /*min-height: 277px;*/ /* Adding extra height, can add back if needed to have determined height */
}

.custom-alert-header {
    font-weight: 600;
    font-size: 24px;
    line-height: 42px;
    color: var(--cbit-bondi-blue);
    padding-bottom: 24px;
    text-transform: uppercase;
}

.custom-alert-text {
    font: normal normal normal 18px/32px var(--cbit-font);

    color:var(--cbit-black);
}

.btns-custom-alert-container {
    float: right;
    padding-top: 24px;
}

.custom-alert .btn-cbit-minor {
    min-width: 134px;
    height: 44px;    
    margin-right: 16px;
    
}

.custom-alert .btn-cbit-primary {
    min-width: 200px;
    height: 44px;
}