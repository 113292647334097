.success-booking-container.cbit-dialog {
    min-height: 277px;
    max-width: 720px;
    width: 100%;
}

.success-booking-text.cbit-text { 
    margin: 0 0 48px;    
}

.success-booking-btn-container {
    display: flex;
    justify-content: flex-end;
}
.success-booking-btn-container .btn-cbit-primary {
    width: 124px;
    height: 44px;
}