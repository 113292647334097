.branch-selector {
    max-width: 200px;
    background: plum;
}
.removePadding {
    padding: 0px;
}
.mainAccordion {
    width: 100%;
}
.buffer-left {
    margin-left: 5px;
}
.navigation-header {
    width: 100%;
    background: #00516b;
    height: 150px;
}

.navigation-header-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.navigation-header-container button {
    margin-right: 16px;
    margin-top: 14px;
}

.faded-navigation-section-label {
    padding-top: 18px;
    padding-left: 24px;
    text-align: left;
    text-decoration: underline;
    font-family: Arial;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.8px;
    color: #9ab5bf;
    text-transform: uppercase;
    opacity: 1;
    cursor:pointer;
}
.navigation-section-label {
    padding-left: 24px;
    padding-top: 48px;
    text-align: left;
    font: normal normal 300 40px/44px Arial;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
}
.accordion-body 
{
    height: calc(100vh - 230px);
    overflow-y: scroll;   
}
.accordion-body div {
    cursor: pointer;
}
.accordion-item-body {
}
.accordion-item-header-chapter {
    background: #ffce6f;
    height: 60px;
    outline: red solid 1px;
}
.accordion-item-header-chapter-lesson {
    background: #85ece8;
    height: 60px;
    outline: red solid 1px;
}

.accordion-item-arrow {
    width: 20px;
    height: 60px;
    background: transparent;
    float: right;
}
.remove-border {
    border: 0px !important;
}
.centerLabel {
    position: relative;
    left: 5%;
    top: 25%;
}
.firstLevelCenter {
    position: relative;
    left: 15%;
    top: 25%;
}
.secondLevelCenter {
    position: relative;
    left: 25%;
    top: 25%;
}
.thirdLevelCenter {
    position: relative;
    left: 35%;
    top: 25%;
}

.Card {
    border: 0px;
}

.accordion-item-label {
    min-width: 55%;
    max-width: 55%;
    width: 55%;
    height: 60px;
    overflow-x: hidden;
    overflow-y: hidden;
    overflow-wrap: break-word;
    background: transparent;
    float: right;
}