.inbox-header-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

h2.inbox-header-title {
    font-size: 32px;
    font-weight: normal;
    color: var(--cbit-dark-blue);
}

.inbox-btn {
    padding: 12px 20px 10px;
    background-color: var(--cbit-orange);
    color: var(--cbit-white);
    border: var(--cbit-orange) 2px solid;
    text-transform: uppercase;
    border-radius: 4px;
}

.inbox-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto;
    gap: 16px 16px;
}

.inbox-no-notifications-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
}

.notification-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 24px 10px;
    background-color: #D6F2F9;
    border-bottom: 2px solid #AFC6CC;
}

.notification-item:hover {
    /*background-color: #D6EFF5;*/
    cursor: pointer;
}

.notification-item.read {
    background-color: #fff;
}

.notification-item-content-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.notification-item-action-container {
    display: flex;
    /*flex-direction: column;*/
    gap: 8px;
    align-items: center;
}

h5.notification-item-title {
    color: var(--cbit-dark-blue);
    font-weight: 600;
    font-size: 24px;
}

h5.notification-item-title.date {
    font-weight: normal;
    font-size: 16px;
    font-style: italic;
    color: #353B3C;
}

.notification-item-title .modal-title {
    color: var(--cbit-dark-blue);
    font-weight: 600;
    font-size: 24px;
}

p.notification-item-description {
    color: #353B3C;
    font-weight: normal;
    font-size: 16px;
}

p.notification-item-description span {
    font-weight: 600;
    font-size: 16px;
    color: var(--cbit-dark-blue);
}

.notification-item-btn {
    color: var(--cbit-blue);
    border: var(--cbit-blue) 2px solid;
    padding: 8px 20px 6px;
    background-color: transparent;
    font-weight: 500;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 0.10em;
    border-radius: 4px;
}

.notification-item-btn.primary {
    background-color: var(--cbit-orange);
    color: #fff;
    border: var(--cbit-orange) 2px solid;
}

.notification-item-btn.primary:hover {
    background-color: #bd5127;
    border: #bd5127 2px solid;
    color: #fff;
    cursor: pointer;
}

.notification-item-btn:hover {
    background-color: #D6EFF5;
    color: #00A3C1;
    border: #00A3C1 2px solid;
}

.notification-item-toolbar {
    display: flex;
}

.notification-item-toolbar-btn {
    border: none;
    background: transparent;
    color: var(--cbit-blue);
}

.notification-item-toolbar-btn svg {
    width: 24px;
    height: 24px;
    fill: var(--cbit-blue);
}

.notification-item-toolbar-btn svg:hover {
    fill: #007B91FF;
}

.inbox-load-more-container {
    width: 100%;
    display: flex;
    margin: 36px 0 0;
}

.inbox-load-more-button {
    font-size: 16px;
    background: transparent;
    color: var(--cbit-blue);
    border: 2px solid var(--cbit-blue);
    border-radius: 4px;
    padding: 8px 20px 6px;
    text-transform: uppercase;
    font-weight: 600;
    margin: 0 auto;
}

.inbox-load-more-button:hover {
    background-color: #D6EFF5;
}

/* Inbox modal */
.inbox-modal-body-container {
    padding: 10px 0 20px;
}

.inbox-modal-btn-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
}

.spinner-container {
    width: 100%;
    height: calc(100vh - 80px);
    display: flex;
    justify-content: center;
    align-items: center;
}