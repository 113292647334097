.inline-module-input {
  border: none;
  border-bottom: 1px solid var( --cbit-cadet-blue);
  outline: none;
  height: auto;
  background-color: transparent;  
  padding: 0;
  
}

.inline-module-input::placeholder{
  color: var( --cbit-cadet-blue);
  
}