
.remove{
    display: none;
}

.toastui-calendar-daygrid-cell {
    cursor: pointer;
}

.create-event-container {
    position: absolute;        
}


.calendar-container .container {
    margin: 0;
    padding: 0;
}

.calendar-nav-container .btn-cbit-minor:not(:first-of-type) {
    margin: 0 3px;
}

.calendar-nav-container .btn-cbit-minor:first-of-type {
    margin-right: 3px;
}

.calendar-date-text {
    margin-left: 3px;
}

/* .toastui-calendar-weekday-grid-date-decorator { */
    /* cursor:not-allowed */

/* } */

div .calendar-not-allowed {
    cursor: not-allowed;
    background-color: #fcfcfc;
}

div .calendar-not-allowed .toastui-calendar-grid-cell-date {    
    color: var(--cbit-light-gray) !important;
}

.consultation-calendar-legend {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 32px;
}

.consultation-legend-icon {
    display: inline-block;
    height: 10px;
    width: 10px;
    margin-right: 10px;
    border-radius: 50%;
    background-color: var(--cbit-black);
}

.one-on-one-legend .consultation-legend-icon  {
    background-color: var(--cbit-dark-blue);
}

.small-group-legend .consultation-legend-icon{
    background-color: var(--cbit-orange);
}

.seminar-legend .consultation-legend-icon{
    background-color: var(--cbit-yellow-green);
}

.calendar-read-only *{
    cursor: default;
}

.toastui-calendar-weekday-event-block * {
    cursor: pointer;
}

.calendar-container .container {
    max-width: 100%;
}

.calendar-container .cbit-tooltip {
    position: absolute;
}