.contact-us-page {
    margin: 0 14% 24px;
    padding: 72px 10%;  
    background-color: var(--cbit-white);
}

.contact-us-page,
.contact-us-page .cbit-section-label {
    line-height: normal;

}
.contact-us-page .cbit-header {
    margin-bottom: 36px;
    font-size: 56px;
    font-weight: 300;
    color: var(--cbit-dark-blue);
    margin: 0 0 36px;
    line-height: 72px;
    text-transform: capitalize;
}

.contact-us-page .cbit-section-label {
    margin-bottom: 16px;
}

.contact-us-page .error-message-container {
    font-size: 16px;
}

.contact-us-inner-container {
    display: flex;
    gap:64px;
}

.contact-us-form-container {
    max-width: 464px;
    
}

.contact-us-form-container .form-group {
  min-height: 96px;
}

.contact-us-form-container .form-label-input-container {
  min-height: 74px;
}

.contact-us-form-container .form-group.last {
  min-height: 180px;
}

.contact-us-form-container .error-message-container {
    margin-top: 0;
}

.contact-us-page .form-input {
    min-height: 46px;    
    padding: 0.75rem;        
    font-size: 16px;
    line-height: normal;
}

.contact-us-page .form-input.vertical-resize-only {
    min-height: 181px;
    padding: 12px; 
    line-height: normal;
}

.contact-us-page .form-input::placeholder {
    font-size: 16px;
}

.contact-us-page .cbit-label {
    margin-bottom: 1px;
    line-height: normal;
}

.contact-us-form-msg {
    margin-bottom: 36px;    
}

.contact-us-form-msg,
.psyctech-location-container ul {
    line-height: 28px;
}

.psyctech-location-container {
    margin-bottom: 40px;
}

.psyctech-location-container ul {
    padding: 0;
    margin: 0;
    list-style: none;
    min-height: 112px;
}

.contact-us-questions-container {
  min-height: 71px;
}

.contact-us-form-container .btn-cbit-primary {
  height:44px;
  width: 171px;
  padding: 0 !important;
}
@media only screen and (max-width: 1120px) {
    .contact-us-page.cbit-page-container {
        margin: 0;

    }
}

@media only screen and (max-width: 921px) {
    .contact-us-inner-container {
        gap: 0;
        flex-direction: column-reverse;
      }
  
    .contact-us-form-container {
        max-width: none;
      }
    .contact-us-page.cbit-page-container {        
        padding-left: 12px;
        padding-right: 12px;
    }

    .contact-us-page .cbit-header {
        font-size: 32px;
    }

    .contact-us-page .cbit-section-label {
        font-size: 20px;
    }

}