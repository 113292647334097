.social-connection-faqs-container {
    background-color: #fff;
    width: 100%;
}

    .social-connection-faqs-page-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 32px;
    }

.social-connection-faqs-subheader {
    width: 100%;
    margin-bottom: 28px;
}

    h4.social-connection-faqs-subheading {
        color: var(--cbit-blue);
        text-transform: uppercase;
        font-weight: 600;
        font-size: 24px;
    }

.social-connection-faqs-posts-container {
    width: 100%;
    height: 100%;
}