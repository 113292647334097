.availability-date-container .btn-availability-control img {
    width: 100%;
}

.availability-date-container {
    margin-bottom: 32px;
    display: flex;
}

.calendar-date-range.availability-date-range {
    font-size: 24px;
    min-width: 228px;
    text-transform: uppercase;
    align-self: center;
    text-align: center;
}