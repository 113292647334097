.referral-alert {
    /*width: 516px;*/ /* Duplicate width */
    height: 485px;
    width: 435px;
}

.referral-alert h2 {
    line-height: normal;
}

.referral-description-container {
}

.referral-alert p {
    font-size: 16px;
    margin-bottom: 40px;
}