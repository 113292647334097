.intro-video-modal {
    /* width: 100%; */
}

.intro-video-container {
    width: fit-content;    
    height: fit-content;
    margin: 0 auto;
    /* display: flex;
    flex-direction: column; */
    background-color: #000000cc;  
    border-radius: 12px;    
  }
  
  .intro-video-react-player-container {
    width: 1280px;
    height: 720px;
    max-width: 100%;
    max-height: 100%;    
  }
  
  @media screen and (max-width: 1270px) {
    .intro-video-react-player-container {
      height: 360px;
      width: 640px;
    }

    .intro-video-modal .btn-close-modal {
        font-size: 18px;
        padding: 4px 10px;
    }
  }
  
  @media screen and (max-width: 645px) {
    .intro-video-react-player-container{
      height: 180px;
      width: 320px;
    }


    .intro-video-modal .btn-close-modal {
        font-size: 16px;
        padding: 4px 10px;
    }
  }