.therapist-support {
    display: flex;
}

.therapist-panel {
    font-family: var(--cbit-font) !important;
}

.therapist-panel.navigation-side-panel {
    background-color: var(--cbit-inner-accordion-color);
}

.therapist-panel .navigation-side-panel-header {
    display: flex;
}

.therapist-panel .faded-navigation-section-label {
    font-weight: 600;
    line-height: 34px;
    font-size: 14px;
    letter-spacing: 0.7px;
    color: var(--cbit-cadet-blue);
    text-transform: uppercase;
    text-decoration: underline;
    cursor: pointer;
    font-family: var(--cbit-font) !important;
    padding: 0;
}

.therapist-panel .navigation-side-panel-header {
    padding: 16px;
}

/**
TODO check for admin
**/
.therapist-panel .navigation-side-panel-inner {
    height: calc(100vh - 380px);
    overflow: auto;
}

.therapist-panel .chapter-content-container,
.standard-content-btn-container {
    min-height: 64px;
    padding: 10px 24px 10px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: pointer;
}

.nav-sidebar .standard-content-btn-container {
    position: absolute;
    bottom: 8px;
}

.therapist-panel .chapter-content-container .drag-handle {
    margin-right: 12px;
}

.therapist-panel .chapter-content-container:hover,
.selected-category {
    background-color: var(--cbit-pale-blue);
}

.therapist-panel .admin-chapter-text,
.category-title {
    color: var(--cbit-dark-blue);
}

.therapist-panel-inner-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.therapist-panel .navigation-section-label {
    font-family: var(--cbit-font) !important;
}

.selected-category .admin-chapter-text {
    font-weight: bold;
}

.standard-content-btn-container {
    cursor: default;
}

.therapist-panel-btn-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.therapist-panel-btn-container button {
    background-color: transparent;
    border: none;
    width: 33px;
    margin-right: 16px;
}

.therapist-panel-btn-container button img {
    width: 100%;
}


/**
    Therapist Support Main view
**/

.therapist-support-main-content {
    background-color: white;
    width: 100%;
    /*padding: 40px;*/
    overflow-y: auto;
}

.main-category-container .category-title {
    width: 80%;
}

.category-title-container {
    display: flex;
    gap: 30px;
}

.category-title {
    text-transform: capitalize;
    font-weight: 400;
    margin-bottom: 32px;
}

.btn-tag-add {
    padding: 0;
    margin: 0;
    border: none;
    background-color: transparent;
    display: flex;
    gap: 12px;
    align-items: flex-start;
}

.tag-add-text {
    color: var(--cbit-bondi-blue);
    font-weight: 600;
    font-size: 18px;
    line-height: 1.2;
}

.refresh-container {
    align-self: flex-start;
    padding: 0;
    /*width: 20%;*/
    justify-content: flex-end;
}

.btn-therapist-refresh {
    align-self: flex-start;
}

.admin-content-menu-item-subheading {
    font: normal normal 600 18px/42px 'Open Sans';
    /* margin: 12px 0; */
    width: 100%;
    /* height: 24px; */
    text-align: left;
}

.btn-tag-add {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
    height: 24px;

}

.btn-tag-add .plus-icon {
    /* height: 18px;
    width: 18px; */
    height: 24px !important;
    width: 24px;
    margin: 0;
    display: block;
}

.tag-add-text {
    margin-right: auto;
}

.btn-tag-add img {
    height: 100%;
    /* object-fit: cover; */

    margin-right: auto;
}


.therapist-tag-editor-container .tag-title {
    font-size: 1.5rem;
    line-height: 1.2;
}

.tag-title {
    color: var(--cbit-blue);
    margin-bottom: 17px;
    text-transform: capitalize;
}

.tag-item {
    padding-bottom: 34px;
    margin-bottom: 40px;
    border-bottom: 1px solid var(--cbit-cadet-blue);
}

.tag-item:last-child {
    border-bottom: none;
}


.tag-content-container {
    display: inline-flex;
    height: 100%;
    flex-direction: row;
    align-items: flex-start;
    gap: 16px 16px;
    flex-wrap: wrap;
    width: 100%;
}

.therapist-content-item {
    min-height: 65px;
    min-width: 457px;
    max-width: 457px;
    display: flex;
    align-items: center;
    padding: 7px 8px 6px 8px;
    color: var(--cbit-black);
    cursor: pointer;
}

.therapist-content-item:hover {
    background-color: #DFEDEE;
}

.therapist-content-item-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 16px;
    width: 40px;
}

.therapist-icon-container {
    width: 35px;
    align-self: center;
}

.therapist-icon-container img {

    width: 100%;
    object-fit: cover;
}

.content-item-duration {
    margin-top: 7px;
}

.therapist-item-details-container {
    display: flex;
    flex-direction: column;
}

.therapist-content-title {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
}

.tag-item .plus-icon {
    height: 24px;
    width: 24px;
}

.therapist-tooltip {
    background-color: red !important;
}

.tag-item .therapist-panel-btn-container {
    align-items: flex-start;
}

/**
    Therapist Support Player
**/

.therapist-support-modal .modal-content {
    background-color: transparent;
}

.therapist-player-wrapper {
    background-color: rgba(0, 0, 0, 0.8);
}

.therapist-player-wrapper,
.therapist-player {
    border-radius: 12px;
}

.therapist-player {
    overflow: hidden;
}

.therapist-player-title-container {
    padding: 20px 27px;
    display: flex;
    justify-content: space-between;
}

.therapist-player-title {
    color: #FFF;
    font-weight: 600;
    font-size: 24px;
    padding: 0;
    margin: 0;
}

/**
Therapist Support Content Item,
**/
.therapist-content-item {
    background-color: #dfedee91;
    height: 130px;
    max-height: 130px;
}


.therapist-content-description {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-width: 457px;
}


.therapist-pdf-item:hover {
    text-decoration: none;
    color: var(--cbit-black);

}

.therapist-content-item .btn-cbit-icon:hover {
    background-color: #dcdcdc !important;
}

.therapist-content-item .therapist-panel-btn-container {
    flex-direction: column;
    gap: 16px;
    margin-left: 24px;
}

.therapist-content-item .therapist-content-title {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
}

.therapist-content-item .drag-handle {
    margin-right: 16px;
    align-self: center;
}

.admin.therapist-content-item .therapist-content-title {
    width: 288px;
    max-width: 288px;
}

.admin-content-body {
    width: 100%;
    margin-left: 20px;
}

.admin-support-title {
    margin-bottom: 10px;
    color: var(--cbit-dark-blue);
}

.admin-support-content {
    color: black;
    text-transform: none;
    font-size:16px;
}

.admin-content-container{
    margin: 20px 0;
    background-color:white;
}
.admin-inner-content-container{
    background-color: var(--cbit-inner-accordion-color);
}
.navigation-side-panel-inner{
    background-color: unset !important;
}
.formik-error-text{
    color:lightcoral;
}

.SupportContent-title{
    margin-bottom: 10px;
    color: var(--cbit-dark-blue);
    background-color: transparent;
    text-align: left;
    font: normal normal 600 18px/20px "Open Sans";
    letter-spacing: 0.9px;
}

a.SupportContent-title:hover {
    cursor: pointer;
}

.SupportContent-pdf-container{
    margin-left:-4px;
    display:flex;
}

.SupportContent-pdf-title-container{
    margin-top:5px;
    margin-left:-10px;
}
