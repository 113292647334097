
.dual-form-input-container {
    display: flex;
    width: 100%;
    gap: 16px;
    flex-wrap: nowrap;    
}

.form-upload-image-container {
    margin-top: 8px;
    display: flex;
    width: 100%;
    min-height: 148px;    
    gap: 16px;
}

.input-txt-img-upload {
    opacity: 0;
    width: 0;
}

.upload-btn-container{
    width: 178px;
    height: 44px;
    letter-spacing: 1.8px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.upload-label .form-label {
    margin: 0
}

.upload-instructions {
    width: 766px;
    height: 148px;
}

.upload-image-thumb,
.text-form-image-preview {
    max-width: 148px;
    min-height: 100%;
}

.upload-image-thumb, .no-upload-image-thumb {
    border-radius: 7px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 148px;
}

.no-upload-image-thumb {
    height: 148px;
    border: 2px solid var( --cbit-cadet-blue);

}

.text-form-image-preview {    
    border-radius: 5px;
    width: 148px !important;
    height: 100%;
    cursor: pointer;
}

.upload-text {
    font: italic normal normal 16px/26px var(--cbit-font);
    padding-bottom: 10px;
}

.text-form-tags-container {
    flex-grow: 1;
}

.text-editor-form {
    width: 1024px;
    min-height: 845px;
    padding: 40px 32px 40px;
}

.text-editor-form .form-input {
    height: 48px;
}

.text-form-remove-image {
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    text-align: center;
    left: 0;
    top: 0;
    background-color: #353b3c9a;
    font-weight: 600;
    font-size: 16px;
    z-index: 1;
    height: 100%;
    width: 100%;
    cursor: pointer;    
    color: #FFF;
    -webkit-transition: all 400ms ease-out;
    -moz-transition: all 400ms ease-out;
    -o-transition: all 400ms ease-out;
    -ms-transition: all 400ms ease-out;
    transition: all 400ms ease-out;
}

.text-form-remove-image:hover {
    opacity: 1;
}


.replace-image {
    width: 255px;
}

.replace-image-container label{
    float: left;
    margin-right: 16px;
}

.btn-remove-image {
    float: left;
    height: 44px;
}