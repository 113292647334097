.cbit-data-grid-container {
    height: 700px;    
}

.empty-cbit-grid {
    display: flex;
    justify-content: center;
    align-items: center;    
    height: 100% !important;
    text-transform: uppercase;
    color: var(--cbit-blue);
    font-weight: 600;    
}

.cbit-data-grid .MuiBadge-badge {
    background-color: var(--cbit-orange);
}

.cbit-data-grid .MuiCircularProgress-svg {
    color: var(--cbit-dark-blue)
}