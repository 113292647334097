.my-profile {
  max-width: 800px;
  min-height: 712px;
}
.my-profile.cbit-dialog {
  border-radius: 0;
  margin:0 auto;
}
.my-profile .btn-cbit-link {
  text-decoration: underline;
  font-size: 1rem;
  font-weight: 500;
}
.my-profile-inner {
  display: flex;
  gap: 20px;
}
.profile-inner-right {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex:1;
  gap: 16px;
}
.my-profile-header-container {
  display: flex;
  gap: 16px;
  align-items: flex-end;
  height: 100%;
  align-content: center;
}
.profile-inner-right .cbit-small-header {
  margin: 0;
}
.profile-bio-desc {
  max-height: 200px;  
  overflow-y: auto;
}
.my-profile-btn-container {
  width: 100%;
  display: flex;
  align-self: flex-end;
  
}
.my-profile-btn-container button{
  height: 44px;
  width: 192px;
}
.my-profile .form-input {
  width: 100%;
}

.my-profile-description {
  min-height: 150px;
  max-height: 300px;
}

.my-profile-desc-error {
  border-color: var(--cbit-coral-red) !important;
}

.my-profile .error-message-container {
  font-size: 15px;
}
.description-error {
  display: flex;
}
.upload-btn-container.btn-cbit-minor:hover {
  background-color: var(--cbit-hover-state);        
}
.my-profile-form .profile-inner-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}
.my-profile-form .image-upload-container {
  display: flex;
}
.my-profile-form .upload-btn-container {
  margin-bottom: 0;
}
.my-profile-form .my-profile-btn-container {
  justify-content: space-between;
}
.my-profile-form .my-profile-btn-container .btn-cbit-minor {
  width: auto;
}

/**Old styles that are being used in other places**/
.profile-image-container {
  display: block;  
  background-color: white;
  height: 125px;
  width: 125px;
  border-radius: 50%;  
  float: left;  
  border: 2px solid var(--cbit-dark-blue);  
}
.empty-profile-image {
  width:100%;
  height:100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--cbit-dark-blue);
  font-weight: 600;
  font-size: 14px;
  cursor: default;
  user-select: none;
}
.my-profile-image {    
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
.my-profile-image-container {
  float: left;
  height: 125px;
  width: 125px;
}
.my-profile-description {
  height: calc(100% - 40px);
  overflow-y: auto;
}
