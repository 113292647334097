.referral-user-item {
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin-bottom: 40px;    
}
.referral-user-item-profile .referral-image-container {
    width: 56px;
}

.referral-user-item-profile .referral-image-container .referral-image {
    height: 56px;
}

.referral-user-item-profile .empty-image-container {
    width: 56px;
    height: 56px;
}

.referral-user-item-profile .empty-profile-image {
    font-size: 10px;
}

.referral-user-item-info {
    width: 100%;
}

.referral-user-item-name.btn-cbit-text {
    color: var(--cbit-blue);
    font-size: 18px;
    font-weight: bold;
}

.referral-user-name-container {
    display: flex;
    justify-content: space-between;
}

.referral-user-item-name.text-ellipse {
    max-width: 310px;    
    -webkit-line-clamp: 1;
    white-space: nowrap;
}

.referral-user-marker-container {    
    width: 16px;    
    
}

.referral-user-marker-container img {
    width: 100%;
    
}

.referral-user-item-occupation {
    text-transform: capitalize;
    
}

.referral-user-item-occupation {
    font-size: 16px;
    color: var(--cbit-cadet-blue);
}

.referral-user-item-bio {
    font-size: 16px;
    color: var(--cbit-black);    
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

}

.referral-user-item-btn-container {
    margin-top: 16px;
}

.referral-user-item-btn-container .btn-cbit-minor {
    height: 38px;
    width: 151px;
    font-size: 16px;
    text-transform: capitalize;
}