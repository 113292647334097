/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}
/* Default CSS */
.default-menu-logo-text{
  font-family:"Verdana";
}
.default-menu-header-text{
  font-family:"Arial";
}

h1{
  font-family:"Arial";
  font-size: 34px;
}

p{
  font-size:14px;
}

.default-container{
  margin:5px;
  background:white;
}

.btn-cbit{
  border-radius: 3px;
  text-align: center;
  text-transform: uppercase;
  border: none;
  color: #FFFFFF;
  min-height: 38px;
  padding-left: 10px;
  padding-right: 10px;
  white-space: nowrap; /* Prevents wrapping on CBit button */
}

.btn-primary
{
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.2em;
  letter-spacing:2px;
  padding-left:20px;
  padding-right:20px;
  background-color:#ff6f36 !important;
}
.btn-primary:hover:not(.btn-disabled)
{
  background-color: #DE5A26;
}
.btn-primary:focus
{
  background-color: #ff6f36 !important;
}


.btn-secondary
{
  color:#00A3C1;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.2em;
  letter-spacing:2px;
  border-radius:3px;
  border: 2px solid #00A3C1;
  background-color:white;
  padding-left:20px;
  padding-right:20px;
}
.btn-secondary:hover
{
  background-color:lightskyblue;
}

.btn-disabled {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.2em;
  letter-spacing:2px;
  background-color: #F7C1AB;
  padding-left:20px;
  padding-right:20px;
  cursor: unset !important;
}

/*.content-item {*/
/*  width: 100%;*/
/*  height: 96px;  */
/*  border: 1px solid var(--cbit-cadet-blue);*/
/*  border-radius: 4px;*/
/*  padding: 30px 24px;*/
/*  background-color: #ffffff;*/
/*}*/
