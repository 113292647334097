.category-builder {
    padding: 40px 32px 40px;
    min-width: 500px;
    min-height: auto;
}

.category-builder .inline-module-input {
    width: 100%;
}

.category-container {
    padding: 0;

}

.content-category-name {
    cursor: pointer;
}

.category-container li{    
    list-style: none;
}

.category-delete-btn {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;

}

.category-delete-btn svg {
    height: 20px;
    width: 20px;
}

.category-form {
    position: relative;
    border: 2px solid var( --cbit-cadet-blue);
    border-radius: 7px;
    margin: 12px 0;
}

.tag-name-container p{
    display: inline-block;
    cursor: pointer;
}

.tag-name-container span {
    float: right;
    cursor: pointer;
}

.tag-name-container svg {
    height: 18px;
    width: 18px;
}