.consultation-plan-form {    
    width: 840px;
}

.consultation-plan-form .form-input {
    width: 100%;
    height: 48px;    
    display: block;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;    
    background-clip: padding-box;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.consultation-plan-form .form-input,
.consultation-plan-textarea-container
 {    
    margin-bottom: 24px;
}

.consultation-plan-form .consultation-plan-textarea {
    margin-bottom: 0;
    min-height: 100px;
    max-height: 276px;
}

.dual-form-input-container {
    display: flex;
    width: 100%;
    grid-gap: 16px;
    gap: 16px;
    flex-wrap: nowrap;
}

.consultation-plan-form .btn-cbit-primary,
.consultation-plan-form .btn-cbit-minor {
    letter-spacing: 1.8px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;    
    min-width: 144px;
}

.to-container {
    display: flex;
    justify-content: center;
    align-content: center;
    width: auto;
    height: 48px;
}
.to-date {
    align-self: center;
    font-size: 24px;
    
}
.session-time-to-column.cbit-column {
    display: flex;
    margin: 0;
    width: 24px;
}

.session-time-to-text {
    color: var(--cbit-dark-blue);
    font-size: 18px;
    font-weight: 600;
    /* line-height: 32px; */
    
    text-transform: uppercase;
    height: 48px;
    align-self: center  ;
}

.recurring-plan-container {
    display: flex;
    gap: 16px;
}

.recurring-repeat-container input{
    display: inline-block;    
    
}

.recurring-repeat-container {
    margin-bottom: 24px;
}

.recurring-repeat-container label,
.recurring-repeat-container input {
    cursor: pointer;
}

.recurring-repeat-container label {
    margin-right: 6px;    
}

.recurring-note {
    display: block;
    font-size: 16px;
    font-weight: normal;

}


.max-attendees-input {
    min-width: 217px;
}


.plan-current-attendees {
    list-style: none;
    padding: 0;
    text-transform: capitalize;
}

.plan-current-attendees li {
    margin-bottom: 8px;
}

.plan-current-attendees .btn-cbit-text {
    margin-left: 16px;
}

.plan-button-group {
    padding-top: 12px;
    display: flex;
    justify-content: flex-end;    
}

.plan-button-group .btn-cbit-minor {
    width: 134px;
}

.plan-button-group .btn-cbit-primary {    
    margin-left: 16px;
    width: 170px;    
}

