.recurring-alert-item-container {
    padding-bottom: 16px;
}

.recurring-alert-item {
    margin-bottom: 16px;
}

.recurring-alert-item label,
.recurring-alert-item input {
    cursor: pointer;
}

.recurring-alert-radio {
    margin-right: 10px;
}
