.social-connection-add-post-container {
    width: 100%;
    margin: 0 auto;
}

    .social-connection-add-post-header {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 32px;
    }

.social-connection-add-post-form-container {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

    .social-connection-add-post-form-container form {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 48px 24px;
        background-color: #fff;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
        border-radius: 4px;
    }

    .social-connection-add-post-form-input {
        width: 100%;
        height: 100%;
        display: inline-flex;
        flex-direction: column;
        border-radius: 4px;
        padding: 16px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: var(--cbit-dark-blue);
    }

    .social-connection-add-post-form-input label {
        font-weight: 600;
    }

    .social-connection-add-post-form-input input {
        width: 100%;
        height: 100%;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: var(--cbit-dark-blue);
        border: slategrey 1px solid;
        border-radius: 4px;
        padding: 16px;
        outline: none;
    }

    .social-connection-add-post-form-input textarea {
        width: 100%;
        height: 100%;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: var(--cbit-dark-blue);
        border: slategrey 1px solid;
        border-radius: 4px;
        padding: 16px;
        outline: none;
    }

    .social-connection-add-post-disclaimer-text {
        margin: 0 16px;
    }

    p.disclaimer-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
    }

    .social-connection-add-post-button-container {
        width: 100%;
    }

    .btn-social-connection-add-post {
        width: 100%;
        font-weight: 600;
        line-height: 34px;
        padding: 8px 16px;
    }
