.answer-choice-container {
    display: flex;
}

.btn-add-Another-choice
 {
    background-color: transparent;
    border: none;
    color: var(--cbit-black);
    font: normal normal normal 22px/32px var(--cbit-font);
    height: 40px;
    padding: 0;
}

.btn-add-Another-choice span {
    width: 36px;
    height: 36px;
    margin-right: 16px;
}

.form-question {
    width: 624px;
    min-height: 561px;        
    padding: 40px 32px 40px;
}

.icon-answer-circle,
.icon-quiz-trash {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
}

.icon-quiz-trash {
    margin-left: 16px;
    margin-right: 0;    
}

.question-choice { 
    padding: 12px;
}

.multi-choice-text-field {
    min-height: 94px !important;
    
}

.true-false-text-field {
    min-height: 116px !important;
}

.question-input {
    min-height: 38px;    
    overflow: auto;
}