.admin-view-tools-left {
  display: flex;
  align-items: flex-end;
  float: left;  
  height: 100%;
  font-size: 16px;
  line-height: 1;  
}

.admin-view-tools-right  {
  float: right;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 32px;
  font-size: 16px;
  line-height: 1;
}

.admin-all-visibility {
  background-color: transparent;
  border: none;
  color: var(--cbit-black);  
  padding: 0;
  display: inline-flex;
  align-items: flex-end;
  vertical-align: bottom;
  cursor: pointer;
}
.admin-visibility-icon,
.admin-visibility-slash-icon
{
  margin-left: 8px;
  height: 18px;
  width: 28px;
}

.admin-visibility-slash-icon {
  height: 20px;
}

.collapse-all-separator {
  width: 0px;
  height: 16px;
  border: .5px solid var( --cbit-cadet-blue);
  margin: 0 16px;
}

.combo-duration {
  cursor: pointer;
  min-width: 116px;
}

.combo-duration-time {
  color: var(--cbit-bondi-blue);
}

.combo-duration-quiz {
  cursor:default;
}

.content-list {  
  width: 100%;  
  display: flex;
  gap: 8px;
  flex-direction: column;
}

/**
TODO Remove box-sizing from root
**/
.content-list * {
  -webkit-box-sizing: initial !important;
  -moz-box-sizing: initial !important;
       box-sizing: content-box !important;
}

.empty-content-list {
  width: auto;
  height: 36px;
  border: 1px dashed var( --cbit-cadet-blue);
  border: 1px dashed #9ab5bf;
  border-radius: 4px;
  padding: 30px 24px;
}

.empty-content-list p {
  font: normal normal normal 24px/32px 'Open Sans';
  text-align: left;
  color: #9ab5bf;
}
.module-creator-body {
  margin: 5px;
  padding: 10px;
}

.save-btn-container{
  display: flex;
  justify-content: flex-end;
  height: 96px;
  width: 100%;
  margin-bottom: 46px;
}


/* .save-btn-container {
  position: sticky;
  background-color: #fff;
  bottom: 0;
  z-index: 1;
  height: 100%;
  width: 100%;
  padding: 0 51px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
} */

.btn-add-content {
  justify-self: flex-start;
}

