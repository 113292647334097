
.admin-accordion-chapter {
  border-bottom: 1px solid var(--cbit-battery-charged-blue) !important;
}

.admin-accordion-chapter div {
  cursor: pointer;
}

.admin-accordion-icon {
  line-height: 0;
}

.admin-accordion-icon svg{
  width: 22px;
  height: 22px;
}

.admin-lesson-amount {
  color: #DEE5E7;
  letter-spacing: 1.4px;
  font-size: 14px;
  font-weight: 400;
  width: 19px;
  height: 19px;
  /* margin-left: 12px; */
  margin-right: 11px;
}

.Lesson-Header-Depth-2 .admin-lesson-header {
  padding-left: 34px;    
}

.admin-accordion .accordion-lesson-text,
.admin-accordion .accordion-lesson-selected-text,
.admin-accordion-text 
{
  white-space: nowrap ;
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.admin-branch-header {
  display: flex;
  justify-content: space-between;
  min-height: 63px;
}

.admin-branch-header .accordion-text {
  white-space:normal;
  min-width: 291px;
  max-width: 291px;
  text-overflow: initial;
  overflow: visible;
  max-height: none;
}

.admin-branch-header.Branch-Header-Depth-0 {
  height: auto;
  height: 63px;
}

.admin-branch-header-items {  
  padding-left: 24px;
  display: flex;
  justify-content: space-between;
  align-self: center;
  width: 100%;
}

.admin-lesson-non-selected {  
  max-width:8px;
  min-width:8px;
  height:56px;
  margin-top:-20px;
  margin-left:-23.28px;
}
.admin-branch-header-selected {
  background-color: #00BBDE;
  padding: 0px;
  position: relative;
}

.admin-accordion-item-container {  
  padding: 17px 0 17px 24px;
  height: 100%;

}

.admin-accordion-icon-container {
  align-self: center;
  display: inline-block;
  vertical-align: middle;
  line-height: 24px;
}

.btn-icon-caret {
  padding: 0;
  border: none;
  background-color: transparent;
}

.icon-caret-left {
  width: 9px;
  height: 12px;
}

.icon-caret-down {
  width: 12px;
  height: 9px;
}

.Lesson-Header-Depth-2 .admin-accordion-item-container {
  border-left: 1px solid #000;
}