h2.upcoming-webinars-header {
    font-size: 32px;
    font-weight: normal;
    color: var(--cbit-dark-blue);
    margin-bottom: 24px;
}

.upcoming-webinars-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px 16px;
    width: 1100px;
}

.upcoming-webinars-no-webinars-container {
    width: 1100px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
}

h3.upcoming-webinars-no-webinars-header {
    color: var(--cbit-dark-blue);
    font-weight: 400;
    font-size: 24px;
    text-align: center;
}

.upcoming-webinar-item {
    width: 350px;
    display: flex;
    flex-direction: column;
    padding: 24px;
    border: 2px solid #AFC6CC;
}

.upcoming-webinar-item-title-container {
    width: 100%;
    min-height: 40px;
    max-height: 100px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-bottom: 16px;
}

h4.upcoming-webinar-item-title {
    color: var(--cbit-dark-blue);
    font-weight: bold;
    font-size: 24px;
}

h5.upcoming-webinar-item-info-title {
    color: #353B3C;
    font-weight: 700;
    font-size: 16px;
    max-width: 700px;
    text-transform: capitalize;
}

.upcoming-webinar-item-description-container {
    padding-bottom: 40px;
    min-height: 120px;
    max-height: 150px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-bottom: 16px;
}

p.upcoming-webinar-item-description {
    color: #353B3C;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 16px;
}

.upcoming-webinar-item-btn-container {
    width: 100%;
}

.upcoming-webinar-item-btn {
    width: 100%;
    padding: 10px 0 8px;
    color: var(--cbit-blue);
    border: var(--cbit-blue) 2px solid;
    background-color: transparent;
    font-weight: 500;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 0.10em;
    border-radius: 4px;
}

.upcoming-webinar-item-btn:hover {
    background-color: #D6EFF5;
    color: #00A3C1;
    border: #00A3C1 2px solid;
}

.upcoming-webinars-load-more-container {
    width: 1100px;
    display: flex;
    margin: 36px 0 0;
}

.upcoming-webinars-load-more-button {
    background: transparent;
    color: var(--cbit-blue);
    border: 2px solid var(--cbit-blue);
    border-radius: 4px;
    padding: 8px 20px 6px;
    text-transform: uppercase;
    font-weight: 600;
    margin: 0 auto;
}

.upcoming-webinars-load-more-button:hover {
    background-color: #D6EFF5;
}

/* upcoming webinars modal */
.upcoming-webinars-modal {
    max-width: 640px;
}

.upcoming-webinars-modal-title-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
}

h4.upcoming-webinars-modal-title {
    color: var(--cbit-blue);
    font-weight: 400;
    font-size: 24px;
    text-transform: uppercase;
}

.upcoming-webinars-modal-btn-close svg {
    font-size: 28px;
    color: var(--cbit-dark-blue);
}

.upcoming-webinars-modal-btn-close svg:hover {
    cursor: pointer;
}

.upcoming-webinars-modal-container {
    padding: 40px;
}

h2.upcoming-webinars-modal-body-title {
    color: var(--cbit-dark-blue);
    font-weight: normal;
    font-size: 32px;
}

.upcoming-webinars-modal-body-info-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px 16px;
    padding-top: 40px;
}

.upcoming-webinars-modal-body-info-item {
    width: 260px;
}

h5.upcoming-webinars-modal-body-title {
    color: var(--cbit-dark-blue);
    font-weight: 600;
    font-size: 18px;
}

p.upcoming-webinars-modal-body-text {
    color: #353B3C;
    font-weight: normal;
    font-size: 16px;
}

p.upcoming-webinars-modal-body-text.instructor {
    text-transform: capitalize;
}

.upcoming-webinars-modal-description-container {
    padding-bottom: 80px;
}

.upcoming-webinars-modal-btn-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.upcoming-webinars-modal-btn {
    width: 210px;
    padding: 10px 0 8px;
    background-color: #FF6F36;
    color: #FFFFFF;
    border: none;
    border-radius: 4px;
    text-transform: uppercase;
    font-weight: 400;
}

.upcoming-webinars-modal-btn.cancel {
    background-color: transparent;
    border: var(--cbit-blue) 2px solid;
    color: var(--cbit-blue);
}

.upcoming-webinars-modal-btn.cancel:hover {
    background-color: #D6EFF5;
    color: #00A3C1;
}

.upcoming-webinars-modal-btn:hover {
    cursor: pointer;
    background-color: #bd5127;
}