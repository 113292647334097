.header-navbar {
    height: var(--cbit-nav-bar-height);
    /* padding: 8px 0; */
    background-color: #fff;
    z-index:100;
    box-shadow: 0px 8px 24px #0000001c;
    position: relative;
  }

  .admin-header-nav {
    background-color: var(--cbit-dark-blue);
    color: #fff;
  }

  .admin-header-nav .nav-item-text,
  .admin-header-nav .nav-item-text:hover,
  .admin-header-nav .greeting,
  .admin-header-nav .iconProfile{
    color: #fff;
  }


  .admin-header-nav path:first-child {
      stroke: #fff;
      stroke-width: 2;
  }

  .admin-header-nav path:last-child {
    stroke: transparent;
    fill: none;
  }

.admin-header-nav g {
    fill: var(--cbit-dark-blue) !important;
}

.admin-header-nav text {
    fill: #fff !important;    
}

.logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.logo-container {
    /* min-width: 400px; */
    height: 80px;
    /* margin: auto 0;         */
    margin: 0 0 0 14px;
}
.logo-link {
    height: 100%;
    width: 100%;
    display: block;
}

.navbar-inner {    
    height: 100%;
    display: flex;
}

.nav-items-container {     
    width: 100%;
    height: 100%;
    align-self: center;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.nav-item-text {
    /* border-bottom: 5px solid transparent; */
    font: normal normal 600 18px/30px var(--cbit-font);
    height: 100%;    
}
.nav-item-text + .nav-item-text {
    margin-left: 40px;
}
.nav-item-text:hover {
  text-decoration: none;
}
.nav-item-text,
.nav-item-text:hover {
  color: var(--cbit-dark-blue);
}

.user-navigation-items {
    min-width: 312px;
    height: 100%;
    margin-right: 24px;    
}

.user-navigation-items.home {
    min-width: unset;
    margin-right: 24px;
}

.admin-dashboard-logo-text {
    font-size: 16px;
    font-weight: 600;
    color: #9AB5BF;
}