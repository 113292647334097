.admin-breadcrumb,
.admin-breadcrumb:hover,
.admin-bread-crumbs-arrow,
.admin-active-breadcrumb
{
    font-weight: 600;
    line-height: 34px;
    font-size: 14px;    
    letter-spacing: 0.7px;
    color: var( --cbit-cadet-blue);
    
    text-transform: uppercase;
    text-decoration: underline;
    cursor: pointer;
}

.admin-active-breadcrumb {
    text-decoration: none;
    color: var(--cbit-dark-blue);
}   

.admin-bread-crumbs-container {
    list-style: none;
    padding: 0;
    margin: 0;
}

.admin-bread-crumbs-container li {
    display: inline;

}

.admin-bread-crumbs-arrow{
    padding: 0 16px;    
    text-decoration: none;    
    
}