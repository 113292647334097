.consultation-plan-form.consultation-info {
    margin-bottom: 0;
    width: 540px;
    padding: 40px 32px;
}

.consultation-info .view-details-text-container{
    margin-bottom: 24px;
}

.view-details-btn-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 16px;
    justify-content: flex-end;    
}
.btn-cbit-primary.btn-book-session,
.btn-cbit-minor.btn-book-session {
    width: 206px;
}

.btn-consultation-close-modal {
    position: absolute;
    top: 5px;
    right: 16px;        
    font-size: 24px;
    color: var(--cbit-dark-blue);
}
.btn-consultation-close-modal:hover {
    color: var(--cbit-blue);
}

