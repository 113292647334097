.login-form,
.forgot-form {
    padding: 1rem;
    background-color: #ffffff;
    border-radius: 0.5rem;
    box-shadow: 0 0 5px -2px #393e46;
    margin-top: 1rem;
}

.login-form .btn-cbit-primary,
.forgot-form .btn-cbit-primary {
    width: 144px;
    height: 44px;
}

.login-alert {
    margin-top: 12px; 
}

.login-form-button-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
}

.forgot-creds-container {
    width: 100%;
    max-width: 33%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
}

.forgot-creds-container a {
    font-size: 14px;
    text-decoration: none;
}