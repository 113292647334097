.consultation-review-form {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 700px;
}
.consultation-review-form p {
    font-size: 18px;
}

.review-btn-container {
    margin-bottom: 16px;
}

.review-btn-container span {
    margin-right: 10px;
    font-weight: bold;
}

.consultation-review-form .btn-cbit-link {
    text-transform: capitalize;
    text-decoration: underline;
}

.consultation-review-form .btn-cbit-link:hover {
    color: var(--cbit-bondi-blue);
}

.rating-input-container {
    display: flex;
    justify-content: flex-end;
    flex-direction: row-reverse;
}

/**
    child combinator selector
    aka select all labels with the parent qualityRating
**/
.rating-input-container > label {
    overflow: hidden;
    color: var(--cbit-light-gray);               
}

.rating-input-container > label:hover ~ label,
.rating-input-container > label:hover
{
    color: var(--cbit-orange);
}

.rating-input-container > label::before {
    content: '★';
}

.rating-input {
    visibility: hidden;
}

.rating-label {
    font-size: 2.5rem; /*40px based on 1rem is 16px*/
    cursor: pointer;
}

.rating-input:checked ~ label {
    color: var(--cbit-orange);
} 


.consultation-review-comments {
    width: 100%;
}

.rating-container {
    margin-bottom: 14px;
}

.review-user-profile {
    overflow-y: auto;
    max-width: 700px;
    max-height: 600px;
}