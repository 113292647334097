.cbit-tooltip  {
    position: relative;
}

.cbit-tooltip .cbit-tooltip-text {
    visibility: hidden;
    width: 18em;    
    background-color: var(--cbit-black);
    color:#fff;
    text-align: left;
    border-radius: 7px;
    padding: 10px;
    position: absolute;
    top: 100%;
    left: 0;
    margin-right: calc(100% * 2);
    z-index: 999;
}

.user-dashboard .consult-box .cbit-tooltip .cbit-tooltip-text,
.user-dashboard .referral-box .cbit-tooltip .cbit-tooltip-text {
    top: 70%;    
}

.user-dashboard .consult-box .cbit-tooltip .cbit-tooltip-text {    
    filter:grayscale(0) brightness(0.5);  
}

.cbit-tooltip .cbit-tooltip-text::after {
    content: " ";
    position: absolute;
    bottom: 100%;  
    left: 10%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent var(--cbit-black) transparent;
  }

.cbit-tooltip:hover .cbit-tooltip-text {
    visibility: visible;
}