/* Navigation Panel */
.social-connection-layout .nav-panel-title {
    margin: 0;
    font: normal normal 300 40px/44px "Open Sans";
    letter-spacing: 0;
    color: #FFFFFF;
    align-self: center;
}

.social-connection-layout .navigation-side-panel {
    background-color: var(--cbit-inner-accordion-color);
    font-family: var(--cbit-font) !important;
}

.social-connection-layout .default-navigation-side-panel .faded-navigation-section-label {
    font-weight: 600;
    line-height: 34px;
    font-size: 16px;
    letter-spacing: 0.8px;
    color: var( --cbit-cadet-blue);
    text-transform: uppercase;
    text-decoration: underline;
    cursor: pointer;
    font-family: var(--cbit-font) !important;
    padding: 0;
}

.social-connection-layout .default-navigation-side-panel.open {
    transform: translateX(0);
    transition: transform 0.3s ease-in;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
}


.social-connection-layout .default-navigation-side-panel.closed {
    transform: translateX(-100%);
    /*display: none; !* TODO: This is used because nav side panel width persists *!*/
    visibility: hidden;
    transition: visibility 0.1s, transform 3s ease-out;
}

.social-connection-layout .navigation-side-panel-header.social-connect-side-panel {
    min-height: 160px;
}

.social-connection-layout .nav-menu-collapse-icon-container {
    background-color: transparent;
    border: none;
    padding: 0;
}

.social-connection-layout .default-navigation-side-panel .navigation-side-panel-header {
    padding: 16px 16px 16px 24px;
    display: flex;
}

.social-connection-layout .default-navigation-side-panel .navigation-side-panel-inner {
    height: calc(100vh - 380px);
    overflow: auto;
}

.social-connection-layout .default-navigation-side-panel .navigation-side-panel-inner-header {
    width: 100%;
}


.social-connection-layout .navigation-side-panel-inner-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.social-connection-layout .navigation-side-panel-actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.social-connection-layout .nav-menu-collapse-icon-container {
    width: 34.59px;
    align-self: center;
}

.social-connection-layout .nav-menu-collapse-icon-container img {
    width: 100%;
}
.social-connection-layout .nav-side-panel-action-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 64px;
    padding: 10px 0 10px 24px;
    width: 100%;
    cursor: pointer;
    border-bottom: 1px solid var(--cbit-cadet-blue);
}

.social-connection-layout .nav-side-panel-action-container:hover {
    background-color: var(--cbit-side-panel-hover);
    text-decoration: none;
}

.social-connection-layout .nav-inner-panel-text {
    text-align: left;
    font-weight: normal;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: 0.9px;
    color: var(--cbit-dark-blue);
    text-transform: capitalize;
}

.social-connection-layout .active-nav-item .nav-inner-panel-text {
    font-weight: bold;
}

.social-connection-layout .text-transform-normal {
    text-transform: none;
}


