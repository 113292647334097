.consultation-filters {
    width: 100%;
    height: 100%;
    max-height: 48px;
    display: flex;
    flex-direction: row;
    gap:16px;
    margin-bottom: 40px;
}

#previous-sessions.consultation-filters {
    flex-wrap: wrap;
    margin-bottom: 60px;
}

.consultation-filters-container {
    width: 100%;
    max-width: 900px;
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.consultation-filter-select {
    max-width: 341px;
    width: 100%;
    cursor: pointer;
}

.empty-session-toggle {
    display: flex;
    align-items: center;
    gap: 16px;
}

.upcoming-consultations {    
    width: 100%;
}

/*.mobile-consultation-filters {*/
/*    display: none;*/
/*}*/