.referral-users-container {
    width: 36%;
    min-width: 414px;
    background-color: #fff;
    padding: 0 24px 24px 0;
    overflow-y: scroll;
    scroll-behavior: smooth;
}

.referral-users-container .cbit-dashboard-header{
    font-size: 22px;
 }

 .filter-age-group {
    display: flex;

 }

 .referral-users-container .cbit-row {
    gap: 16px;
 }

 .referral-users-container .cbit-form-group {
    width: 100%;
 }

 .filter-age-group .user-form-checkbox {
     margin-right: 16px;
 }

 .filter-age-group .checkbox-cbit {
    display: block;
 }

 .referral-address-legend {
   display: flex;
   gap: 20px;
   margin-bottom: 16px;
 }

 .referral-address-legend .referral-user-marker-container {
   display: inline-block;
 }

.referral-user-list-container.mobile {
    display: none;
}

.referral-users-container.mobile {
    display: none;
}

 /*@media only screen and (max-width: 866px) { */
 /*  .referral-users-container {*/
 /*     min-width: auto;*/
 /*     width: 100%;*/
 /*     height: 50%;*/
 /*  }*/
 /*}*/

 @media only screen and (max-width: 410px) {
   /*.referral-users-container .cbit-dashboard-header {*/
   /*   font-size: 18px;*/
   /*}*/

   /*.referral-users-container .cbit-label {*/
   /*   font-size: 14px;*/
   /*}*/

   /*.referral-state-row.cbit-row {*/
   /*   flex-direction: column;*/
   /*}*/

   /*.filter-age-group {*/
   /*   display: flex;*/
   /*   flex-direction: column;*/
   /*}*/

   /*.referral-address-legend {*/
   /*   flex-direction: column;*/
   /*}*/

 }