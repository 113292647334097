

form.user-form {
    max-width: 1024px;
    min-height: 956px;
    height: auto;
    margin:  0 auto;    
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
    overflow: auto;
}

.user-form .form-control.is-invalid {
    background-image: none;
}

.admin-description-field{
    min-height: 100px;
    max-height: 340px;
}

.user-form-checkbox-container {
    display: block;
}
.user-form-checkbox-container .checkbox-label-cbit {    
    font-size: 18px;
}
.checkbox-label-cbit input {
    margin-right: 12px;
}

.user-form-checkbox-container input,
.user-form-checkbox-container label {    
    cursor: pointer;
}

.user-form .btn-cbit-primary {
    display: block;
    margin-right: 0;
    margin-left: auto;
    width: 144px;
    height: 44px;
    margin-top: 16px;
}

.user-form .form-group {
    height: calc(78px + 20px);
}

.user-form-checkbox {
    margin-bottom: 16px;
}


.password-container {
    height: auto;
    position: relative;
}


@media (min-width: 1280px) {
    .user-form {
        min-width: 380px;
    }
}