.sliderGroup
{
    display:inline;    
}
.sliderMenu
{
    display:inline-block;
    position: absolute;
    width:362px;
    height:calc(100vh - 80px);
    background: var(--cbit-pastel-blue);
    
    -webkit-transform: translate3d(-0px,0px,0px);
    transform:translate3d(-0px,0px,0px);
    transition: all 0.5s ease 0s;
    -webkit-transition: all 0.5s ease 0s;
}

.sliderMenu.isClosed
{
    -webkit-transform: translate3d(362px,0px,0px);
    transform: translate3d(362px,0px,0px);
}

.sideBarMenu
{
    height:calc(100vh - 80px);
    background: var(--cbit-pastel-blue);

}
.addVideo-text{
    margin-top: 6px;;
    margin-left:32px;
    margin-bottom:24px;
}
.add-video-container {
    cursor: pointer;
}

.add-video-container:hover .addVideo-titleText,
.add-video-container:hover .addVideo-lengthText{
    color: var(--cbit-bondi-blue);
}

.addVideo-box{
    margin-left:32px;
    width: 280px;
}
.addVideo-box img {
    width: 100%;
}
.addVideoName{
    margin-left:32px;
    margin-top:32px;

    font: normal normal bold 16px/34px Open Sans;
    letter-spacing:2.4px;
    text-transform: uppercase;
    color:var(--cbit-blue);
    max-width:240px;
}
.addVideo-titleText{
    font: normal normal bold 18px/20px Open Sans;
    color:var(--cbit-black);
}
.addVideo-lengthText{
    font: normal normal normal 14px/34px Open Sans;
    color:var(--cbit-black);
}
.add-video-separator{
    background-color:var(--cbit-pastel-blue);
    width: 280px;
    height:4px;
    margin-left:32px;
    margin-bottom:17px;
}
.no-content-additional-video-container {
    margin-left: 32px;
    width: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.no-content-text {
    text-transform: capitalize;
    /* text-align: center; */
    font-size: 16px;
    color: var(--cbit-black)
  }
  .no-content-image-container {
    width: 120px;
    text-align: center;
  }
  .no-content-image img {
    width: 100%;
  }


.pdf-toolbox-box {
    cursor: pointer;
}
.pdf-toolbox-box:hover {
    text-decoration: none;   
}
.pdf-toolbox-box .addVideo-box {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--cbit-black);
    /* border-radius: 7px; */
    background-color: var(--cbit-dark-blue);
}
.pdf-toolbox-box:hover  .addVideo-titleText{
    color:var(--cbit-blue);    
}
.pdf-toolbox-box .addVideo-titleText {
    margin-top: 6px;
}
.pdf-toolbox-box .addVideo-box{
    width:280px;
    height:151px;
    
}
.pdf-toolbox-box img {
    height: 100%;
    filter: brightness(0) invert(1);
}
.Menu-Content
{
    position:relative;
    float:left;
}
.menuTab-Container{
    position:relative;
    left:-0px;
    width:100%;
}

.Internal-Content
{
    position:relative;
    float:left;
}
