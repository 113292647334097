.forgot-password-form {
    max-width: 800px;
    padding: 1rem;
    background-color: #ffffff;
    border-radius: 0.5rem;
    box-shadow: 0 0 5px -2px #393e46;
    margin: 1rem auto 0;
}

.password-reset-form .btn-cbit-primary {
    height: 44px;
    width: 144px;
}