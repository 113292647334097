.additional-content-form {
    width: 600px;
    margin: 0 auto;
}

.additional-content-form .btn-cbit-primary {
    width: 144px;
    height: 44px;
}

.additional-content-form .form {
    box-shadow: none;
}