/* Header */
.social-connection-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
}

    h2.social-connection-heading {
        font-size: 32px;
        font-weight: normal;
        color: var(--cbit-dark-blue);
    }

    .btn-social-connection {
        font-weight: 600;
        line-height: 34px;
        width: 100%;
        max-width: 226px;
    }

    .new-post-icon {
        display: none;
    }

/*  Search  */
.social-connection-search-container {
    width: 100%;
    margin-bottom: 32px;
}

    .social-connection-search-container form {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .social-connection-search-left,
    .social-connection-search-right {
        width: 100%;
        max-width: 225px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .social-connection-search-left select {
        width: 100%;
        height: 45px;
        border: 1px solid var(--cbit-cadet-blue);
        border-radius: 4px;
        padding: 0 8px;
        font-size: 16px;
        font-weight: normal;
    }

.social-connection-search-center {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-right: 10px !important;
}

.admin-search .social-connection-search-center {
    margin: 0 10px !important;
}

    .social-connection-search-center input {
        width: 100%;
        height: 45px;
        border: 1px solid var(--cbit-cadet-blue);
        border-radius: 4px;
        padding: 0 8px;
        font-size: 16px;
        font-weight: normal;
    }

    .btn-social-connection-search {
        width: 100%;
        height: 45px;
        border: 1px solid var(--cbit-cadet-blue);
        border-radius: 4px;
        padding: 0 8px;
        font-size: 16px;
        font-weight: normal;
        background-color: var(--cbit-blue) !important;
        color: #fff;
    }

        .btn-social-connection-search .search-icon {
            display: none;
        }

        .btn-social-connection-search:hover {
            background-color: var(--cbit-dark-blue) !important;
        }

/* Subheader */
.social-connection-subheader {
    width: 100%;
    margin-bottom: 28px;
}

    h4.social-connection-subheading {
        color: var(--cbit-blue);
        text-transform: uppercase;
        font-weight: 600;
        font-size: 24px;
    }

/*  Social Connection Post  */
.social-connection-posts-container {
    width: 100%;
}

.social-connection-post {
    width: 100%;
    border-bottom: 1px solid var(--cbit-cadet-blue);
    padding: 24px 12px;
}

.social-connection-post:hover {
    background-color: var(--cbit-pastel-blue);
    cursor: pointer;
}

.social-connection-post.editing:hover {
    background-color: #fff;
    cursor: default;
}

.social-connection-post a {
    color: var(--cbit-dark-blue);
    text-decoration: none !important;
}

    .social-connection-post-header,
    .social-connection-post-header-editing {
        width: 100%;
    }

    .social-connection-post-header-editing {
        margin: 0;
        padding-left: 175px;
    }

    .social-connection-post-container {
        width: 100%;
        display: flex;
    }

    .social-connection-post-header-left,
    .social-connection-post-reply-left,
    .social-connection-post-reply-right {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 200px;
    }

    .social-connection-profile-image {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-color: var(--cbit-pastel-blue);
        margin-right: 24px;
        margin-bottom: 16px;
    }

    h5.social-connection-username {
        color: #000;
        font-weight: bold;
        font-size: 20px;
        margin-right: 22px;
   }

    .social-connection-post-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: 10px 24px;
    }

    h4.social-connection-post-content-heading {
        color: var(--cbit-dark-blue);
        font-weight: normal;
        font-size: 24px;
        margin-bottom: 16px;
    }

    p.social-connection-post-content-text {
        color: #000;
        font-weight: normal;
        font-size: 20px;
        margin-bottom: 16px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

/*  Deleted Post  */
.social-connection-post.deleted {
    border-bottom: 1px solid var(--cbit-light-gray);
}

    .social-connection-profile-image.deleted {
        filter: grayscale(1);
    }

    h5.social-connection-username.deleted,
    h4.social-connection-post-content-heading.deleted,
    h5.social-connection-date.deleted,
    h5.social-connection-reply-count.deleted,
    p.social-connection-post-content-text.deleted {
        color: grey;
    }

/* Edit post */
.social-connection-edit-post-container {
    width: 100%;
    display: flex;
    gap: 8px;
}

    .btn-social-connection-edit-post,
    .btn-social-connection-cancel-edit-post {
        font-weight: 600;
        padding: 4px 16px;
    }

    .btn-social-connection-edit-post {
        background-color: var(--cbit-blue) !important;
    }

    .btn-social-connection-edit-post:hover {
        background-color: var(--cbit-dark-blue) !important;
    }

    .btn-social-connection-cancel-edit-post {
        background-color: var(--cbit-orange) !important;
    }

    .btn-social-connection-cancel-edit-post:hover {
        background-color: #c04e20 !important;
    }

    .social-connection-editing-input-container,
    .social-connection-editing-textarea-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin: 0 0 16px 0;
    }

   .social-connection-editing-input-container input {
       width: 100%;
       height: 100%;
       font-size: 16px;
       font-weight: 400;
       line-height: 24px;
       color: var(--cbit-dark-blue);
       border: slategrey 1px solid;
       border-radius: 4px;
       padding: 8px;
       outline: none;
    }

   .social-connection-editing-textarea-container {
        margin: 0 0 16px 0;
   }

    .social-connection-editing-textarea-container textarea {
        width: 100%;
        height: 100%;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: var(--cbit-dark-blue);
        border: slategrey 1px solid;
        border-radius: 4px;
        padding: 8px;
        outline: none;
   }

.social-connection-post-header-editing select {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: var(--cbit-dark-blue);
    border: slategrey 1px solid;
    border-radius: 4px;
    padding: 4px 8px;
    outline: none;
    margin-bottom: 8px;
}

    .social-connection-post-header-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 200px;
    }

    h5.social-connection-reply-count {
        color: var(--cbit-dark-blue);
        font-weight: normal;
        font-size: 20px;
        margin-bottom: 16px;
    }

    h5.social-connection-date {
        color: #656a6b;
        font-weight: normal;
        font-size: 20px;
        font-style: italic;
    }

/* No posts container */
.social-connection-no-posts-container {
    width: 100%;
    padding: 20px 0;
    text-align: center;
}

    h4.social-connection-no-posts-text {
        color: var(--cbit-dark-blue);
        font-weight: normal;
        font-size: 24px;
    }

    .social-connection-post-footer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
    }

/* Post Toolbar */
.social-connection-post-toolbar {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

    .social-connection-post-toolbar-item {
        justify-content: flex-end;
    }

    .social-connection-post-toolbar-item {
        border: none;
        background-color: transparent;
    }

    .social-connection-post-toolbar span {
        margin-right: 5px
    }

    .social-connection-post-toolbar-item svg {
        color: var(--cbit-dark-blue);
        font-size: 18px;
    }

    .social-connection-post-toolbar-item.report svg:hover {
        color: var(--cbit-orange);
    }

    .social-connection-post-toolbar-item.ban span:not(.banned) svg:hover,
    .social-connection-post-toolbar-item.report span:not(.reported) svg:hover,
    .social-connection-post-toolbar-item.promote span:not(.promoted) svg:hover,
    .social-connection-post-toolbar-item.delete span:not(.deleted) svg:hover,
    .social-connection-post-toolbar-item.edit span:not(.edited) svg:hover {
        color: var(--cbit-blue);
    }

    .promoted svg {
        color: green;
    }

    .promoted svg:hover {
        color: #019201 !important;
    }

    .banned svg,
    .reported svg {
        color: var(--cbit-orange);
    }

    .banned svg:hover,
    .reported svg:hover {
        color: #c9572a !important;
    }

    .deleted-icon svg {
        color: var(--cbit-coral-red);
    }

    /* Social Connection Tooltip */
    .social-connection-post .cbit-tooltip .cbit-tooltip-text,
    .social-connection-single-post .cbit-tooltip .cbit-tooltip-text,
    .social-connection-single-post-reply .cbit-tooltip .cbit-tooltip-text {
        width: auto;
    }
