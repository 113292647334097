.my-profile-full-description {
    white-space: pre-line;
    max-width: 900px;        
    padding: 16px;
}

.my-profile-full-description div {
    overflow-y: auto;
    max-height: 900px;
}

.my-profile-description-name {
    font-size: 32px;    
    letter-spacing: 0;
    font-weight: normal;
    text-transform: capitalize;
}