
.user-profile-menu {    
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.user-profile-menu-icon-container {
    position: relative;    
    height:100%;
    display: flex;
    align-items: center;
}

.user-profile-menu-icon svg,
.user-profile-menu-icon div {    
    text-anchor: middle;
    cursor: pointer;    
    /* dominant-baseline: middle; */
}

.user-profile-menu-icon-active svg g {
    
    fill: var(--cbit-dark-blue);    
}

.user-profile-menu-icon-active svg text {
    fill: #fff;
}

.profile-image-icon.badge {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 10px;
    right: 0;
    background-color: var(--cbit-orange);
    color: #FFF;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.welcome-text-container {
    display: inline-block;    
}

.welcome-text-container p {
    color: var(--cbit-orange);
    font: normal normal bold 18px/30px var(--cbit-font);
    letter-spacing: 0;
    font-weight: bold;
    line-height: 30px;
    /**Need the 5px to match the border bottom of the nav text**/
    /* margin: 0 16px 5px 50px;  */
    margin:0 16px 0 0;
    display: inline-block;  
}

.welcome-text-container .greeting {
    color: var(--cbit-dark-blue);
}

.greeting-text {
    /* margin: 0 16px 5px 0;  */
}

div .menu-item-container {
    position:absolute;    
    font: normal normal 600 16px/30px var(--cbit-font);
    top: 80px;
    right: 16px;
    width: 216px;     
    height: auto;
    background-color: #fff;    
    border-radius: 4px;  
    z-index: 999;
    text-align: left;    
    /* border: 1px solid var(--cbit-dark-blue);  */
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
      
}

div .menu-item-container.menu-arrow-top::after {
    content: " ";    
    position: absolute;
    right: 26px;
    top: -6px;    
    border: 6px solid #fff;
    border-color: white white transparent transparent;    
    transform: rotate(-45deg);        
     box-shadow: 3px 0px 2px 0 rgba(0, 0, 0, 0.4) -3px 0px 2px 0 rgba(0, 0, 0, 0.4);
}

.menu-items {
    padding: 0;
    margin: 0;
    list-style: none;
    color: var(--cbit-dark-blue);
}

.menu-item {
    height: 46px;
    cursor: pointer;
}

.menu-item.active {
    background-color: #D6F2F9;
}

.menu-item.active:hover {
    background-color: #ccedf6;
}

.menu-item:hover {
    background-color:#ECFCFF;
}

div .menu-items-inactive {
    display: none;
}
div .menu-items-active {
    display: block;
}

.menu-item-link {   
    display: block;
    height: 100%;
    width: 100%; 
    line-height: 46px;
    vertical-align: middle;
}
.menu-item-link, 
.menu-item-link:hover {
    color: var(--cbit-dark-blue);
    text-decoration: none;
}

.menu-item-text {
    padding: 0 24px;
    position: relative;
}

.profile-image-icon.notification-badge {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 8%;
    left: 78%;
    background-color: var(--cbit-orange);
    color: #FFF;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}
