.user-progress-view {
    text-transform: uppercase;
    /* min-width: 640px; */
}
.user-progress-view .cbit-dashboard-header{
    font-size: 24px;
    margin-bottom: 16px;
}

.user-progress-module-text{
    font-size: 16px;
    font-weight: bold;
    color: var(--cbit-cadet-blue);
}


.user-progress-module .content-type-label {
    margin: 0
}

.user-progress-lessons {
    text-transform: capitalize;
    display: flex;
    flex-direction: column;
    gap: 16px;
    list-style: none;
    padding: 0;
}

.user-progress-lessons .lesson-type-icon {
    min-width: 22px;
}

.user-progress-lessons li {
    display: flex;
    align-items: center;
    gap: 8px;
    
}

.user-progress-location {
    border-radius: 8px;
    margin-left: 16px;
    padding: 0 8px;
    color: white;
    min-width: 131px;
    max-height: 24px;   
    background-color: var(--cbit-orange);
}