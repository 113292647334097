.user-management-container.cbit-container{
    border-radius: 0;
}

.user-management-container {    
    margin: 0 auto;
    width: 90%;
    min-width: 900px;
    max-width: 1600px;
    min-height: calc(100vh - var(--cbit-nav-bar-height));
    overflow: auto;
}

.user-management-container .cbit-header {
    margin-bottom: 0;    
}

.user-management-header-container {
    display: flex;
    justify-content: space-between;
}

.user-management-header-container .btn-cbit-primary {
    width: 170px;
    height: 44px;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 34px;
}

.user-management-header-container .btn-cbit-primary:hover {
    text-decoration: none;
    color:#fff
}

.user-management-container .cbit-table-column-header {    
    max-width: 180px;    
    min-width: auto;
    width: 20%;        
    padding: 0 20px 0 0;    
}

.user-management-container .user-table-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;    
}

.user-table-header:hover {
    opacity:  0.7;
    cursor: pointer;
}

.user-table-sort-container {
    align-self: center;
    max-width: 12px;
}

.user-management-container .sort-icon {
    margin: 0 0 0 16px;
    min-width: 12px;
    height: auto;            
}

.user-management-container .sort-icon img { 
    width: 100%;
}

.user-management-container .cbit-table-container {
    max-width: 1488px;    
    overflow-x: auto;
}

/**
Role Styles
**/
.user-management-container .cbit-table-cell:nth-child(1) {
    max-width:140px;    
}

.user-management-container .cbit-table-cell:nth-child(1) .cbit-table-cell-content{
    width:140px;    
}

/**
First Name Styles
**/
.user-management-container .cbit-table-cell:nth-child(2),
.user-management-container .cbit-table-cell:nth-child(3) {
    max-width: 165px;    
}

.user-management-container .cbit-table-cell:nth-child(2) .cbit-table-cell-content,
.user-management-container .cbit-table-cell:nth-child(3) .cbit-table-cell-content {    
    width: 165px;
}

.user-management-container .cbit-table-cell:nth-child(4) {
    max-width:368px;    
}

.user-management-container .cbit-table-cell:nth-child(4) .cbit-table-cell-content {
    width:368px; 
}

.user-management-container .cbit-table-cell:nth-child(5) {
    max-width:110px;
}

.user-management-container .cbit-table-cell:nth-child(5) .cbit-table-cell-content {
    width:110px;    
}

.user-management-container .cbit-table-cell:nth-child(6) {
    max-width:155px;
}

.user-management-container .cbit-table-cell:nth-child(6) .cbit-table-cell-content {
    width:155px;    
}
