.user-dashboard {
    height: calc(100vh - 80px);
    min-height: 392px;
    /* width: 100%;     */
    overflow: auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, minmax(22.77%, auto));
    /* max-height: 1080px;
    max-width: 1920px; */
    margin: 0 auto;
    /* grid-template-rows: 1fr repeat(4, 2fr) 1fr; */
    grid-gap:14px;
    padding: 0 14px;
  }

  .user-dashboard a {    
    display: block;
    height: 100%;
    text-decoration: none;    
  }

.training-box {
  background-image: url("../../../resources/images/Image_Train_crop.png");
  grid-column: 1 / 3;
  grid-row: 1/ 3
}
.toolbox-box, 
.consult-box {
  background-image: url("../../../resources/images/Image_Practice_crop.png");
  grid-column: 3 / 5;
  grid-row: 1 / 2;
}
.consult-box {
  background-image: url("../../../resources/images/Image_Consult_crop.png");
  grid-row: 2/3;
}
.social-box {
  background-image: url("../../../resources/images/image_social_connect.png");  
}
.social-box.disabled {
  filter: grayscale(1) brightness(0.8);
}
.family-box {
  background-image: url("../../../resources/images/image_family_support.png");
}
.referral-box {
  background-image: url("../../../resources/images/Image_Referral_crop.png");
}
.ceu-box {
  background-image: url("../../../resources/images/Image_CEU_crop.png");
}

.dashboard-box {
  /* height:100%;
  width:100%;   */
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;  
  background-repeat: no-repeat;
}

.dashboard-box.disabled {
  filter:grayscale(1) brightness(1);  
}

.consult-box.dashboard-box.disabled {
  filter:grayscale(1) brightness(2);  
}

.consult-box.disabled .dashboard-text {
  filter:grayscale(0) brightness(.5);  
}

.referral-box.disabled .dashboard-text {
  filter:grayscale(0);  
}

.dashboard-box.disabled,
.dashboard-box.disabled a:hover{
  cursor: default;
  /* pointer-events: none; */
}

.dashboard-box.disabled .cbit-tooltip {
  height: 100%;
}

.dashboard-box a {
  display: block;
  height: 100%;
  text-decoration: none;
}

.dashboard-text {
  display: inline-block;  
  font-family: var(--cbit-font);
  font-weight: 300;
  font-size: calc(1rem + 2vw);
  letter-spacing: 0px;
  color: #FFFFFF;
  text-transform: uppercase;
  opacity: 1;  
  line-height: normal;
  padding: 24px 24px 0 24px;  
}

.user-dash-panel a {
    text-decoration: none;
}

@media only screen and (max-width: 1081px) and (min-width: 789px){
  .dashboard-text {
    font-size: calc(1rem + 1.5vw);
  }
}

@media only screen and (max-width: 900px) {
  .user-dashboard {
    min-height: auto;
  }
  .dashboard-text {
    font-size: 23px;
  }
  .user-dashboard {
    display:flex;
    flex-direction: column;
    overflow-y: auto;
  }
  
  .dashboard-box {
    height:100%;
  }
}


