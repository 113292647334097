.consultant-image-container {
    display: flex;
    gap: 24px;
    margin-bottom: 32px;
}

.consultant-image-container .empty-profile-image {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    background-color: var(--cbit-pastel-blue);
}

.consultant-my-profile-image-container {
    width: 160px;
    min-height: 160px;
}

.consultant-image-container .btn-cbit-minor {
    width: 192px;
    height: 44px;
}


.consultant-profile-container {
    width: 100%;
    max-width: 639px;
}


.consultant-profile-container label {
    margin-bottom: 0;
    
}

.consultant-profile-container p {
    height: 48px;
    font-size: 16px;
    color: var(--cbit-black);
}
.consultant-profile-container .cbit-column {
    margin-bottom: 1rem;
}

.consultant-profile-container .form-input {
    height: 48px;
    width: 100%;    
}

.consultant-profile-container #bio{
    height: 154px;
}

.consultant-profile-container .cbit-btn-container {
    margin-top: 40px;
}

.consultant-profile-container .cbit-btn-container button {
    width: 144px;
    height: 44px;
}

.consultant-upload-container {
    display: flex;    
}

.consultant-upload-container,
.consult-profile-name-container.cbit-row {
    gap: 16px;
}

.consultant-bio-description.form-input {
    margin-bottom: 8px;
}

p.consultation-profile-description {
    white-space: pre-line;    
    height: auto;
}

/* Mobile Form Fields */
.profile-desktop {
    display: flex;
}

.mobile-profile-name {
   display: none;
}

.mobile-form-container {
    display: none;
}

