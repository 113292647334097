
.admin-navigation-panel {
  min-width: 400px;
  background: #00516b;
  height: 100%;  
}
.admin-content-menu-panel {
  width: 400px;
  min-width: 400px;
  overflow-y: scroll;
  background-color: #ebf3f5;
}

.admin-dashboard {
  background-color: #fff;
  height: calc(100vh - 80px); /*the 80pxs is the height of the nav bar*/
}

.admin-dashboard-body {
  display: flex;
  height: 100%;
  width: 100%;
}

.admin-view-header {
  color: #00516b;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 24px;
  padding-top: 5px;

}

.admin-view-panel {
  height: 100%;
  width: 100%;
  
  
}

.admin-view-inner-panel {
  padding: 14px 56px 0 56px;
  height: 100%;
  overflow-y: scroll;
}

.admin-view-title {
  font: normal normal normal 40px/55px 'Open Sans';
  font-weight: 400;
  height: 55px;
  margin: 0;
  color: var(--cbit-dark-blue);
}

.admin-view-tools-container {
  height: 44px;
  margin-bottom: 16px;
}

.admin-view-subtitle {
  font: normal normal 600 24px/42px 'Open Sans';
  text-transform: uppercase;
  width: 211px;
  height: 33px;
  margin: 0;
  font-size: 24px;
  color: var(--cbit-bondi-blue);
}

.admin-spinner {
  align-self: flex-start !important;
}

.padding {
  height: 32px;
}

.btn-save-content {
  width: 144px;
  height: 44px;
  float: right;
  align-self: flex-end;
  margin: 0;
}

.branch-selector {
  min-width: 100%;
}

.branch-selector .list-group-item:hover {
  cursor: pointer;
}

.content-list-container {
  text-align: center;
}

@media screen and (max-width: 1469px) {
  .admin-navigation-panel {
    min-width: auto;    
  }

  .admin-navigation-panel .admin-nav {
    width: 300px;
  }

  .admin-navigation-panel .admin-nav-header-container {
    height: 250px;
  }

  .admin-navigation-panel .admin-nav-title {
    font-size: 30px;
  }

  .admin-navigation-panel .admin-nav-body {
    height: calc(100vh - 330.1px);
  }

  .admin-navigation-panel .admin-chapter-text {
    width: 100%;
  }

  .admin-navigation-panel .admin-lesson-text {
    width: 150px;
  }

  .admin-navigation-panel .lesson-type-icon {
    min-width: 22px;
  }

  .admin-view-panel .admin-view-title {
    font-size: 30px;
  }

  .admin-content-menu-panel {
    min-width: 300px;
    overflow-x: hidden;
  }

  .admin-content-menu-panel  .admin-content-menu-title {
    font-size: 20px;
  }

  .admin-content-menu-panel  .admin-content-menu-text {
    font-size: 16px;
  }
  
  .admin-content-menu-panel .admin-content-menu-item {
    width: 211px;    
    align-items: center;    
  }

  .admin-content-menu-panel .admin-content-menu-item-subheading {
    height: auto;
  }

  .admin-content-menu-panel .plus-icon,
  .admin-content-menu-panel .plus-icon svg {   
    width: 35px;
    height: 35px;
  }

  .admin-content-menu-panel .admin-content-menu-item-custom {
    height: 148px;
  }

  .admin-view-panel .content-item-title {
    font-size: 18px;
  }
} 
