.social-connection-my-posts-container {
    background-color: #fff;
    width: 100%;
}

    .social-connection-my-posts-page-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 32px;
    }

    h2.social-connection-my-posts-heading {
        font-size: 32px;
        font-weight: normal;
        color: var(--cbit-dark-blue);
    }

.social-connection-my-posts-subheader {
    width: 100%;
    margin-bottom: 28px;
}

    h4.social-connection-my-posts-subheading {
        color: var(--cbit-blue);
        text-transform: uppercase;
        font-weight: 600;
        font-size: 24px;
    }
