.attendees-list,
.attendees-list-modal {
    list-style: none;
}

.attendees-list .btn-cbit-link {
    margin: 0;
    text-transform: capitalize;
}

.attendees-list-modal {
    text-transform: capitalize;    
    width: 300px;
    padding: 16px;
}

.attendees-list-modal .cbit-header {
    margin-bottom: 0;
}

.attendees-list-modal-item {
    margin: 16px 0;
}
.attendees-list-item .btn-cbit-link {
    text-decoration: underline;    
    font-weight: 500;
}

.attendees-list {
    max-height: 200px;
    max-width: 500px;
    overflow-y: auto;
}

