.custom-modal {
  width: fit-content;
  max-width: 100%;
}
.modal-content {
  border: none;
}

.custom-modal .btn-cbit-icon {
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 0;
  border: none;
  width: 16px;
  background-color: transparent;
}

.custom-modal .btn-cbit-icon:hover:enabled {
  background-color: transparent;
}

.custom-modal .btn-cbit-icon img {
  width: 100%;
}