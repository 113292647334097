.consultation-payment {
    max-width: 671px;
    width: 100%;
    min-height: 896px;
}

.consultation-payment .btn-cbit-minor {
    margin-top: 1rem;
    height: 44px;
    width: 100%;
}

.consultation-payment .product-name {
    color: var(--cbit-dark-blue);
    font: normal normal 600 18px/32px var(--cbit-font);
    text-transform: uppercase;
}