.referral-page, .referral-list-page {
    width: 100%;
    height: 100%;
    scroll-behavior: smooth;
}

.referral-page {
    background-color: #fff;
}
.referral-list-page {
    display: flex;
    flex-direction: row;
}

/*@media only screen and (max-width: 866px) { */
/*    .referral-list-page {*/
/*        flex-direction: column;*/
/*    }*/

/*}*/