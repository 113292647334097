.toggle-visibility {    
    position: absolute;
    top: 0;
    right: 16px;
    width: 28px;
    height: 100%;
    padding: 0;
    border: none;
    background-color: transparent;
}

.toggle-visibility img {            
    width: 100%;
    vertical-align: middle;
}

.toggle-visibility-open {
    height: 18px;
}

.toggle-visibility-hide {
    height: 21px;
}