
.no-border{
    border:0px;
    border-radius:0px;
}
.full-width{
    width:100%;
}

.Accordion{
    width:100%;
    height:100%;
}
.accordion-text{
    /* font-family:Arial; */    
   text-align:left;
    font: normal normal 600 18px/20px "Open Sans";
    letter-spacing: 0.9px;
    color: #FFFFFF;
    text-transform: uppercase;
    width:284px;
}
.accordion-lesson-selected-text{
    font-size:16px;
    line-height:24px;
    font-weight:bold;
    color:black;
    max-width:240px;


}
.accordion-lesson-two-selected-text{
    font-size:16px;
    line-height:24px;
    font-weight:bold;
    color:black;
    max-width:180px;


}
.accordion-lesson-three-selected-text{
    font-size:16px;
    line-height:24px;
    font-weight:bold;
    color:black;
    max-width:140px;



}
.accordion-lesson-text{
    font-size:16px;
    line-height:24px;
    font-weight:normal;
    color:black;
    max-width:220px;



}
.accordion-lesson-two-text{
    font-size:16px;
    line-height:24px;
    font-weight:normal;
    color:black;
    max-width:180px;


}
.accordion-lesson-three-text{
    font-size:16px;
    line-height:24px;
    font-weight:normal;
    color:black;
    max-width:140px;


}
.float-left{
    float:left;
}
.float-right{
    float:right;
}
.completed-text-depth-0{
    text-transform: uppercase;
    color:#DEE5E7;
    text-size:12px;

    padding-right:5px;
}
.lesson-video-text-depth-1{
    color:#353B3C;
    text-transform: uppercase;
    text-size:12px;

    padding-right:5px;
}
.header-setting{
    min-height:24px;
    padding:10px 0px 10px 24px;
    display:flex;
    justify-content: space-between;
    align-content: center;
    width:100%;
}
.Branch-Header-Depth-0{
    background-color:#00A3C1 !important;
    min-height:64px;
    display:flex;
    box-sizing:content-box;
}
.Branch-Depth-1-Label{
    text-align:left;
}
.Branch-Header-Depth-1{
    background-color:var(--cbit-pale-blue) !important;
    padding:0px;
    height:56px;
}
.Branch-Header-Depth-2{
    background-color:var(--cbit-pale-blue) !important;
    padding:0px;
    height:56px;
}
.Lesson-Header-Depth-1{
    background-color:#EBF3F5 !important;
    padding:0px;
    height:56px;
}
.Lesson-Header-Depth-selected-1{
    background-color:var(--cbit-pale-blue) !important;
    padding:0px;
    height:56px;
}
.Lesson-Header-Depth-2{
    background-color:var(--cbit-pale-blue) !important;
    height:56px;
    padding:0px;
}
.Left-Buffer-10{
    padding-left:10px;
}
.Left-Buffer-24{
    padding-left:24px;
}
.Right-Buffer-9{
    padding-right:9px;
}
.Right-Buffer-12{
    padding-right:12px;
}
.Right-small-Buffer-14{
    padding-right:14px;
}
.small-width-20{
    padding-right:15px;
}
.orange-color{
    color:#FF6F36;
}
.sub-lesson-icon-adjust{
    align-self:center;
    height:24px;
}

.lesson-duration-buffer{
    min-width:30px;
}
.centerBar{
    width:1px;
    max-width:1px;
    min-width:1px;
    height:56px;
    background-color:#000000;
    margin-top:-20px;
    margin-left:8px;
}
.orange-selected{
    background-color:#FF6F36;
    width:8px;
    height:56px;
    position:relative;
    z-index:15;
    left:-24px;
    margin-right:-8px;

}
.orange-selected-sub{
    background-color:#FF6F36;
    width:8px;
    height:56px;
    position:relative;
    z-index:15;
    left:-35px;
    margin-right:6px;
}

.orange-selected-Lg{
    background-color:#FF6F36;
    max-width:8px;
    min-width:8px;
    height:56px;
    margin-top:-20px;
    margin-left:-25px;
}
.lesson-non-selected{
    background-color:#EBF3F5;
    max-width:8px;
    min-width:8px;
    height:56px;
    margin-top:-20px;
    margin-left:-25px;
}
.lesson-non-selected-Lg{
    background-color:#EBF3F5;
    max-width:56px;
    min-width:56px;
    height:56px;
    margin-top:-20px;
    margin-left:-25px;
}
.lesson-selected-md{
    background-color: var(--cbit-pale-blue);
    max-width:48px;
    min-width:48px;
    height:56px;
    margin-top:-20px;
    margin-left:-17px;
}
.disabled{
    background-color:lightgrey !important;
    color:grey !important;
}
.disabledBar{
    background-color:darkGray !important;
    color:darkGray !important;
}
.sub-lesson-selected{
    background-color: #BEE7EE;
}

.sub-lesson-highlight{
    background-color: #EBF3F5;
}
.sub-lesson-highlight:hover{
    background-color: #BEE7EE;
}
.sub-branch-container{
    width:100%;
    background-color: #BEE7EE;
}
.sub-branch-time{
    color:#353B3C;
    margin-left:13px;
    align-self:center;
}
.sub-branch-text-selected{
    font: normal normal normal 16px/20px "Open Sans";
    letter-spacing: 0px;
    color: #353B3C;
}
.sub-branch-content-buffer{
    height:56px;
}
.remove-padding{
    padding:0px !important;
}
.text-margin-15{
    margin-left:15px;
}
.text-margin-15-important{
    margin-left:15px !important;
}
