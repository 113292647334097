.referral-profile-body {
    display: flex;
    flex-direction: row;
    gap: 48px;
}

.referral-image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.referral-image-container > div:first-child {

    margin-bottom: 16px;
} 

.referral-image-container,
.referral-image-container .btn-cbit-minor { 
    width: 192px;
}

.referral-image-container {
    align-self: flex-start;
}

.referral-image-container .btn-cbit-link {
    color: var(--cbit-bondi-blue);
    text-transform: capitalize;
    text-decoration: underline;
    font-weight: 300;
}

.referral-image-container .input-txt-img-upload {
    height: 0;
}

.referral-image-container .upload-btn-container {
    margin-bottom: 12px;
}

.referral-image {
    width: 100%;
    height: 192px;
}

.referral-image .profile-image-icon {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.referral-form {
    width:600px;
}


