.toggle-switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin-bottom: 0;
}

.toggle-switch input {
    width: 0;
    height: 0;
    opacity: 0;
}

.slider, .slider:before {
    position: absolute;
    left: 0;
    -webkit-transform: .4s;
    transition: .4s;
}

.slider {
    cursor: pointer;
    top: 17px;
    right: 4px;
    bottom: 0;
    height: 2px;
    background-color: var(--cbit-light-gray);
}

.slider:before {    
    content: "";
    height: 26px;
    width: 26px;
    top: -13px;    
    bottom: 4px;
    background-color: #fff;        
}

input:checked + .slider {
    background-color: var(--cbit-battery-charged-blue);
}

input:focus + .slider {
    box-shadow: 0 0 1px var(--cbit-battery-charged-blue);
}

input:checked + .slider:before {
    -webkit-transform: translateX(30px);
    -ms-transform: translateX(30px);
    transform: translateX(30px);
    background-color: var(--cbit-battery-charged-blue);
}

input:checked + .slider.slider.round:before {
    border: 1px solid var(--cbit-battery-charged-blue);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border: 1px solid var(--cbit-light-gray);
    border-radius: 50%;
}