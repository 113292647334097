@media (max-width: 1280px) {
    /*  Navbar  */
    .logo-container {
        height: 64px;
    }

    .welcome-text-container p {
        font-size: 17px;
        line-height: 22px;
        margin: 0 12px 0 0;
    }

    .nav-item-text {
        font-size: 17px;
    }

    .nav-item-text + .nav-item-text {
        margin-left: 32px;
    }

    div .menu-item-container {
        font-size: 15px;
        width: 190px;
    }

    .user-profile-menu-icon svg {
        width: 45px;
        height: 45px;
    }

    .menu-item {
        height: 40px;
    }

    .menu-item-link {
        line-height: 40px;
    }

    /* Social Connection - Layout */
    /* Nav Sidebar */
    .nav-sidebar {
        width: 320px;
    }

    .AccordionContent {
        width: 320px;
    }

    /* Social Connection */
    /* Nav Sidebar - content */
    .nav-sidebar .title-container {
        min-height: 160px;
        padding: 12px 12px 12px 20px;
    }

    .nav-sidebar .back-to-dashboard {
        line-height: 27px;
        font-size: 15px;
        letter-spacing: 1px;
    }

    h2.nav-sidebar-title {
        font-size: 32px;
    }

    .nav-sidebar .sidebar-link {
        min-height: 51px;
        padding: 8px 0 8px 20px;
    }

    .nav-sidebar .sidebar-link-text {
        font-size: 20px;
        line-height: 26px;
        letter-spacing: 0.8px;
    }

    /* Social Connection */
    /* Main content container */
    .social-connection-main-content,
    .continuing-education-main-content,
    .inbox-main-content,
    .consultation-main-content,
    .therapist-support-main-content,
    .referral-main-content {
        padding: 38px 44px;
    }

    .isOpen .social-connection-main-content,
    .isOpen .continuing-education-main-content,
    .isOpen .inbox-main-content,
    .isOpen .consultation-main-content,
    .isOpen .therapist-support-main-content,
    .isOpen .referral-main-content {
        left: 320px;
        width: calc(100vw - 320px);
    }

    /* Social Connection */
    /* Header */
    h2.social-connection-heading {
        font-size: 25px;
    }

    .btn-cbit-minor,
    .btn-cbit-primary {
        letter-spacing: 1.44px;
        font-size: 16px;
    }

    .btn-social-connection {
        line-height: 27px;
        max-width: 180px;
    }

    .social-connection-header {
        margin-bottom: 25px;
    }

    /* Social Connection */
    /* Search */
    .social-connection-search-container {
        margin-bottom: 25px;
    }

    .social-connection-search-left,
    .social-connection-search-right {
        max-width: 180px;
    }

    .social-connection-search-left select {
        height: 36px;
    }

    .social-connection-search-center input {
        height: 36px;
    }

    .btn-social-connection-search {
        height: 36px;
    }

    .social-connection-subheader {
        margin-bottom: 22px;
    }

    /* Social Connection */
    /* Posts container */
    .social-connection-post {
        padding: 12px 9px;
    }

    .social-connection-post-header,
    .social-connection-post-header-editing {
        padding-left: 125px;
        margin-bottom: 6px;
    }

    .social-connection-post-header-left,
    .social-connection-post-reply-left,
    .social-connection-post-reply-right {
        width: 180px;
    }

    .social-connection-profile-image {
        width: 70px;
        height: 70px;
        margin-right: 19px;
        margin-bottom: 12px;
    }

    h5.social-connection-username {
        font-size: 18px;
        margin-right: 9px;
    }

    .social-connection-post-content {
        padding: 8px 19px;
    }

    h4.social-connection-subheading {
        font-size: 19px;
    }

    h4.social-connection-post-content-heading {
        font-size: 19px;
        margin-bottom: 12px;
    }

    p.social-connection-post-content-text {
        font-size: 16px;
        margin-bottom: 12px;
    }

    .social-connection-post-header-right {
        width: 180px;
    }

    h5.social-connection-reply-count {
        font-size: 18px;
        margin-bottom: 12px;
    }

    h5.social-connection-date {
        font-size: 18px;
    }

    /* Social Connection */
    /* No posts container */
    .social-connection-no-posts-container {
        padding: 16px 0;
    }

    h4.social-connection-no-posts-text {
        font-size: 19px;
    }

    .topic-badge-container {
        margin-left: 6px;
    }

    /* Social Connection */
    /* Single Post Page */
    .social-connection-single-post-page-header {
        margin-bottom: 25px;
    }

    .social-connection-go-back a {
        line-height: 27px;
        max-width: 180px;
    }

    .social-connection-single-post {
        max-width: 1040px;
        margin: 0 0 0 25px;
        padding: 12px 9px;
    }

    .social-connection-single-post-user-image {
        width: 120px;
        height: 120px;
    }

    .social-connection-single-post-user-image-reply {
        width: 64px;
        height: 64px;
    }

    .social-connection-single-post-content {
        padding-left: 19px;
    }

    .social-connection-single-post-header {
        gap: 12px;
        margin-bottom: 6px;
    }

    h5.social-connection-single-post-username {
        font-size: 16px;
    }

    h5.social-connection-single-post-date {
        font-size: 14px;
        margin-right: 6px;
    }

    h4.social-connection-single-post-title {
        font-size: 19px;
        margin-bottom: 12px;
    }

    p.social-connection-single-post-text {
        font-size: 15px;
        line-height: 19px;
    }

    /* Social Connection */
    /* Post Toolbar */
    .social-connection-post-toolbar {
        gap: 3px;
        margin-bottom: 10px;
    }

    .social-connection-post-toolbar span {
        margin-right: 3px
    }

    .social-connection-post-toolbar svg {
        font-size: 15px;
    }

    .social-connection-post-toolbar .cbit-tooltip .cbit-tooltip-text {
        padding: 6px;
        font-size: 12px;
        left: 10px;
    }

    /* Social Connection */
    /*  Add Reply  */
    .btn-social-connection-add-reply,
    .btn-social-connection-add-reply-submit {
        max-width: 128px;
        line-height: 27px;
        padding: 6px 16px;
    }

    .social-connection-post-add-reply-container {
        max-width: 1080px;
        padding: 19px 9px;
    }

    .social-connection-post-add-reply-center {
        padding: 8px 19px;
    }

    .social-connection-post-add-reply-warning-text-container {
        margin-bottom: 3px;
    }

    p.social-connection-post-add-reply-warning-text {
        font-size: 13px;
        line-height: 19px;
    }

    .social-connection-post-add-reply-center textarea {
        height: 80px;
        padding: 6px;
        margin-bottom: 12px;
    }

    .social-connection-post-add-reply-textarea {
        font-size: 15px;
        line-height: 19px;
        padding: 12px;
    }

    /* Social Connection */
    /* Reply */
    .social-connection-single-post-reply-container {
        max-width: 977px;
        margin: 0 0 0 100px;
    }

    .social-connection-single-post-reply {
        max-width: 910px;
        margin: 0 0 10px 28px;
        padding: 12px 9px;
    }

    .social-connection-single-post-reply-user-image {
        width: 80px;
        height: 80px;
    }

    .social-connection-single-post-reply-body {
        padding-left: 19px;
    }


    .social-connection-single-post-reply-header {
        gap: 12px;
        margin-bottom: 6px;
    }

    h5.social-connection-single-post-reply-username {
        font-size: 16px;
        line-height: 27px;
    }

    p.social-connection-single-post-reply-text {
        font-size: 15px;
        line-height: 19px;
    }

    /* Social Connection */
    /* Deleted Reply */
    h5.social-connection-date.deleted span {
        margin-left: 8px;
    }

    /* Social Connection */
    /*  Add Post Page  */
    .social-connection-add-post-header {
        margin-bottom: 25px;
    }

    .social-connection-add-post-form-container {
        max-width: 800px;
        gap: 12px;
    }

    .social-connection-add-post-form-container form {
        gap: 12px;
        padding: 38px 19px;
    }

    .social-connection-add-post-form-input {
        padding: 12px;
        font-size: 15px;
        line-height: 19px;
    }

    .social-connection-add-post-form-input input {
        font-size: 12px;
        line-height: 19px;
        padding: 12px;
    }

    .social-connection-add-post-form-input textarea {
        font-size: 12px;
        line-height: 19px;
        padding: 12px;
    }

    .social-connection-add-post-disclaimer-text {
        margin: 0 12px;
    }

    p.disclaimer-text {
        font-size: 13px;
        line-height: 19px;
    }

    .btn-social-connection-add-post {
        line-height: 27px;
        padding: 6px 12px;
    }

    /* Continuing Education */
    /* Upcoming Webinars */
    h2.upcoming-webinars-header,
    h2.past-webinars-header,
    h2.my-registrations-header {
        font-size: 25px;
        margin-bottom: 19px;
    }

    .upcoming-webinars-container,
    .past-webinars-container {
        gap: 12px 12px;
        width: 100%;
    }

    .upcoming-webinars-no-webinars-container,
    .past-webinars-no-webinars-container,
    .my-registrations-no-webinars-container {
        width: 100%;
        height: 320px;
    }

    h3.upcoming-webinars-no-webinars-header,
    h3.past-webinars-no-webinars-header,
    h3.my-registrations-no-webinars-header {
        font-size: 19px;
    }

    .upcoming-webinar-item,
    .past-webinar-item {
        width: 280px;
        padding: 19px;
    }

    .upcoming-webinar-item-title-container,
    .past-webinar-item-title-container {
        min-height: 32px;
        max-height: 80px;
        margin-bottom: 12px;
    }

    h4.upcoming-webinar-item-title,
    h4.past-webinar-item-title {
        font-size: 19px;
    }

    h5.upcoming-webinar-item-info-title,
    .past-webinar-item-info-title {
        font-size: 15px;
    }

    .upcoming-webinar-item-description-container,
    .past-webinar-item-description-container {
        padding-bottom: 32px;
        min-height: 96px;
        max-height: 120px;
        margin-bottom: 12px;
    }

    p.upcoming-webinar-item-description,
    p.past-webinar-item-description {
        font-size: 15px;
        line-height: 22px;
        margin-bottom: 12px;
    }

    .upcoming-webinar-item-btn,
    .past-webinar-item-btn {
        padding: 8px 0 6px;
        font-size: 16px;
    }

    /* Load more */
    .upcoming-webinars-load-more-container,
    .past-webinars-load-more-container,
    .my-registrations-load-more-container {
        width: 100%;
        margin: 28px 0 0;
    }

    .upcoming-webinars-load-more-button,
    .past-webinars-load-more-button,
    .past-webinars-load-more-button {
        padding: 6px 16px 4px;
    }

    /* Upcoming webinar modal */
    .upcoming-webinars-modal {
        max-width: 512px;
    }

    .upcoming-webinars-modal-title-container {
        margin-bottom: 19px;
    }

    h4.upcoming-webinars-modal-title {
        font-size: 19px;
    }

    .upcoming-webinars-modal-btn-close svg {
        font-size: 22px;
    }

    .upcoming-webinars-modal-container {
        padding: 32px;
    }

    h2.upcoming-webinars-modal-body-title {
        font-size: 25px;
    }

    .upcoming-webinars-modal-body-info-container {
        gap: 6px 12px;
        padding-top: 32px;
    }

    .upcoming-webinars-modal-body-info-item {
        width: 208px;
    }

    h5.upcoming-webinars-modal-body-title {
        font-size: 17px;
    }

    p.upcoming-webinars-modal-body-text {
        font-size: 15px;
    }

    .upcoming-webinars-modal-description-container {
        padding-bottom: 64px;
    }

    .upcoming-webinars-modal-btn {
        width: 168px;
        padding: 8px 0 6px;
    }

    /* Past Webinars */
    /* past webinars modal */
    .past-webinars-modal {
        max-width: 832px;
        height: 616px;
    }

    .past-webinars-modal-video-container {
        height: 470px;
    }

    .past-webinars-modal-video {
        height: 470px;
    }

    .past-webinars-modal-close-button {
        width: 28px;
        height: 28px;
        top: 12px;
        right: 12px;
    }

    .past-webinars-modal-close-button svg {
        width: 28px;
        height: 28px;
        top: 1.5%;
        left: 1.5%;
    }

    .past-webinars-modal-text-container {
        padding: 19px 25px;
    }

    h3.past-webinars-modal-title {
        font-size: 19px;
    }

    h5.past-webinars-modal-subtext {
        font-size: 13px;
        margin-bottom: 13px;
    }

    p.past-webinars-modal-text {
        font-size: 13px;
    }

    /*  My Registrations  */
    .my-registrations-container {
        width: 100%;
    }

    .my-registrations-item {
        width: 870px;
        margin-bottom: 19px;
    }

    h4.my-registrations-item-title {
        font-size: 19px;
        margin-bottom: 12px;
    }

    h5.my-registrations-item-info-title {
        font-size: 15px;
        max-width: 560px;
    }

    .my-registrations-item-description-container {
        min-height: 40px;
        max-height: 80px;
        margin-bottom: 12px;
    }

    p.my-registrations-item-description {
        font-size: 15px;
        line-height: 22px;
        margin: 12px 0;
    }

    .my-registrations-item-btn-container {
        padding-bottom: 25px;
    }

    .my-registrations-item-btn {
        padding: 8px 16px 6px;
        font-size: 17px;
    }

    /* My Registrations - Modal */
    .my-registrations-modal {
        max-width: 576px;
    }

    h1.my-registrations-modal-title {
        font-size: 19px;
        margin-bottom: 19px;
    }

    .my-registrations-modal-container {
        padding: 25px;
    }

    p.my-registrations-modal-text {
        font-size: 17px;
    }

    .my-registrations-modal-btn-container {
        gap: 12px;
        padding-top: 24px;
    }

    .my-registrations-modal-btn {
        width: 168px;
        padding: 8px 0 6px;
        font-size: 16px;
    }

    /* Inbox */
    .inbox-header-container {
        margin-bottom: 19px;
    }

        h2.inbox-header-title {
            font-size: 25px;
        }

    .inbox-btn {
        padding: 9px 16px 8px;
    }

    .inbox-container {
        gap: 12px 12px;
    }

    .inbox-no-notifications-container {
        height: 320px;
    }

    .notification-item {
        padding: 19px 8px;
    }

    .notification-item-action-container {
        gap: 6px;
    }

    .notification-item-action-container svg {
        width: 19px;
        height: 19px;
    }

    h5.notification-item-title {
        font-size: 19px;
    }

    h5.notification-item-title.date {
        font-size: 15px;
    }

    .notification-item-title .modal-title {
        font-size: 19px;
    }

    p.notification-item-description,
    p.notification-item-description span {
        font-size: 15px;
    }

    .notification-item-btn {
        padding: 6px 16px 4px;
        font-size: 17px;
    }

    .inbox-load-more-container {
        margin: 28px 0 0;
    }

    .inbox-load-more-button {
        font-size: 15px;
        padding: 6px 16px 4px;
    }

    /* Inbox modal */
    .inbox-modal-body-container {
        padding: 8px 0 16px;
    }

    .inbox-modal-btn-container {
        gap: 12px;
    }

    /*  Consultation  */
    /* Book a Consultation */
    .consultation-header {
        font-size: 25px;
        margin-bottom: 25px;
    }

    .consultation-sub-header {
        margin-bottom: 22px;
        font-size: 19px;
    }

    .available-consultants-container {
        gap: 12px 12px;
    }

    .consultant-booking-item {
        max-width: unset;
        flex: unset;
        width: 475px;
        min-height: 213px;
        padding: 19px;
        column-gap: 19px;
    }

    .consultant-booking-item .empty-profile-image {
        width: 100px;
        height: 100px;
    }

    .consultant-profile-img-container {
        width: 100px;
    }

    .consultant-name {
        font-size: 25px;
        max-width: 560px;
        margin-bottom: 3px;
    }

    .consultant-booking-name {
        font-size: 19px;
        line-height: 25px;
        max-width: 348px;
        max-height: 25px;
    }

    .consultant-booking-title {
        margin: 3px 0;
        max-height: 19px;
    }

    .consultant-booking-bio {
        font-size: 15px;
        line-height: 26px;
        max-height: unset;
        min-height: 67px;
        margin: 9px 0 19px 0;
        max-width: 560px;
    }

    .consultant-action-container {
        margin: 9px 0;
    }

    .btn-consultation-action {
        line-height: 27px;
    }

    /* Book a Consultant - Weekly View */
    .book-consultations-container.consultant-book-availability {
        padding-top: 25px;
    }

    .btn-cbit-link.btn-return-consultant {
        font-size: 15px;
        line-height: 26px;
    }

    .consultant-availability-info {
        margin-top: 41px;
        gap: 32px;
    }

    .consultant-booking-bio-container {
        margin: 9px 0 9px 0;
    }

    .consultant-info-container-right .btn-cbit-link {
        margin-bottom: 6px;
        font-size: 15px;
    }

    .booking-availability-container {
        margin-top: 24px;
    }

    .booking-availability-controls {
        height: 25px;
    }

    .availability-date-range {
        font-size: 15px;
        line-height: 26px;
        padding: 0 12px;
    }

    .date-picker-btn-container,
    .btn-cbit-calendar-icon,
    .date-picker-btn-container input[type="date"] {
        width: 20px;
    }

    .date-picker-btn-container {
        margin-left: 12px;
    }

    .btn-consultation-calendar {
        width: 20px;
        margin-bottom: 3px;
    }

    .availability-date-header {
        height: 60px;
    }

    .day-of-the-week {
        font-size: 15px;
        line-height: 20px;
    }

    .availability-date {
        font-size: 17px;
        line-height: 22px;
    }

    .available-sessions-list {
        margin-top: 8px;
        gap: 9px;
    }

    .non-available-session-item {
        font-size: 15px;
        line-height: 26px;
    }

    /* Consultant Bio - Modal */
    h2.my-profile-description-name {
        font-size: 25px;
    }

    .my-profile-full-description {
        max-width: 720px;
        padding: 12px;
    }

    /* Availability Management */
    .availability-date-container {
        margin-bottom: 25px;
    }

    .calendar-date-range.availability-date-range {
        font-size: 19px;
        min-width: 182px;
    }

    .consultation-calendar-legend {
        gap: 12px;
        margin-bottom: 25px;
        font-size: 15px;
    }

    .consultation-legend-icon {
        height: 8px;
        width: 8px;
        margin-right: 8px;
    }

    .btn-cbit-icon.btn-availability-control {
        width: 24px;
        height: 25px;
    }

    .toastui-calendar-month .toastui-calendar-month-daygrid {
        font-size: 14px !important;
    }

    .toastui-calendar-grid-cell-date .toastui-calendar-weekday-grid-date.toastui-calendar-weekday-grid-date-decorator {
        height: 24px;
        line-height: 24px;
        width: 24px;
    }

    /* Availability Management - Modal */
    .consultation-plan-form {
        width: 672px;
    }

    .form, .cbit-dialog {
        padding: 32px 25px 32px;
    }

    .cbit-dialog {
        padding-bottom: 25px;
    }

    .custom-modal .btn-cbit-icon {
        top: 12px;
        right: 12px;
        width: 12px;
    }

    h2.form-title,
    .cbit-header {
        font-size: 19px;
        margin: 0 0 19px;
    }

    .cbit-column {
        margin: 0 6px;
    }

    .form-label,
    .cbit-label {
        font-size: 17px;
    }

    .consultation-plan-form .form-input {
        height: 38px;
        font-size: 15px;
    }

    .consultation-plan-form .form-input,
    .consultation-plan-textarea-container {
        margin-bottom: 19px;
    }

    .text-character-limit-container {
        font-size: 17px;
    }

    .session-time-to-column.cbit-column {
        width: 20px;
    }

    .session-time-to-text {
        font-size: 17px;
        height: 40px;
    }

    .recurring-plan-container {
        gap: 12px;
    }

    .recurring-repeat-container {
        margin-bottom: 19px;
    }

    .recurring-note {
        font-size: 15px;
    }

    .upload-text {
        font-size: 15px;
        padding-bottom: 8px;
    }

    .plan-button-group {
        padding-top: 9px;
    }

    .consultation-plan-form .btn-cbit-primary,
    .consultation-plan-form .btn-cbit-minor {
        height: 40px;
        min-width: 115px;
    }

    .plan-button-group .btn-cbit-primary {
        margin-left: 12px;
        width: 136px;
    }

    /* Consultation Info Modal */
    .consultation-plan-form.consultation-info {
        width: 432px;
        padding: 32px 25px;
    }

    .consultation-info .view-details-text-container {
        margin-bottom: 19px;
    }

    .btn-cbit-text,
    .btn-cbit-link {
        font-size: 15px;
    }

    .consultation-info {
        font-size: 15px;
    }

    /* Consultation Info - Delete Modal */
    .custom-alert {
        padding: 32px 25px 32px;
        width: 576px;
    }

    .custom-alert-header {
        font-size: 19px;
        line-height: 33px;
        padding-bottom: 19px;
    }

    .custom-alert-text {
        font-size: 17px;
    }

    .btns-custom-alert-container {
        padding-top: 19px;
    }

    .custom-alert .btn-cbit-minor {
        min-width: 107px;
        height: 40px;
        margin-right: 12px;
    }

    .custom-alert .btn-cbit-primary {
        min-width: 160px;
        height: 40px;
    }

    /* My Upcoming Sessions */
    .consultation-filters {
        max-height: 38px;
        margin-bottom: 32px;
        gap: 12px;
    }

    .form-input {
        padding: 6px 12px;
        font-size: 15px;
    }

    .upcoming-consultations {
        font-size: 15px;
    }

    .session-item {
        min-height: 266px;
        gap: 25px;
        margin-bottom: 26px;
    }

    .consultant-info-container-left.session-profile-icon {
        max-width: 100px;
    }

    .consultation-action-container {
        gap: 12px;
        margin-bottom: 22px;
    }

    .btn-consultation-edit {
        width: 153px;
        height: 36px;
    }

    .btn-consultation-delete {
        height: 36px;
        width: 134px;
    }

    /* My Previous Sessions */
    #previous-sessions.consultation-filters {
        max-height: 38px;
        gap: 12px;
        margin-bottom: 70px;
    }

    .consultation-filters-container {
        max-width: 720px;
        gap: 12px;
    }

    .consultation-filter-select {
        max-width: 272px;
    }

    .empty-session-toggle {
        gap: 12px;
    }

    .empty-session-toggle span {
        font-size: 14px;
        max-width: 180px;
    }

    .toggle-switch {
        width: 48px;
    }

    .btn-consultation-minor.btn-cbit-minor {
        font-size: 16px;
        line-height: 27px;
        height: 35px;
        width: 200px;
    }

    /* My Previous Sessions - Review Modal */
    .consultation-review-form {
        width: 560px;
    }
    .consultation-review-form p {
        font-size: 17px;
    }

    .review-btn-container {
        margin-bottom: 12px;
    }

    .review-btn-container span {
        margin-right: 8px;
    }

    .rating-label {
        font-size: 2rem;
    }

    .rating-container {
        margin-bottom: 11px;
    }

    .review-user-profile {
        max-width: 560px;
        max-height: 480px;
    }

    .form-btn-container .btn-cbit-primary {
        width: 115px;
        font-size: 16px;
    }

    /* My Profile */
    .consultant-image-container {
        gap: 19px;
        margin-bottom: 25px;
    }

    .consultant-my-profile-image-container,
    .consultant-image-container .empty-profile-image {
        width: 128px;
        height: 128px;
    }

    .empty-profile-image {
        font-size: 13px;
    }

    .consultant-image-container .btn-cbit-minor {
        width: 153px;
        height: 40px;
    }

    .consultant-profile-container {
        max-width: 511px;
    }

    .consultant-profile-container p {
        height: 38px;
        font-size: 15px;
    }

    .consultant-profile-container .form-input {
        height: 38px;
    }

    .consultant-profile-container #bio{
        height: 123px;
    }

    .consultant-profile-container .cbit-btn-container {
        margin-top: 32px;
    }

    .consultant-profile-container .cbit-btn-container button {
        width: 115px;
        height: 35px;
    }

    .btn-remove-image {
        height: 35px;
    }

    .consultant-upload-container,
    .consult-profile-name-container.cbit-row {
        gap: 12px;
    }

    .consultant-bio-description.form-input {
        margin-bottom: 6px;
    }

    /* Family Support */
    .category-title-container {
        gap: 24px;
    }

    h2.category-title {
        font-size: 25px;
        margin-bottom: 25px;
    }

    .SupportContent-title {
        margin-bottom: 8px;
        font-size: 17px;
        letter-spacing: 0.7px;
    }

    /* Family Support - Admin */
    .chapter-content-container {
        min-height: 19px;
        padding: 8px 0 8px 19px;
    }

    .drag-handle,
    .drag-handle svg {
        height: 28px;
        width: 10px;
    }

    .inline-basic-input {
        max-width: 184px !important;
    }

    .therapist-panel-btn-container button {
        width: 28px;
        margin-right: 12px;
    }

    .standard-content-btn-container {
        min-height: 51px;
    }

    .nav-sidebar .standard-content-btn-container {
        padding: 8px 19px 8px 19px;
        bottom: 6px;
    }

    .admin-content-menu-item,
    .admin-content-menu-item-loading {
        width: 262px;
        height: 57px;
    }

    .plus-icon {
        margin: 9px 12px;
        width: 38px;
        height: 38px;
    }

    .plus-icon svg {
        width: 38px;
        height: 38px;
    }

    .admin-content-menu-item-subheading {
        font-size: 17px;
        margin: 8px 0;
        height: 19px;
    }

    .btn-tag-add {
        margin-top: 9px;
        height: 19px;
    }

    .tag-add-text {
        font-size: 17px;
        line-height: 1.0em;
    }

    .admin-chapter-text {
        font-size: 17px;
        letter-spacing: 0.8px;
    }

    .admin-content-body {
        margin-left: 16px;
    }

    .admin-content-container {
        margin: 16px 0;
    }

    .admin-support-content {
        font-size: 15px;
    }

    /* Family Support - Admin: Modal - Edit */
    .additional-content-form {
        width: 520px;
        padding: 24px !important;
    }

    .additional-content-form .btn-cbit-primary {
        width: 115px;
        height: 35px;
    }

    /* Admin Tools */
    .admin-tools {
        padding: 16px;
    }

    .admin-tools-inner {
        margin-top: 12px;
        gap: 16px;
    }

    .admin-tools-item {
        width: 364px;
    }

    .admin-tools-item,
    .admin-tools-item a {
        min-height: 120px;
    }

    .admin-tools-item a {
        padding: 16px;
    }

    .admin-tool-header {
        font-size: 18px;
    }

    .admin-tool-body {
        font-size: 15px;
    }

    /* Therapist Support */
    .tag-item {
        padding-bottom: 27px;
        margin-bottom: 32px;
    }

    h4.tag-title {
        margin-bottom: 13px;
        font-size: 19px;
    }

    .tag-content-container {
        gap: 12px 12px;
    }

    .therapist-content-item {
        height: 104px;
        min-height: 52px;
        max-height: 104px;
        min-width: 365px;
        max-width: 365px;
        padding: 5px 6px 4px 6px;
    }

    .therapist-content-item-info {
        margin-right: 12px;
        width: 32px;
    }

    .therapist-icon-container {
        width: 28px;
    }

    .content-item-duration {
        margin-top: 5px;
        font-size: 15px;
    }

    .therapist-content-title {
        font-size: 16px;
        line-height: 19px;
    }

    .tag-item .plus-icon {
        height: 19px;
        width: 19px;
    }

    .therapist-support-modal {
        width: 512px;
    }

    .therapist-player,
    .react-player {
        width: 512px !important;
        height: 288px !important;
    }

    .react-player-title {
        font-size: 19px;
    }

    .react-player-title-container {
        padding: 16px 21px;
    }

    .therapist-player-title-container {
        padding: 16px 21px;
    }

    .therapist-player-title {
        font-size: 19px;
    }

    .therapist-content-description {
        max-width: 365px;
    }

    h2, .h2 {
        font-size: 25px;
    }

    /* Therapist Support - Admin */
    .admin.therapist-content-item .therapist-content-title {
        width: 230px;
        max-width: 230px;
    }

    .therapist-content-item .drag-handle {
         margin-right: 12px;
    }

    .therapist-content-item .therapist-panel-btn-container {
        gap: 12px;
        margin-left: 19px;
    }

    .additional-content-form .form {
         padding: 0;
    }

    /* Referral */
    /* Referral Modal */
    .referral-alert {
        height: auto;
        width: 348px;
    }

    .cbit-dashboard-header {
        font-size: 25px;
        margin-bottom: 25px;
    }

    .referral-alert p {
        font-size: 15px;
        margin-bottom: 32px;
    }

    .cbit-dialog-btn-container,
    .cbit-btn-container {
        gap: 12px;
    }

    .cbit-dialog-btn-container .btn-cbit-primary,
    .cbit-dialog-btn-container .btn-cbit-minor {
        height: 40px;
        font-size: 17px;
        line-height: 28px;
        letter-spacing: 1.6px;
    }

    /* Referral Profile */
    .referral-profile-body {
        gap: 38px;
    }

    .referral-image-container,
    .referral-image-container .btn-cbit-minor,
    .referral-details .referral-image-container {
        width: 153px;
    }

    .referral-image-container > div:first-child {
        margin-bottom: 12px;
    }

    .referral-image-container .upload-btn-container {
        margin-bottom: 9px;
    }

    .upload-btn-container {
        width: 142px;
        height: 40px;
        letter-spacing: 1.6px;
    }

    .referral-image {
        height: 153px;
    }

    .empty-image-container,
    .referral-profile .empty-image-container {
        width: 153px;
        height: 153px;
    }

    .referral-form {
        width: 480px;
    }

    .referral-container {
        gap: 38px;
    }

    .cbit-form-group {
        margin-bottom: 12px;
    }

    .cbit-column.referral-address-field {
        margin-bottom: 12px;
    }

    .user-form-checkbox {
        margin-bottom: 12px;
    }

    .user-form-checkbox-container .checkbox-label-cbit {
        font-size: 17px;
    }

    .checkbox-label-cbit input {
        margin-right: 9px;
    }

    .form-btn-container {
        gap: 12px;
    }

    /* Referral */
    /* View Referral List */
    .referral-users-container {
        min-width: 331px;
        padding: 0 19px 19px 0;
    }

    .referral-users-container .cbit-dashboard-header {
        font-size: 20px;
    }

    .referral-users-container .cbit-row {
        gap: 12px;
    }

    .filter-age-group .user-form-checkbox {
        margin-right: 12px;
    }

    .checkbox-label-cbit {
        font-size: 15px;
    }

    .checkbox-cbit {
        height: 17px;
        width: 17px;
    }

    .referral-address-legend {
        gap: 16px;
        margin-bottom: 12px;
        font-size: 15px;
    }

    .referral-user-marker-container {
        width: 12px;
    }

    .referral-map-popup {
        max-width: 162px;
        max-height: 88px;
        font-size: 15px;
    }

    .referral-map-popup .cbit-small-header {
        font-size: 15px;
    }

    .cbit-divider {
        margin: 7px 0;
    }

    .referral-user-item {
        gap: 12px;
        margin-bottom: 32px;
    }

    .referral-user-item-profile .referral-image-container {
        width: 44px;
    }

    .referral-user-item-profile .empty-image-container {
        width: 44px;
        height: 44px;
    }

    .referral-user-item-profile .referral-image-container .referral-image {
        height: 44px;
    }

    .referral-user-item-profile .referral-image-container .empty-profile-image {
        font-size: 8px;
    }

    .referral-user-item-name.btn-cbit-text {
        font-size: 17px;
    }

    .referral-user-item-occupation {
        font-size: 15px;
    }

    .referral-user-item-bio {
        font-size: 15px;
    }

    .referral-user-item-btn-container {
        margin-top: 12px;
    }

    .referral-user-item-btn-container .btn-cbit-minor {
        height: 30px;
        width: 135px;
        font-size: 15px;
    }

    /* Referral */
    /* View Referral List - Modal */
    .referral-details .referral-image-container,
    .referral-details .referral-image-container .empty-image-container {
        width: 153px;
    }

    .referral-details .referral-image-container .empty-image-container {
         height: 153px;
    }

    .referral-info-container {
        gap: 12px;
        width: 448px;
    }

    .cbit-small-header {
        font-size: 19px;
    }

    .referral-details .referral-info-container {
        gap: 12px;
    }

    .referral-bio-container p {
        font-size: 15px;
    }

    #referralUserMap {
        height: 153px;
    }

    .my-profile-full-description {
        max-width: 720px;
        padding: 12px;
        font-size: 15px;
    }

    /* Training */
    /* Layout */
    .isOpen .MainContent {
        width: calc(100vw - 289px);
    }

    .Menu-Right-Bar {
        width: 289px;
        max-width: 289px;
    }

    .AccordionContent {
        width: 320px;
    }

    .Viewer-Content {
        left: 320px;
        width:calc(100vw - 320px);
    }

    .isOpen .Viewer-Content {
        left: 320px;
        width: calc(100vw - 571px);
    }

    .menuTab,
    .accordMenuTab {
        top: 32px;
        height: 76px;
        width: 38px;
    }

    .isOpen .menuTab {
        right: -56px;
    }

    /* Training */
    /* NavSidebar */
    .faded-navigation-section-label {
        padding-top: 14px;
        padding-left: 19px;
        font-size: 15px;
        letter-spacing: 0.6px;
    }

    .navigation-section-label {
        padding-left: 19px;
        padding-top: 55px;
        font-size: 32px;
    }

    .accordion-body {
        height: calc(100vh - 184px);
    }

    .accordion-item-header-chapter {
        height: 48px;
    }

    .accordion-item-header-chapter-lesson {
        height: 48px;
    }

    .accordion-item-arrow {
        width: 16px;
        height: 48px;
    }

    .orange-selected {
        width: 6px;
        height: 48px;
        left: -20px;
        margin-right: -6px;
    }

    .admin-chapter-item {
        min-height: 50px;
    }

    .AdditionalVideoContainer .chapter-lesson-amount {
        font-size: 13px;
        letter-spacing: 1.2px;
        margin: 0 0 0 9px;
        width: 40px;
    }

    .chapter-lesson-amount {
        font-size: 13px;
        letter-spacing: 1.2px;
        margin: 0 0 0 9px;
        width: 40px;
    }

    .admin-icon-container {
        margin: 0 10px 0 10px;
    }

    .admin-caret-left img {
        height: 9px;
        width: 7px;
    }

    .admin-caret-down img {
        height: 7px;
        width: 9px;
    }

    .admin-lesson-item {
        padding-left: 19px;
        min-height: 44px;
    }

    .lesson-type-icon {
        width: 17px;
        margin-right: 12px;
    }

    .lesson-type-icon img {
        height: 17px;
        width: 17px;
    }

    .admin-lesson-text {
        width: 194px;
    }

    .admin-lesson-duration {
        font-size: 11px;
        margin-left: 9px;
    }

    .lesson-duration-buffer {
        min-width: 24px;
    }

    .admin-lesson-group-item {
        padding-left: 19px;
    }

    .sub-lesson-item {
        padding-left: 27px;
    }

    .admin-lesson-group-items .admin-lesson-content {
        padding-left: 18px;
        min-height: 28px;
    }

    .text-margin-15 {
        margin-left: 12px;
    }

    .admin-lesson-group-items .admin-lesson-text {
        width: 171px;
    }

    .admin-lesson-text {
        font-size: 15px;
    }

    .text-margin-15-important {
        margin-left: 12px !important;
    }

    /* Training */
    /* Viewer Content */
    .video-player-background {
        height: calc(24vw);
        margin-bottom: 25px;
    }

    .video-content-panel-header {
        margin: 16px 25px 12px;
        gap: 4px;
    }

    .video-text-title {
        max-width: 696px;
        font-size: 22px;
        line-height: 32px;
    }

    .Left-Buffer-24 {
        padding-left: 19px;
    }

    .btn-cbit,
    .btn-disabled {
        min-height: 30px;
        padding: 8px 10px;
        font-size: 16px;
        letter-spacing: 1px;
    }

    .btn-primary,
    .btn-secondary {
        font-size: 16px;
        letter-spacing: 1px;
        padding: 8px 10px;
    }

    .video-text-offset {
        margin: 12px 25px 0;
    }

    .video-text-body {
        font-size: 15px;
        width: 640px;
    }

    /* Training */
    /* Additional Content */
    .addVideoName {
        margin-left: 25px;
        margin-top: 25px;
        font-size: 15px;
        letter-spacing: 2.2px;
        max-width: 192px;
    }

    .add-video-separator {
        width: 224px;
        height: 3px;
        margin-left: 25px;
        margin-bottom: 13px;
    }

    .addVideo-box {
        margin-left: 25px;
        width: 224px;
    }

    .addVideo-text {
        margin-top: 5px;
        margin-left: 25px;
        margin-bottom: 19px;
    }

    .addVideo-titleText {
        font-size: 17px;
    }

    .addVideo-lengthText {
        font-size: 13px;
    }

    .no-content-additional-video-container {
        margin-left: 25px;
        width: 224px;
    }

    .no-content-image-container {
        width: 96px;
    }

    .no-content-text {
        font-size: 15px;
    }

    /* Training */
    /* Quiz, Combo, Text & Video Lessons */
    .quiz-content-panel,
    .combo-lesson-quiz-content-panel {
        margin-top: 52px;
        margin-left: 64px;
        margin-right: 64px;
    }

    .quiz-title,
    .combo-lesson-quiz-title {
        font-size: 25px;
        margin-bottom: 25px;
    }

    .quiz-instruction-text {
        font-size: 15px;
        margin-bottom: 25px;
    }

    p {
        font-size: 13px;
    }

    .quiz-question-text {
        font-size: 15px;
        margin-bottom: 25px;
    }

    .quiz-question-title-seperation {
        margin-bottom: 12px;
    }

    .quiz-question-left-margin {
        margin-left: 12px;
    }

    .quiz-question-seperation {
        margin-left: 25px;
        margin-bottom: 12px;
    }

    .radioButtonAnswerAdjustment {
        margin-left: -28px;
    }

    .radioDesignationAddjustment {
        margin-right: 12px;
    }

    .radioTextAdjustment {
        margin-left: 12px;
    }

    .pdf-toolbox-box .addVideo-box {
        width: 224px;
        height: 120px;
    }

    .textLessonImage {
        margin-bottom: 25px;
    }

    /* My Profile */
    .my-profile-container {
        max-width: 640px;
        min-height: 619px;
    }

    .my-profile-container .form {
        min-height: 619px;
    }

    .my-profile-container .form-textarea {
        min-height: 92px;
    }

    .my-profile-container .upload-btn-container {
        width: auto;
        height: 40px;
        padding: 0 10px;
        letter-spacing: 1.6px;
    }

    .my-profile-upload-container {
        height: 100px;
        margin-bottom: 12px;
    }

    .my-profile-image-container {
        height: 100px;
        width: 100px;
    }

    .profile-image-container {
        height: 100px;
        width: 100px;
    }

    .empty-profile-image {
        font-size: 13px;
    }

    .profile-image-upload-container {
        margin-left: 12px;
        width: 440px;
    }

    .profile-image-upload-container label {
        margin-right: 12px;
    }

    .profile-image-text {
        font-size: 17px;
    }

    .my-profile-container .btn-cbit-icon {
        height: 20px;
        width: 20px;
    }

    .my-profile-container .cbit-row {
        margin: 11px 0;
        min-height: 64px;
        max-height: 64px;
    }

    .my-profile-container .cbit-col:first-child {
        padding-right: 24px;
    }

    .my-profile-input,
    .my-profile-text {
        font-size: 17px;
    }

    .cbit-row.isEditing {
        margin-bottom: 24px;
    }

    .my-profile-text {
        padding: 5px 0;
    }

    .my-profile-container .my-profile-description-container {
        height: 124px;
        min-height: 124px;
    }

    .my-profile-description {
        height: calc(100% - 32px);
    }

    .submit-btn-container {
        margin: 19px 0 0 !important;
    }

    .submit-btn-container .btn-cbit-primary {
        height: 40px;
        width: 115px;
        margin-top: 10px;
    }

    /* Password Reset */
    .password-reset-form .btn-cbit-primary {
        height: 40px;
        width: 115px;
    }

    .login-form .btn-cbit-primary, .forgot-form .btn-cbit-primary {
        width: 115px;
        height: 40px;
    }

    /* Sign Up */
    .user-form .btn-cbit-primary {
        width: 115px;
        height: 40px;
        margin-top: 12px;
    }

    /* Content Builder */
    /* Nav Sidebar */
    .admin-nav-header {
        padding: 47px 19px 19px 19px;
    }

    .admin-navigation-panel .admin-nav-header-container {
        height: auto;
    }

    .admin-nav-title-container {
        min-height: 44px;
    }

    .admin-nav-text-container {
        margin-top: 12px;
        min-height: 36px;
    }

    .admin-nav-text {
        font-size: 15px;
    }

    /* Content Builder */
    /* View Panel */
    .admin-view-inner-panel {
        padding: 11px 44px 0 44px;
    }

    .admin-breadcrumb,
    .admin-breadcrumb:hover,
    .admin-bread-crumbs-arrow,
    .admin-active-breadcrumb {
        line-height: 27px;
        font-size: 13px;
        letter-spacing: 0.5px;
    }

    .admin-view-header {
        margin-bottom: 19px;
        padding-top: 4px;
    }

    .content-type-label {
        margin: 0 0 9px 12px;
    }

    .admin-view-tools-container {
        height: 35px;
        margin-bottom: 12px;
    }

    .admin-view-tools-right {
        gap: 25px;
        font-size: 12px;
    }

    .empty-editor-btns-container,
    .editor-btns-container {
        width: 256px;
        height: 35px;
    }

    .btn-save-content {
        width: 115px;
        height: 40px;
    }

    /* Content Builder */
    /* Content List */
    .content-list {
        gap: 7px;
    }

    .content-item {
        padding: 24px 19px;
    }

    .content-item-type {
        margin: 0 19px;
        height: 22px;
        width: 22px;
    }

    .lesson-content-caret-down,
    .lesson-content-caret-left {
        width: 9px;
    }

    .lesson-content-caret-left img {
        width: 7px;
    }

    .content-item-options li {
        margin-right: 19px;
    }

    .content-action-icon-visibility {
        width: 24px;
        height: 19px;
    }

    .content-action-icon {
        width: 19px;
    }

    .lesson-content-duration {
        font-size: 15px;
        width: 32px;
        height: 17px;
    }

    .admin-view-item {
        margin-top: 20px;
        padding: 0 120px 0 50px;
    }

    /* Content Builder */
    /* Content Menu Panel */
    .component-list {
        gap: 9px;
        padding: 44px 28px;
    }

    .content-label {
        font-size: 17px;
        letter-spacing: 0.50px;
        margin: 9px 0 0;
    }

    .admin-content-menu-panel .admin-content-menu-item-custom {
        height: auto;
    }

    .content-menu-custom-container,
    .admin-content-menu-item-subheading {
        margin: 0 0 12px;
        height: auto;
    }

    .content-menu-custom-container,
    .admin-content-menu-item-subheading {
        display: flex;
        margin: 0 0 12px;
        height: auto;
        padding: 0 10px 0 0;
    }

    .admin-content-menu-item-text {
        font-size: 15px;
         height: auto;
    }

    .user-navigation-items  {
        min-width: 210px;
    }

}

@media (max-width: 1024px) {
    /* Navbar */
    .logo-container {
        height: 60px;
    }

    .logo-container img {
        margin-top: 6px;
    }

    .welcome-text-container p {
        font-size: 16px;
    }

    .nav-item-text {
        font-size: 16px;
    }

    /* User Profile Menu */
    .user-profile-menu-icon svg {
        width: 43px;
        height: 43px;
    }

    div .menu-item-container {
        top: 75px;
        font-size: 14px;
        width: 180px;
    }

    .menu-item {
        height: 37px;
    }

    .menu-item-link {
        line-height: 37px;
    }

    /* Social Connection */
    /* Nav Sidebar */
    .nav-sidebar {
        width: 300px;
    }

    .isOpen .nav-sidebar {
        width: 300px;
    }

    /* Social Connection */
    /* Nav Sidebar - Content */
    .nav-sidebar .title-container {
        min-height: 150px;
        padding: 12px 12px 12px 18px;
    }

    .nav-sidebar .back-to-dashboard {
        line-height: 25px;
        font-size: 14px;
        letter-spacing: 0.6px;
    }

    h2.nav-sidebar-title {
        font-size: 30px;
    }

    .nav-sidebar .sidebar-link {
        min-height: 48px;
        padding: 7px 0 7px 18px;
    }

    .nav-sidebar .sidebar-link-text {
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.7px;
    }

    /* Social Connection */
    /* Main content container */
    .social-connection-main-content,
    .continuing-education-main-content,
    .inbox-main-content,
    .therapist-support-main-content,
    .referral-main-content {
        padding: 36px 42px;
    }

    .consultation-main-content {
        padding: 36px 90px;
    }

    .isOpen .social-connection-main-content,
    .isOpen .continuing-education-main-content,
    .isOpen .inbox-main-content,
    .isOpen .consultation-main-content,
    .isOpen .therapist-support-main-content,
    .isOpen .referral-main-content {
        left: 300px;
        width: calc(100vw - 300px);
    }

    /* Social Connection */
    /* Header */
    h2.social-connection-heading {
        font-size: 24px;
    }

    .btn-cbit-minor,
    .btn-cbit-primary {
        letter-spacing: 1.35px;
        font-size: 15px;
    }

    .btn-social-connection {
        line-height: 25px;
        height: 33px;
        max-width: 170px;
    }

    .social-connection-header {
        margin-bottom: 24px;
    }

    /* Social Connection */
    /* Search */
    .social-connection-search-container {
        margin-bottom: 24px;
    }

    .social-connection-search-left,
    .social-connection-search-right {
        max-width: 170px;
    }

    .social-connection-search-left select {
        height: 33px;
    }

    .social-connection-search-center input {
        height: 33px;
    }

    .btn-social-connection-search {
        height: 33px;
    }

    .social-connection-subheader {
        margin-bottom: 21px;
    }

    /* Social Connection */
    /* Posts container */
    .social-connection-post-header,
    .social-connection-post-header-editing {
        padding-left: 103px;
        margin-bottom: 6px;
    }

    .social-connection-post-header-left,
    .social-connection-post-reply-left,
    .social-connection-post-reply-right {
        width: 150px;
    }

    .social-connection-profile-image {
        width: 60px;
        height: 60px;
        margin-right: 8px;
        margin-bottom: 12px;
    }

    h5.social-connection-username {
        font-size: 17px;
        margin-right: 9px;
    }

    .social-connection-post-content {
        padding: 7px 18px;
    }

    h4.social-connection-subheading {
        font-size: 18px;
    }

    h4.social-connection-post-content-heading {
        font-size: 18px;
        margin-bottom: 12px;
    }

    p.social-connection-post-content-text {
        font-size: 16px;
        margin-bottom: 12px;
    }

    .social-connection-post-header-right {
        width: 150px;
    }

    h5.social-connection-reply-count {
        font-size: 17px;
        margin-bottom: 12px;
    }

    h5.social-connection-date {
        font-size: 17px;
    }

    /* Social Connection */
    /* No posts container */
    .social-connection-no-posts-container {
        padding: 15px 0;
    }

    h4.social-connection-no-posts-text {
        font-size: 18px;
    }

    /* Social Connection */
    /* Single Post Page */
    .social-connection-single-post-page-header {
        margin-bottom: 24px;
    }

    .social-connection-go-back a {
        font-size: 14px;
        line-height: 27px;
        max-width: 169px;
    }

    .social-connection-single-post {
        max-width: 100%;
        margin: 0 0 0 18px;
        padding: 0 9px 0 0;
    }

    .social-connection-single-post-user-image {
        width: 112px;
        height: 112px;
    }

    .social-connection-single-post-user-image-reply {
        width: 60px;
        height: 60px;
    }

    .social-connection-single-post-content {
        padding-left: 18px;
    }

    h5.social-connection-single-post-username {
        font-size: 15px;
    }

    h5.social-connection-single-post-date {
        font-size: 13px;
    }

    h4.social-connection-single-post-title {
        font-size: 18px;
    }

    p.social-connection-single-post-text {
        font-size: 14px;
        line-height: 18px;
    }

    /* Social Connection */
    /* Post Toolbar */
    .social-connection-post-toolbar {
        gap: 2px;
        margin-bottom: 8px;
    }

    .social-connection-post-toolbar span {
        margin-right: 2px
    }

    .social-connection-post-toolbar svg {
        font-size: 14px;
    }

    /* Social Connection */
    /*  Add Reply  */
    .btn-social-connection-add-reply,
    .btn-social-connection-add-reply-submit {
        max-width: 120px;
        line-height: 25px;
        padding: 6px 15px;
    }

    .social-connection-post-add-reply-container {
        max-width: 1012px;
        padding: 18px 9px;
    }

    .social-connection-post-add-reply-center {
        padding: 7px 18px;
    }

    .social-connection-post-add-reply-warning-text-container {
        margin-bottom: 2px;
    }

    p.social-connection-post-add-reply-warning-text {
        font-size: 12px;
        line-height: 18px;
    }

    .social-connection-post-add-reply-center textarea {
        height: 75px;
        padding: 6px;
        margin-bottom: 12px;
    }

    .social-connection-post-add-reply-textarea {
        font-size: 14px;
        line-height: 18px;
        padding: 12px;
    }

    /* Social Connection */
    /* Reply */
    .social-connection-single-post-reply-container {
        max-width: 916px;
        margin: 0 0 0 80px;
    }

    .social-connection-single-post-reply {
        max-width: 900px;
        margin: 0 0 0 27px;
        padding: 12px 9px;
    }

    .social-connection-single-post-reply-user-image {
        width: 75px;
        height: 75px;
    }

    .social-connection-single-post-reply-body {
        padding-left: 18px;
    }

    h5.social-connection-single-post-reply-username {
        font-size: 15px;
        line-height: 25px;
    }

    p.social-connection-single-post-reply-text {
        font-size: 14px;
        line-height: 18px;
    }

    /* Social Connection */
    /* Deleted Reply */
    h5.social-connection-date.deleted span {
        margin-left: 7px;
    }

    /* Social Connection */
    /*  Add Post Page  */
    .social-connection-add-post-header {
        margin-bottom: 24px;
    }

    .social-connection-add-post-form-container {
        max-width: 750px;
    }

    .social-connection-add-post-form-container form {
        padding: 36px 18px;
    }

    .social-connection-add-post-form-input {
        font-size: 14px;
        line-height: 18px;
    }

    .social-connection-add-post-form-input input {
        line-height: 18px;
    }

    .social-connection-add-post-form-input textarea {
        line-height: 18px;
    }

    p.disclaimer-text {
        font-size: 12px;
        line-height: 18px;
    }

    .btn-social-connection-add-post {
        line-height: 25px;
    }

    /* Continuing Education */
    /* Upcoming Webinars */
    h2.upcoming-webinars-header,
    h2.past-webinars-header,
    h2.my-registrations-header {
        font-size: 24px;
        margin-bottom: 18px;
    }

    .upcoming-webinars-container,
    .past-webinars-container {
        gap: 12px 12px;
    }

    .upcoming-webinars-no-webinars-container,
    .past-webinars-no-webinars-container,
    .my-registrations-no-webinars-container {
        height: 300px;
    }

    h3.upcoming-webinars-no-webinars-header,
    h3.past-webinars-no-webinars-header,
    h3.my-registrations-no-webinars-header {
        font-size: 18px;
    }

    .upcoming-webinar-item,
    .past-webinar-item {
        width: 262px;
        padding: 18px;
    }

    .upcoming-webinar-item-title-container,
    .past-webinar-item-title-container {
        min-height: 30px;
        max-height: 75px;
        margin-bottom: 12px;
    }

    h4.upcoming-webinar-item-title,
    h4.past-webinar-item-title {
        font-size: 18px;
    }

    h5.upcoming-webinar-item-info-title,
    h5.past-webinar-item-info-title {
        font-size: 14px;
    }

    .upcoming-webinar-item-description-container,
    .past-webinar-item-description-container {
        padding-bottom: 30px;
        min-height: 90px;
        max-height: 112px;
        margin-bottom: 12px;
    }

    p.upcoming-webinar-item-description,
    p.past-webinar-item-description {
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 12px;
    }

    .upcoming-webinar-item-btn,
    .past-webinar-item-btn {
        padding: 7px 0 6px;
        font-size: 15px;
    }

    .upcoming-webinars-load-more-container,
    .past-webinars-load-more-container,
    .my-registrations-load-more-container {
        margin: 27px 0 0;
    }

    .upcoming-webinars-load-more-button,
    .past-webinars-load-more-button,
    .my-registrations-load-more-button {
        padding: 6px 15px 4px;
    }

    /* Upcoming Webinars */
    .upcoming-webinars-modal {
        max-width: 480px;
    }

    .upcoming-webinars-modal-title-container {
        margin-bottom: 18px;
    }

    h4.upcoming-webinars-modal-title {
        font-size: 18px;
    }

    .upcoming-webinars-modal-btn-close svg {
        font-size: 21px;
    }

    .upcoming-webinars-modal-container {
        padding: 30px;
    }

    h2.upcoming-webinars-modal-body-title {
        font-size: 24px;
    }

    .upcoming-webinars-modal-body-info-container {
        gap: 6px 12px;
        padding-top: 30px;
    }

    .upcoming-webinars-modal-body-info-item {
        width: 195px;
    }

    h5.upcoming-webinars-modal-body-title {
        font-size: 16px;
    }

    p.upcoming-webinars-modal-body-text {
        font-size: 14px;
    }

    .upcoming-webinars-modal-description-container {
        padding-bottom: 60px;
    }

    .upcoming-webinars-modal-btn {
        width: 157px;
        padding: 7px 0 6px;
    }

    /* Past Webinars */
    /* past webinars modal */
    .past-webinars-modal {
        max-width: 780px;
        height: 577px;
    }

    .past-webinars-modal-video-container {
        height: 441px;
    }

    .past-webinars-modal-video {
        height: 441px;
    }

    .past-webinars-modal-close-button {
        width: 27px;
        height: 27px;
    }

    .past-webinars-modal-close-button svg {
        width: 27px;
        height: 27px;
    }

    .past-webinars-modal-text-container {
        padding: 18px 24px;
    }

    h3.past-webinars-modal-title {
        font-size: 18px;
    }

    h5.past-webinars-modal-subtext {
        font-size: 12px;
        margin-bottom: 12px;
    }

    p.past-webinars-modal-text {
        font-size: 12px;
    }

    /*  My Registrations  */
    .my-registrations-item {
        width: 100%;
        margin-bottom: 18px;
    }

    h4.my-registrations-item-title {
        font-size: 18px;
        margin-bottom: 12px;
    }

    h5.my-registrations-item-info-title {
        font-size: 14px;
        max-width: 525px;
    }

    .my-registrations-item-description-container {
        min-height: 37px;
        max-height: 75px;
        margin-bottom: 12px;
    }

    p.my-registrations-item-description {
        font-size: 14px;
        line-height: 21px;
        margin: 12px 0;
    }

    .my-registrations-item-btn-container {
        padding-bottom: 24px;
    }

    .my-registrations-item-btn {
        padding: 7px 15px 6px;
        font-size: 16px;
    }

     /* My Registrations - Modal */
    .my-registrations-modal {
        max-width: 540px;
    }

    h1.my-registrations-modal-title {
        font-size: 18px;
        margin-bottom: 18px;
    }

    .my-registrations-modal-container {
        padding: 24px;
    }

    p.my-registrations-modal-text {
        font-size: 16px;
    }

    .my-registrations-modal-btn-container {
        gap: 12px;
        padding-top: 22px;
    }

    .my-registrations-modal-btn {
        width: 157px;
        padding: 7px 0 6px;
        font-size: 15px;
    }

    /* Inbox */
    .inbox-header-container {
        margin-bottom: 18px;
    }

    h2.inbox-header-title {
        font-size: 24px;
    }

    .inbox-btn {
        padding: 9px 15px 7px;
    }

    .inbox-container {
        gap: 12px 12px;
    }

    .inbox-no-notifications-container {
        height: 300px;
    }

    .notification-item {
        padding: 18px 7px;
    }

    .notification-item-action-container {
        gap: 6px;
    }

    .notification-item-action-container svg {
        width: 18px;
        height: 18px;
    }

    h5.notification-item-title {
        font-size: 18px;
    }

    h5.notification-item-title.date {
        font-size: 14px;
    }

    .notification-item-title .modal-title {
        font-size: 18px;
    }

    p.notification-item-description,
    p.notification-item-description span {
        font-size: 14px;
    }

    .notification-item-btn {
        padding: 6px 15px 4px;
        font-size: 16px;
    }

    .inbox-load-more-container {
        margin: 27px 0 0;
    }

    .inbox-load-more-button {
        font-size: 14px;
        padding: 6px 15px 4px;
    }

    /* Inbox modal */
    .inbox-modal-body-container {
        padding: 7px 0 15px;
    }

    .inbox-modal-btn-container {
        gap: 12px;
    }

    /*  Consultation  */
    /* Consultation - Book a Consult */
    .consultation-header {
        font-size: 24px;
        margin-bottom: 24px;
    }

    .consultation-sub-header {
        margin-bottom: 21px;
        font-size: 18px;
    }

    .available-consultants-container {
        gap: 12px 12px;
    }

    .consultant-booking-item {
        /*max-width: 475px;*/
        width: 425px;
        min-height: 200px;
        padding: 18px;
        column-gap: 18px;
    }

    .consultant-booking-item .empty-profile-image {
        width: 93px;
        height: 93px;
    }

    .consultant-profile-img-container {
        width: 93px;
    }

    .consultant-name {
        font-size: 24px;
        max-width: 525px;
        margin-bottom: 2px;
    }

    .consultant-booking-name {
        font-size: 18px;
        line-height: 24px;
        max-width: 326px;
        max-height: 24px;
    }

    .consultant-booking-title {
        margin: 3px 0;
        max-height: 19px;
    }

    .consultant-booking-bio {
        font-size: 14px;
        line-height: 24px;
        max-height: unset;
        min-height: 63px;
        margin: 9px 0 18px 0;
        max-width: 525px;
    }

    .consultant-action-container {
        width: 186px;
        height: 34px;
        margin: 9px 0;
    }

    .btn-consultation-action {
        line-height: 25px;
    }

    /* Book a Consultant - Weekly View */
    .book-consultations-container.consultant-book-availability {
        padding-top: 24px;
    }

    .btn-cbit-link.btn-return-consultant {
        font-size: 14px;
        line-height: 24px;
    }

    .consultant-availability-info {
        margin-top: 39px;
        gap: 30px;
    }

    .consultant-info-container-right .btn-cbit-link {
        font-size: 14px;
        margin-bottom: 4px;
    }

    .booking-availability-container {
        margin-top: 22px;
    }

    .booking-availability-controls {
        height: 24px;
    }

    .availability-date-range {
        font-size: 14px;
        line-height: 24px;
    }

    .date-picker-btn-container,
    .btn-cbit-calendar-icon,
    .date-picker-btn-container input[type="date"] {
        width: 18px;
    }

    .btn-consultation-calendar {
        width: 18px;
    }

    .availability-date-header {
        height: 56px;
    }

    .day-of-the-week {
        font-size: 14px;
    }

    .availability-date {
        font-size: 16px;
        line-height: 20px;
    }

    .available-sessions-list {
        margin-top: 6px;
        gap: 7px;
    }

    .non-available-session-item {
        font-size: 14px;
        line-height: 24px;
    }

    /* Consultant Bio - Modal */
    h2.my-profile-description-name {
        font-size: 24px;
    }

    .my-profile-full-description {
        max-width: 675px;
        padding: 12px;
    }

    /* Availability Management */
    .availability-date-container {
        margin-bottom: 24px;
    }

    .calendar-date-range.availability-date-range {
        font-size: 18px;
        min-width: 171px;
    }

    .consultation-calendar-legend {
        gap: 12px;
        margin-bottom: 24px;
        font-size: 14px;
    }

    .consultation-legend-icon {
        height: 7px;
        width: 7px;
        margin-right: 7px;
    }

    .btn-cbit-icon.btn-availability-control {
        width: 22px;
        height: 24px;
    }

    .toastui-calendar-month .toastui-calendar-month-daygrid {
        font-size: 13px !important;
    }

    .toastui-calendar-grid-cell-date .toastui-calendar-weekday-grid-date.toastui-calendar-weekday-grid-date-decorator {
        height: 22px;
        line-height: 22px;
        width: 22px;
    }

    /* Availability Management - Modal */
    .consultation-plan-form {
        width: 630px;
    }

    .form, .cbit-dialog {
        padding: 30px 24px 30px;
    }

    .cbit-dialog {
        padding-bottom: 24px;
    }

    h2.form-title, .cbit-header {
        font-size: 18px;
        margin: 0 0 18px;
    }

    .form-label, .cbit-label {
        font-size: 16px;
    }

    .consultation-plan-form .form-input {
        height: 36px;
    }

    .consultation-plan-form .form-input,
    .consultation-plan-textarea-container {
        margin-bottom: 18px;
    }

    .text-character-limit-container {
        font-size: 16px;
    }

    .session-time-to-column.cbit-column {
        width: 18px;
    }

    .session-time-to-text {
        font-size: 16px;
    }

    .recurring-repeat-container {
        margin-bottom: 18px;
    }

    .recurring-note {
        font-size: 14px;
    }

    .upload-text {
        font-size: 14px;
        padding-bottom: 7px;
    }

    .consultation-plan-form .btn-cbit-primary,
    .consultation-plan-form .btn-cbit-minor {
        height: 36px;
        min-width: unset;
        width: 127px;
    }

    .plan-button-group .btn-cbit-primary {
        margin-left: 12px;
        width: 127px;
    }

    /* Consultation Info Modal */
    .consultation-plan-form.consultation-info {
        width: 405px;
        padding: 30px 24px;
    }

    .consultation-info .view-details-text-container {
        margin-bottom: 18px;
    }

    .btn-cbit-text,
    .btn-cbit-link {
        font-size: 14px;
    }

    .consultation-info {
        font-size: 14px;
    }

    /*Consultation Info - Delete Modal */
    .custom-alert {
        padding: 30px 24px 30px;
        width: 540px;
    }

    .custom-alert-header {
        font-size: 18px;
        line-height: 31px;
        padding-bottom: 18px;
    }

    .custom-alert-text {
        font-size: 16px;
    }

    .btns-custom-alert-container {
        padding-top: 18px;
    }

    .custom-alert .btn-cbit-minor {
        min-width: 100px;
        height: 36px;
        margin-right: 9px;
    }

    .custom-alert .btn-cbit-primary {
        min-width: 150px;
        height: 36px;
    }

    /* My Upcoming Sessions */
    .consultation-filters {
        margin-bottom: 30px;
        gap: 12px;
    }

    .form-input {
        padding: 6px 10px;
        font-size: 14px;
    }

    .upcoming-consultations {
        font-size: 14px;
    }

    .session-item {
        min-height: 249px;
        gap: 24px;
        margin-bottom: 25px;
    }

    .consultant-info-container-left.session-profile-icon {
        max-width: 93px;
    }

    .consultation-action-container {
        gap: 12px;
        margin-bottom: 21px;
    }

    .btn-consultation-edit {
        width: 144px;
        height: 32px;
    }

    .btn-consultation-delete {
        height: 32px;
        width: 126px;
    }

    /* My Previous Sessions */
    #previous-sessions.consultation-filters {
        max-height: 36px;
        gap: 12px;
        margin-bottom: 65px;
    }

    .consultation-filters-container {
        max-width: 675px;
        gap: 12px;
    }

    .consultation-filter-select {
        max-width: 255px;
    }

    .empty-session-toggle {
        gap: 12px;
    }

    .empty-session-toggle span {
        font-size: 13px;
        max-width: 130px;
    }

    .toggle-switch {
        width: 45px;
        height: 32px;
    }

    .btn-consultation-minor.btn-cbit-minor {
        font-size: 15px;
        line-height: 25px;
        height: 33px;
        width: 180px;
    }

    /* My Previous Sessions - Review Modal */
    .consultation-review-form {
        width: 525px;
    }
    .consultation-review-form p {
        font-size: 16px;
    }

    .review-btn-container {
        margin-bottom: 12px;
    }

    .review-btn-container span {
        margin-right: 7px;
    }

    .rating-label {
        font-size: 1.8rem;
    }

    .rating-container {
        margin-bottom: 10px;
    }

    .review-user-profile {
        max-width: 525px;
        max-height: 450px;
    }

    .form-btn-container .btn-cbit-primary {
        width: 108px;
        font-size: 15px;
    }

    /* My Profile */
    .consultant-image-container {
        gap: 18px;
        margin-bottom: 24px;
    }

    .consultant-my-profile-image-container,
    .consultant-image-container .empty-profile-image {
        width: 120px;
        height: 120px;
    }

    .empty-profile-image {
        font-size: 12px;
    }

    .consultant-image-container .btn-cbit-minor {
        width: 144px;
        height: 36px;
    }

    .consultant-profile-container {
        max-width: 479px;
    }

    .consultant-profile-container p {
        height: 36px;
        font-size: 14px;
    }

    .consultant-profile-container .form-input {
        height: 36px;
    }

    .consultant-profile-container #bio{
        height: 115px;
    }

    .consultant-profile-container .cbit-btn-container {
        margin-top: 30px;
    }

    .consultant-profile-container .cbit-btn-container button {
        width: 108px;
        height: 33px;
    }

    .btn-remove-image {
        height: 33px;
    }

    .consultant-upload-container,
    .consult-profile-name-container.cbit-row {
        gap: 12px;
    }

    .consultant-bio-description.form-input {
        margin-bottom: 5px;
    }

    /* Family Support */
    .category-title-container {
        gap: 22px;
    }

    h2.category-title {
        font-size: 24px;
        margin-bottom: 24px;
    }

    .SupportContent-title {
        margin-bottom: 7px;
        font-size: 16px;
        letter-spacing: 0.6px;
    }

    /* Family Support - Admin */
    .chapter-content-container {
        min-height: 18px;
        padding: 7px 0 7px 18px;
    }

    .drag-handle,
    .drag-handle svg {
        height: 26px;
        width: 9px;
    }

    .inline-basic-input {
        max-width: 172px !important;
    }

    .therapist-panel-btn-container button {
        width: 26px;
        margin-right: 12px;
    }

    .standard-content-btn-container {
        min-height: 48px;
    }

    .nav-sidebar .standard-content-btn-container {
        padding: 7px 18px 7px 18px;
        bottom: 5px;
    }

    .admin-content-menu-item,
    .admin-content-menu-item-loading {
        width: 246px;
        height: 54px;
    }

    .plus-icon {
        margin: 9px 12px;
        width: 36px;
        height: 36px;
    }

    .plus-icon svg {
        width: 36px;
        height: 36px;
    }

    .admin-content-menu-item-subheading {
        font-size: 16px;
        margin: 7px 0;
        height: 18px;
    }

    .btn-tag-add {
        margin-top: 9px;
        height: 18px;
    }

    .tag-add-text {
        font-size: 16px;
        line-height: 0.9em;
    }

    .admin-chapter-text {
        font-size: 16px;
        letter-spacing: 0.7px;
    }

    .admin-content-body {
        margin-left: 15px;
    }

    .admin-content-container {
        margin: 15px 0;
    }

    .admin-support-content {
        font-size: 14px;
    }

    /* Family Support - Admin: Modal - Edit */
    .additional-content-form {
        width: 450px;
        padding: 22px !important;
    }

    .additional-content-form .btn-cbit-primary {
        width: 108px;
        height: 33px;
    }

    /* Admin Tools */
    .admin-tools {
        padding: 15px;
    }

    .admin-tools-inner {
        margin-top: 11px;
        gap: 15px;
    }

    .admin-tools-item {
        width: 341px;
    }

    .admin-tools-item,
    .admin-tools-item a {
        min-height: 112px;
    }

    .admin-tools-item a {
        padding: 15px;
    }

    .admin-tool-header {
        font-size: 17px;
    }

    .admin-tool-body {
        font-size: 14px;
    }

    /* Therapist Support */
    .tag-item {
        padding-bottom: 25px;
        margin-bottom: 30px;
    }

    h4.tag-title {
        margin-bottom: 12px;
        font-size: 18px;
    }

    .therapist-content-item {
        height: 97px;
        min-height: 48px;
        max-height: 97px;
        min-width: 342px;
        max-width: 342px;
    }

    .therapist-content-item-info {
        width: 30px;
    }

    .therapist-icon-container {
        width: 26px;
    }

    .content-item-duration {
        font-size: 14px;
    }

    .therapist-content-title {
        font-size: 15px;
        line-height: 18px;
    }

    .tag-item .plus-icon {
        height: 18px;
        width: 18px;
    }

    .therapist-support-modal {
        width: 480px;
    }

    .therapist-player,
    .react-player {
        width: 480px !important;
        height: 270px !important;
    }

    .react-player-title {
        font-size: 18px;
    }

    .react-player-title-container {
        padding: 15px 20px;
    }

    .therapist-player-title-container {
        padding: 15px 20px;
    }

    .therapist-player-title {
        font-size: 18px;
    }

    .therapist-content-description {
        max-width: 342px;
    }

    h2, .h2 {
        font-size: 24px;
    }

    /* Therapist Support - Admin */
    .admin.therapist-content-item .therapist-content-title {
        width: 216px;
        max-width: 216px;
    }

    .therapist-content-item .therapist-panel-btn-container {
        margin-left: 18px;
    }

    /* Referral */
    /* Referral Modal */
    .referral-alert {
        width: 326px;
    }

    .cbit-dashboard-header {
        font-size: 24px;
        margin-bottom: 24px;
    }

    .referral-alert p {
        font-size: 14px;
        margin-bottom: 30px;
    }

    .cbit-dialog-btn-container .btn-cbit-primary,
    .cbit-dialog-btn-container .btn-cbit-minor {
        height: 36px;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 1.4px;
    }

    /* Referral Profile */
    .referral-profile-body {
        gap: 36px;
    }

    .referral-image-container,
    .referral-image-container .btn-cbit-minor,
    .referral-details .referral-image-container,
    .referral-details .referral-image-container .empty-image-container {
        width: 144px;
    }

    .referral-details .referral-image-container .empty-image-container {
        height: 144px;
    }

    .referral-image-container .upload-btn-container {
        margin-bottom: 9px;
    }

    .upload-btn-container {
        width: 133px;
        height: 36px;
        letter-spacing: 1.4px;
    }

    .referral-image {
        height: 144px;
    }

    .empty-image-container,
    .referral-profile .empty-image-container {
        width: 144px;
        height: 144px;
    }

    .referral-form {
        width: 450px;
    }

    .referral-container {
        gap: 36px;
    }

    .cbit-column.referral-address-field {
        margin-bottom: 11px;
    }

    .user-form-checkbox-container .checkbox-label-cbit {
        font-size: 16px;
    }

    /* Referral */
    /* View Referral List */
    .referral-users-container .cbit-row {
        flex-direction: column;
        gap: 12px;
    }

    .referral-users-container .cbit-dashboard-header {
        font-size: 18px;
    }

    .checkbox-label-cbit {
        font-size: 14px;
    }

    .checkbox-cbit {
        height: 16px;
        width: 16px;
    }

    .referral-address-legend {
        gap: 15px;
        font-size: 14px;
    }

    .referral-map-popup {
        max-width: 152px;
        max-height: 82px;
        font-size: 14px;
    }

    .referral-map-popup .cbit-small-header {
        font-size: 14px;
    }

    .cbit-divider {
        margin: 6px 0;
    }

    .referral-user-item {
        margin-bottom: 30px;
    }

    .referral-user-item-profile .referral-image-container {
        width: 42px;
    }

    .referral-user-item-profile .empty-image-container {
        width: 42px;
        height: 42px;
    }

    .referral-user-item-profile .referral-image-container .referral-image {
        height: 42px;
    }

    .referral-user-item-profile .referral-image-container .empty-profile-image {
        font-size: 7px;
    }

    .referral-user-item-name.btn-cbit-text {
        font-size: 16px;
    }

    .referral-user-item-occupation {
        font-size: 14px;
    }

    .referral-user-item-bio {
        font-size: 14px;
    }

    .referral-user-item-btn-container .btn-cbit-minor {
        height: 28px;
        width: 120px;
        font-size: 14px;
    }

    /* Referral */
    /* View Referral List - Modal */
    .cbit-small-header {
        font-size: 18px;
    }

    .referral-bio-container p {
        font-size: 14px;
    }

    #referralUserMap {
        height: 144px;
    }

    /* Training */
    /* Layout */
    .isOpen .isNavOpen .MainContent {
        width: calc(100vw - 271px);
    }

    .isOpen .MainContent {
        width: calc(100vw - 271px);
    }

    .Menu-Right-Bar {
        width: 271px;
        max-width: 271px;
    }

    .AccordionContent {
        width: 300px;
    }

    .Viewer-Content {
        left: 300px;
        width:calc(100vw - 300px);
    }

    .isOpen .Viewer-Content {
        left: 300px;
        width: calc(100vw - 571px);
    }

    .isOpen .isNavOpen .Viewer-Content {
        left: 300px;
        width:calc(100vw - 571px);
    }

    .menuTab,
    .accordMenuTab {
        top: 15px;
        height: 72px;
        width: 36px;
    }

    .isOpen .menuTab {
        right: -74px;
    }

    /* Training */
    /* NavSidebar */
    .faded-navigation-section-label {
        padding-top: 13px;
        padding-left: 18px;
        font-size: 14px;
        letter-spacing: 0.4px;
    }

    .navigation-section-label {
        padding-left: 19px;
        padding-top: 63px;
        font-size: 30px;
    }

    .accordion-body {
        height: calc(100vh - 172px);
    }

    .accordion-item-header-chapter {
        height: 45px;
    }

    .accordion-item-header-chapter-lesson {
        height: 45px;
    }

    .accordion-item-arrow {
        width: 15px;
        height: 45px;
    }

    .orange-selected {
        width: 4px;
        height: 45px;
        left: -18px;
        margin-right: -4px;
    }

    .admin-chapter-item {
        min-height: 48px;
    }

    .chapter-lesson-amount {
        font-size: 12px;
        letter-spacing: 1px;
    }

    .AdditionalVideoContainer .chapter-lesson-amount {
        font-size: 12px;
        letter-spacing: 1px;
        width: 43px;
    }

    .chapter-lesson-amount {
        font-size: 12px;
        letter-spacing: 1px;
    }

    .admin-icon-container {
        margin: 0 9px 0 9px;
    }

    .admin-caret-left img {
        width: 6px;
    }

    .admin-caret-down img {
        height: 6px;
    }

    .admin-lesson-item {
        padding-left: 18px;
    }

    .admin-lesson-text {
        width: 182px;
    }

    .admin-lesson-duration {
        font-size: 11px;
    }

    .lesson-duration-buffer {
        min-width: 27px;
    }

    .admin-lesson-group-item {
        padding-left: 18px;
    }

    .sub-lesson-item {
        padding-left: 25px;
    }

    .admin-lesson-group-items .admin-lesson-content {
        padding-left: 17px;
        min-height: 27px;
    }

    .text-margin-15 {
        margin-left: 11px;
    }

    .admin-lesson-group-items .admin-lesson-text {
        width: 160px;
    }

    .admin-lesson-text {
        font-size: 14px;
    }

    .text-margin-15-important {
        margin-left: 11px !important;
    }

    /* Training */
    /* Viewer Content */
    .video-player-background {
        height: calc(45vw);
        margin-bottom: 24px;
    }

    .video-content-panel-header {
        margin: 15px 24px 12px;
        gap: 3px;
    }

    .video-text-title {
        max-width: 652px;
        font-size: 21px;
        line-height: 30px;
    }

    .Left-Buffer-24 {
        padding-left: 18px;
    }

    .btn-cbit,
    .btn-disabled {
        min-height: 28px;
        padding: 6px 8px;
        font-size: 15px;
        letter-spacing: 0.8px;
    }

    .btn-primary,
    .btn-secondary {
        font-size: 15px;
        letter-spacing: 0.8px;
        padding: 6px 8px;
    }

    .video-text-offset {
        margin: 11px 25px 0;
    }

    .video-text-body {
        font-size: 14px;
        width: 100%;
        padding: 0 10px;
    }

    /* Training */
    /* Additional Content */
    .addVideoName {
        margin-left: 24px;
        margin-top: 24px;
        font-size: 14px;
        letter-spacing: 2px;
        max-width: 180px;
    }

    .add-video-separator {
        width: 210px;
        height: 2px;
        margin-left: 24px;
    }

    .addVideo-box {
        margin-left: 24px;
        width: 210px;
    }

    .addVideo-text {
        margin-top: 4px;
        margin-left: 24px;
        margin-bottom: 18px;
    }

    .addVideo-titleText {
        font-size: 16px;
    }

    .addVideo-lengthText {
        font-size: 12px;
    }

    .no-content-additional-video-container {
        margin-left: 24px;
        width: 210px;
    }

    .no-content-image-container {
        width: 90px;
    }

    .no-content-text {
        font-size: 14px;
    }

    /* Training */
    /* Quiz, Combo, Text & Video Lessons */
    .quiz-content-panel,
    .combo-lesson-quiz-content-panel {
        margin-top: 48px;
        margin-left: 60px;
        margin-right: 60px;
    }

    .quiz-title,
    .combo-lesson-quiz-title {
        font-size: 24px;
        margin-bottom: 24px;
    }

    .quiz-instruction-text {
        font-size: 14px;
        margin-bottom: 24px;
    }

    p {
        font-size: 12px;
    }

    .quiz-question-text {
        font-size: 14px;
        margin-bottom: 24px;
    }

    .quiz-question-seperation {
        margin-left: 24px;
    }

    .radioButtonAnswerAdjustment {
        margin-left: -26px;
    }

    .pdf-toolbox-box .addVideo-box {
        width: 210px;
        height: 113px;
    }

    .textLessonImage {
        margin-bottom: 24px;
    }

    .admin-icon-container.mobile {
        display: none;
    }

    /* My Profile */
    .my-profile-container {
        max-width: 600px;
        min-height: 580px;
    }

    .my-profile-container .form {
        min-height: 580px;
    }

    .my-profile-container .form-textarea {
        min-height: 87px;
    }

    .my-profile-container .upload-btn-container {
        height: 36px;
        padding: 0 8px;
        letter-spacing: 1.4px;
    }

    .my-profile-upload-container {
        height: 93px;
    }

    .my-profile-image-container {
        height: 93px;
        width: 93px;
    }

    .profile-image-container {
        height: 93px;
        width: 93px;
    }

    .profile-image-upload-container {
        width: 412px;
    }

    .profile-image-text {
        font-size: 16px;
    }

    .my-profile-container .btn-cbit-icon {
        height: 18px;
        width: 18px;
    }

    .my-profile-container .cbit-row {
        margin: 10px 0;
        max-height: 60px;
    }

    .my-profile-container .cbit-col:first-child {
        padding-right: 22px;
    }

    .my-profile-input,
    .my-profile-text {
        font-size: 16px;
    }

    .cbit-row.isEditing {
        margin-bottom: 20px;
    }

    .my-profile-text {
        padding: 4px 0;
    }

    .my-profile-container .my-profile-description-container {
        height: 117px;
        min-height: 117px;
    }

    .my-profile-description {
        height: calc(100% - 30px);
    }

    .submit-btn-container {
        margin: 18px 0 0 !important;
    }

    .submit-btn-container .btn-cbit-primary {
        height: 36px;
        width: 108px;
        margin-top: 6px;
    }

    /* Password Reset */
    .password-reset-form .btn-cbit-primary {
        height: 36px;
        width: 108px;
    }

    .login-form .btn-cbit-primary, .forgot-form .btn-cbit-primary {
        width: 108px;
        height: 36px;
    }

    /* Sign Up */
    .user-form .btn-cbit-primary {
        width: 108px;
        height: 36px;
    }

    /* Content Builder */
    /* Nav Sidebar */
    .admin-nav-header {
        padding: 44px 18px 18px 18px;
    }

    .admin-nav-title-container {
        min-height: 41px;
    }

    .admin-nav-text-container {
        min-height: 34px;
    }

    .admin-nav-text {
        font-size: 14px;
    }

    /* Content Builder */
    /* View Panel */
    .admin-view-inner-panel {
        padding: 10px 42px 0 42px;
    }

    .admin-breadcrumb,
    .admin-breadcrumb:hover,
    .admin-bread-crumbs-arrow,
    .admin-active-breadcrumb {
        line-height: 25px;
        font-size: 12px;
        letter-spacing: 0.4px;
    }

    .admin-view-header {
        margin-bottom: 18px;
        padding-top: 3px;
    }

    .admin-view-tools-container {
        height: 33px;
    }

    .admin-view-tools-right {
        gap: 24px;
    }

    .empty-editor-btns-container,
    .editor-btns-container {
        width: 240px;
        height: 33px;
    }

    .btn-save-content {
        width: 108px;
        height: 36px;
    }

    /* Content Builder */
    /* Content List */
    .content-list {
        gap: 6px;
    }

    .content-item {
        padding: 22px 18px;
    }

    .content-item-type {
        margin: 0 18px;
        height: 21px;
        width: 21px;
    }

    .lesson-content-caret-down,
    .lesson-content-caret-left {
        width: 8px;
    }

    .lesson-content-caret-left img {
        width: 6px;
    }

    .content-item-options li {
        margin-right: 18px;
    }

    .content-action-icon-visibility {
        width: 22px;
        height: 18px;
    }

    .content-action-icon {
        width: 18px;
    }

    .lesson-content-duration {
        font-size: 14px;
        width: 30px;
        height: 16px;
    }

    .admin-view-item {
        margin-top: 19px;
        padding: 0 10px 0 30px;
    }

    /* Content Builder */
    /* Content Menu Panel */
    .component-list {
        gap: 9px;
        padding: 42px 27px;
    }

    .content-label {
        font-size: 16px;
        letter-spacing: 0.40px;
    }

    .content-menu-custom-container,
    .admin-content-menu-item-subheading {
        padding: 0 9px 0 0;
    }

    .admin-content-menu-item-text {
        font-size: 14px;
    }

    .attendee-list .cbit-data-grid-container {
        width: 100%;
    }

}

@media (max-width: 1023px) {
    /* Navbar */
    .nav-items,
    .nav-items.home a:first-child {
        display: none;
    }

    .nav-items.home {
        display: block;
    }

    .menu-item.mobile {
        display: block;
    }

    /* Nav Sidebar */
    button.nav-sidebar-collapse {
        display: block;
    }

    .nav-sidebar-menu-tab {
        display: block;
        top: 15px;
        height: 72px;
        width: 36px;
    }

    .buttonIcon {
        margin: 2px 0 2px 4px;
    }

    .nav-sidebar {
        z-index: 5000;
    }

    .isOpen .nav-sidebar {
        z-index: 5000;
        box-shadow: 13px 1px 24px 0 rgba(0, 0, 0, 0.5);
    }

    .isOpen .social-connection-main-content,
    .isOpen .continuing-education-main-content,
    .isOpen .inbox-main-content,
    .isOpen .consultation-main-content,
    .isOpen .therapist-support-main-content,
    .isOpen .referral-main-content {
        left: 0;
        width: 100vw;
    }

    /* Training */
    /* Layout */
    .nav-menu-icon-container {
        display: flex;
    }

    .nav-menu-icon {
        width: 34px;
        height: 13px;
        margin-top: 18px;
        margin-right: 16px;
    }

    .isOpen .Viewer-Content {
        left: 0;
        width: 100vw;
    }

    .isOpen .MainContent {
        width: 100vw;
    }

    .isNavOpen .MainContent {
        width:  100vw;
    }

    .MainContent {
        left: 0;
        width: 100vw;
    }

    .MainContent .AccordionContent {
        width: 80vw;
        left: -80vw;
    }

    .isNavOpen .AccordionContent {
        left: 0;
        z-index: 10;
        box-shadow: 5px 12px 36px #00000042 !important;
    }

    .isNavOpen .Viewer-Content {
        left: 0;
        width: 100vw;
    }

    .Viewer-Content {
        left: 0;
        width: 100vw;
    }

    #accordion-tab {
        display: block;
        position: absolute;
        left: 0;
        top: 15px;
        height: 72px;
        width: 36px;
        transform: rotate(180deg);
    }

   .nav-menu-icon:hover {
        cursor: pointer;
    }

    .isNavOpen #accordion-tab {
        display: none;
    }

    .Menu-Right-Bar {
        width: 271px;
        max-width: 271px;
        right: -271px;
    }

    .isOpen .Menu-Right-Bar {
        right: 0;
        z-index: 9;
    }

    /* Training */
    /* NavSidebar */
    .chapter-content-container .chapter-lesson-amount,
    .chapter-content-container .admin-icon-container {
        display: none;
    }

    .chapter-lesson-amount-container.mobile,
    .chapter-lesson-amount.mobile,
    .admin-icon-container.mobile,
    .admin-lesson-text-container.mobile,
    .admin-lesson-content.mobile {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .admin-lesson-text-container.mobile {
        justify-content: space-between;
    }

    .admin-duration-container.mobile {
        display: flex;
        align-items: center;
    }

    .admin-lesson-content.mobile {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .admin-nav-body .admin-lesson-content.mobile {
        justify-content: flex-start;
    }

    .admin-icon-container.mobile {
        width: auto;
        margin: 0 2px 0 8px;
    }

    .chapter-content-container {
        padding: 7px 18px 7px 18px;
    }

    .admin-lesson-item {
        padding: 0 14px 0 18px;
    }

    .sub-lesson-mobile {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .sub-lesson-mobile.end {
        justify-content: flex-end;
        padding-right: 10px;
    }

    .admin-lesson-text {
        width: auto;
    }

    .admin-lesson-content {
        width: 98%;
    }

    /*  Consultant's - Bio  */
    .consultant-booking-bio {
        max-width: unset;
        width: auto;
        margin-right: 20px !important;
    }

    /* Referral */
    /* View Referral List */
    .referral-users-container {
        display: flex;
        flex-direction: column;
        min-width: unset;
        width: 100%;
        height: auto;
        padding: 0 0 18px 0;
    }

    .referral-list-page {
        display: flex;
        flex-direction: column;
        height: auto;
    }

    #leafletMap {
        width: 100%;
        height: 300px;
    }

    .referral-user-list-container {
        display: none;
    }

    .cbit-form-group.referral-user-list.mobile {
        display: flex;
        flex-direction: column;
    }

    .referral-user-list-container.mobile {
        display: flex;
        flex-direction: column;
        height: auto;
        margin-top: 24px;
    }

    .cbit-label.referral-not-found.mobile {
        margin-top: 24px;
    }


}

@media (max-width: 768px) {
    /* Navbar */
    .header-navbar {
        height: 64px;
    }

    .logo-container {
        min-width: unset;
        height: 50px;
        margin: 0 0 0 10px;
    }

        .logo-container img {
            margin-top: 5px;
        }

    .welcome-text-container p {
        font-size: 14px;
    }

    .nav-item-text {
        font-size: 16px;
    }

    /* User Profile Menu */
    .user-profile-menu-icon svg {
        width: 40px;
        height: 40px;
    }

        div .menu-item-container {
            top: 67px;
            right: 12px;
            font-size: 13px;
        }

        .menu-item {
            height: 35px;
        }

        .menu-item-link {
            line-height: 37px;
        }

        .user-navigation-items {
            min-width: unset;
            margin-right: 10px;
        }

    /* Social Connection */
    /* Nav Sidebar */
    .AccordionContent {
        width: 60vw;
    }

    .nav-sidebar {
        height: calc(100vh - 64px);
    }

        .isOpen .nav-sidebar {
            height: calc(100vh - 64px);
            width: 100vw;
        }

        .nav-sidebar-menu-tab {
            top: 10px;
            height: 57px;
            width: 29px;
        }

    .social-connection-layout  .buttonIcon,
    .continuing-education-layout .buttonIcon,
    .inbox-layout .buttonIcon,
    .consultation-layout .buttonIcon,
    .therapist-support-layout .buttonIcon,
    .referral-layout .buttonIcon {
        margin: 0 0 0 3px;
        font-size: 11px;
    }

    /* Social Connection */
    /* Nav Sidebar - Content */
    h2.nav-sidebar-title {
        font-size: 26px;
    }

    .therapist-support-layout .sidebar-link-text:hover {
        cursor: pointer;
    }

    /* Social Connection */
    /* Main content container */
    .social-connection-main-content,
    .continuing-education-main-content,
    .inbox-main-content,
    .consultation-main-content,
    .therapist-support-main-content,
    .referral-main-content {
        height: calc(100vh - 64px);
        width: calc(100vw);
        padding: 28px 33px;
    }

    .therapist-support-main-content {
        padding: 28px 60px;
    }

    .consultation-main-content {
        padding: 28px 20px;
    }

        .isOpen .social-connection-main-content,
        .isOpen .continuing-education-main-content,
        .isOpen .inbox-main-content,
        .isOpen .consultation-main-content,
        .isOpen .therapist-support-main-content,
        .isOpen .referral-main-content {
            height: calc(100vh - 64px);
        }

        .social-connection-header {
            margin-bottom: 20px;
        }

        h2.social-connection-heading {
            font-size: 20px;
        }

        .btn-social-connection {
            line-height: 20px;
            height: 28px;
            width: 80px;
            max-width: unset;
        }

        .btn-social-connection span {
            display: none;
        }

        .new-post-icon {
            display: block;
            font-size: 20px;
        }

        .btn-cbit-minor,
        .btn-cbit-primary {
            letter-spacing: 1.20px;
            font-size: 14px;
        }

    /* Social Connection */
    /* Search */
    .social-connection-search-container {
        margin-bottom: 20px;
    }

        .social-connection-search-left,
        .social-connection-search-right {
            max-width: 80px;
        }

        .btn-social-connection-search {
            height: 31px;
            max-width: 80px;
        }

        .btn-social-connection-search span {
          display: none;
        }

        .btn-social-connection-search .search-icon {
            display: block;
        }

    /* Social Connection */
    /* Subheading */
    .social-connection-subheader {
        margin-bottom: 20px;
    }

        h4.social-connection-subheading {
            font-size: 16px;
        }

    /* Social Connection */
    /* Posts container */
    .social-connection-post-header-left,
    .social-connection-post-reply-left,
    .social-connection-post-reply-right,
    .social-connection-post-header-right {
        width: 120px;
    }

        .social-connection-profile-image {
            width: 48px;
            height: 48px;
            margin-right: 6px;
            margin-bottom: 9px;
        }

        h5.social-connection-username {
            font-size: 16px;
            margin-right: 2px;
        }

        .social-connection-post-content {
            padding: 6px 14px;
        }

        h4.social-connection-post-content-heading {
            font-size: 16px;
            margin-bottom: 10px;
        }

        p.social-connection-post-content-text {
            font-size: 15px;
            margin-bottom: 10px;
        }

        h5.social-connection-reply-count {
            font-size: 15px;
            margin-bottom: 10px;
        }

        h5.social-connection-date {
            font-size: 15px;
        }

    /* Social Connection */
    /* No posts */
    .social-connection-no-posts-container {
        padding: 13px 0;
    }

        h4.social-connection-no-posts-text {
            font-size: 16px;
        }

    /* Social Connection */
    /* Single Post Page */
    .social-connection-single-post-page-header {
        margin-bottom: 19px;
    }

        .social-connection-go-back a {
            font-size: 13px;
            line-height: 27px;
            max-width: 169px;
        }

        .social-connection-single-post {
            padding: 0;
            margin: 0;
        }

        .social-connection-single-post-user-image {
            width: 90px;
            height: 90px;
        }

        .social-connection-single-post-user-image-reply {
            width: 40px;
            height: 40px;
        }

        .social-connection-single-post-content {
            padding-left: 14px;
        }

        .social-connection-single-post-header {
            gap: 10px;
            margin-bottom: 5px;
        }

        h5.social-connection-single-post-username {
            font-size: 14px;
        }

        h5.social-connection-single-post-date {
            font-size: 12px;
        }

        h4.social-connection-single-post-title {
            font-size: 16px;
            margin-bottom: 9px;
        }

        p.social-connection-single-post-text {
            font-size: 13px;
            line-height: 14px;
        }

    /* Social Connection */
    /* Post Toolbar */
    .social-connection-post-toolbar {
        gap: 0;
        margin-bottom: 0;
    }

        .social-connection-post-toolbar span {
            margin-right: 1px
        }

    /* Social Connection */
    /*  Add Reply  */
    .btn-social-connection-add-reply,
    .btn-social-connection-add-reply-submit {
        max-width: 96px;
        line-height: 20px;
        padding: 4px 12px;
    }

        .social-connection-post-add-reply-container {
            max-width: 100%;
            padding: 14px 7px;
        }

        .social-connection-post-add-reply-center {
            padding: 7px 18px;
        }

        .social-connection-post-add-reply-textarea {
            font-size: 13px;
            line-height: 14px;
            padding: 9px;
        }

        .social-connection-post-add-reply-center textarea {
            height: 60px;
            padding: 5px;
            margin-bottom: 12px;
        }

    /* Social Connection */
    /* Reply */
    .social-connection-single-post-reply-container {
        max-width: 100%;
        margin: 0 0 0 0;
    }

        .social-connection-single-post-reply {
            margin: 0 0 0 21px;
            padding: 9px 0;
        }

        .social-connection-single-post-reply-user-image {
            width: 60px;
            height: 60px;
        }

        .social-connection-single-post-reply-body {
            padding-left: 14px;
        }

        .social-connection-single-post-reply-header {
            gap: 9px;
            margin-bottom: 4px;
        }

        h5.social-connection-single-post-reply-username {
            font-size: 14px;
            line-height: 25px;
        }

        p.social-connection-single-post-reply-text {
            font-size: 13px;
            line-height: 14px;
        }

    /* Social Connection */
    /*  Add Post Page  */
    .social-connection-add-post-header {
        margin-bottom: 19px;
    }

    .social-connection-add-post-form-container {
        max-width: 100%;
        gap: 9px;
    }

    .social-connection-add-post-form-container form {
        gap: 9px;
        padding: 28px 14px;
    }

    .social-connection-add-post-form-input {
        padding: 9px;
        font-size: 13px;
        line-height: 14px;
    }

    .social-connection-add-post-form-input input {
        font-size: 9px;
        line-height: 14px;
        padding: 9px;
    }

    .social-connection-add-post-form-input textarea {
        font-size: 9px;
        line-height: 14px;
        padding: 9px;
    }

    .social-connection-add-post-disclaimer-text {
        margin: 0 9px;
    }

    p.disclaimer-text {
        font-size: 9px;
        line-height: 14px;
    }

    .btn-social-connection-add-post {
        line-height: 20px;
        padding: 4px 9px;
    }

    /* Continuing Education */
    /* Upcoming Webinars */
    h2.upcoming-webinars-header,
    h2.past-webinars-header,
    h2.my-registrations-header {
        font-size: 19px;
        margin-bottom: 14px;
    }

    .upcoming-webinars-container,
    .past-webinars-container {
        gap: 9px 9px;
    }

    .upcoming-webinars-no-webinars-container,
    .past-webinars-no-webinars-container,
    .my-registrations-no-webinars-container {
        height: 240px;
    }

    h3.upcoming-webinars-no-webinars-header,
    h3.past-webinars-no-webinars-header,
    h3.my-registrations-no-webinars-header {
        font-size: 16px;
    }

    .upcoming-webinar-item,
    .past-webinar-item {
        width: 210px;
        padding: 14px;
    }

    .upcoming-webinar-item-title-container,
    .past-webinar-item-title-container {
        min-height: 24px;
        max-height: 60px;
        margin-bottom: 9px;
    }

    h4.upcoming-webinar-item-title,
    h4.past-webinar-item-title {
        font-size: 16px;
    }

    h5.upcoming-webinar-item-info-title,
    h5.past-webinar-item-info-title {
        font-size: 13px;
    }

    .upcoming-webinar-item-description-container,
    .past-webinar-item-description-container {
        padding-bottom: 24px;
        min-height: 72px;
        max-height: 90px;
        margin-bottom: 9px;
    }

    p.upcoming-webinar-item-description,
    p.past-webinar-item-description {
        font-size: 13px;
        line-height: 16px;
        margin-bottom: 9px;
    }

    .upcoming-webinar-item-btn,
    .past-webinar-item-btn {
        padding: 6px 0 4px;
        font-size: 14px;
    }

    .upcoming-webinars-load-more-container,
    .past-webinars-load-more-container,
    .my-registrations-load-more-container {
        margin: 21px 0 0;
    }

    .upcoming-webinars-load-more-button,
    .past-webinars-load-more-button,
    .my-registrations-load-more-button {
        padding: 4px 12px 3px;
    }

    /* Upcoming Webinars - Modal */
    .upcoming-webinars-modal {
        max-width: 100%;
    }

    .upcoming-webinars-modal-title-container {
        margin-bottom: 16px;
    }

    h4.upcoming-webinars-modal-title {
        font-size: 16px;
    }

    .upcoming-webinars-modal-btn-close svg {
        font-size: 19px;
    }

    .upcoming-webinars-modal-container {
        padding: 28px;
    }

    h2.upcoming-webinars-modal-body-title {
        font-size: 22px;
    }

    .upcoming-webinars-modal-body-info-container {
        gap: 4px 9px;
        padding-top: 24px;
    }

    .upcoming-webinars-modal-body-info-item {
        width: 156px;
    }

    h5.upcoming-webinars-modal-body-title {
        font-size: 15px;
    }

    p.upcoming-webinars-modal-body-text {
        font-size: 13px;
    }

    .upcoming-webinars-modal-description-container {
        padding-bottom: 48px;
    }

    .upcoming-webinars-modal-btn {
        width: 126px;
        padding: 6px 0 4px;
    }

    /* Past Webinars */
    /* past webinars modal */
    .past-webinars-modal {
        max-width: 624px;
        height: 462px;
    }

    .past-webinars-modal-video-container {
        height: 352px;
    }

    .past-webinars-modal-video {
        height: 352px;
    }

    .past-webinars-modal-close-button {
        width: 21px;
        height: 21px;
        top: 9px;
        right: 9px;
    }

    .past-webinars-modal-close-button svg {
        width: 21px;
        height: 21px;
    }

    .past-webinars-modal-text-container {
        padding: 14px 19px;
    }

    h3.past-webinars-modal-title {
        font-size: 16px;
    }

    h5.past-webinars-modal-subtext {
        font-size: 12px;
        margin-bottom: 9px;
    }

    p.past-webinars-modal-text {
        font-size: 12px;
    }

    /*  My Registrations  */
    .my-registrations-item {
        margin-bottom: 14px;
    }

    h4.my-registrations-item-title {
        font-size: 16px;
        margin-bottom: 9px;
    }

    h5.my-registrations-item-info-title {
        font-size: 13px;
        max-width: 420px;
    }

    .my-registrations-item-description-container {
        min-height: 30px;
        max-height: 60px;
        margin-bottom: 9px;
    }

    p.my-registrations-item-description {
        font-size: 13px;
        line-height: 16px;
        margin: 9px 0;
    }

    .my-registrations-item-btn-container {
        padding-bottom: 19px;
    }

    .my-registrations-item-btn {
        padding: 6px 12px 4px;
        font-size: 15px;
    }

    /* My Registrations - Modal */
    .my-registrations-modal {
        max-width: 432px;
        margin: 0 auto;
    }

    h1.my-registrations-modal-title {
        font-size: 16px;
        margin-bottom: 14px;
    }

    .my-registrations-modal-container {
        padding: 19px;
    }

    p.my-registrations-modal-text {
        font-size: 15px;
    }

    .my-registrations-modal-btn-container {
        gap: 9px;
        padding-top: 18px;
    }

    .my-registrations-modal-btn {
        width: 126px;
        padding: 6px 0 4px;
        font-size: 12px;
    }

    /* Inbox */
    .inbox-header-container {
        margin-bottom: 14px;
    }

    h2.inbox-header-title {
        font-size: 19px;
    }

    .inbox-btn {
        padding: 7px 12px 6px;
    }

    .inbox-container {
        gap: 9px 9px;
    }

    .inbox-no-notifications-container {
        height: 240px;
    }

    .notification-item {
        padding: 14px 6px;
    }

    .notification-item-action-container {
        gap: 4px;
    }

    .notification-item-action-container svg {
        width: 16px;
        height: 16px;
    }

    h5.notification-item-title {
        font-size: 16px;
    }

    h5.notification-item-title.date {
        font-size: 13px;
    }

    .notification-item-title .modal-title {
        font-size: 16px;
    }

    p.notification-item-description,
    p.notification-item-description span {
        font-size: 13px;
    }

    .notification-item-btn {
        padding: 5px 12px 4px;
        font-size: 13px;
    }

    .inbox-load-more-container {
        margin: 21px 0 0;
    }

    .inbox-load-more-button {
        font-size: 13px;
        padding: 5px 12px 4px;
    }

    /* Inbox - Modal */
    .inbox-modal-body-container {
        padding: 6px 0 12px;
    }

    .inbox-modal-btn-container {
        gap: 9px;
    }

    /*  Consultation  */
    /* Consultation - Book a Consult */
    .consultation-header {
        font-size: 19px;
        margin-bottom: 19px;
    }

    .consultation-sub-header {
        margin-bottom: 16px;
        font-size: 16px;
    }

    .available-consultants-container {
        flex-direction: column;
        gap: 9px 9px;
    }

    .consultant-booking-item {
        width: 100%;
        min-height: 160px;
        padding: 14px;
        column-gap: 14px;
    }

    .consultant-booking-item .empty-profile-image {
        width: 75px;
        height: 75px;
    }

    .consultant-profile-img-container {
        width: 75px;
    }

    .consultant-name {
        font-size: 19px;
        max-width: 420px;
        margin-bottom: 2px;
    }

    .consultant-booking-name {
        font-size: 16px;
        line-height: 19px;
        max-width: 261px;
        max-height: 19px;
    }

    .consultant-booking-title {
        margin: 2px 0;
        max-height: 18px;
    }

    .consultant-booking-bio {
        font-size: 13px;
        line-height: 22px;
        max-height: unset;
        min-height: 50px;
        margin: 7px 0 14px 0;
    }

    .consultant-action-container {
        width: 180px;
        height: 30px;
        margin: 9px 0;
    }

    .btn-consultation-action {
        line-height: 22px;
    }

    /* Book a Consultant - Weekly View */
    .book-consultations-container.consultant-book-availability {
        padding-top: 19px;
    }

    .consultations-container {
        overflow: unset;
    }

    .btn-cbit-link.btn-return-consultant {
        font-size: 13px;
        line-height: 22px;
    }

    .consultant-availability-info {
        margin-top: 31px;
        gap: 24px;
    }

    .consultant-booking-bio-container {
        margin: 7px 0 7px 0;
    }

    .consultant-info-container-right .btn-cbit-link {
        font-size: 13px;
        margin-bottom: 4px;
    }

    .booking-availability-container {
        margin-top: 20px;
    }

    .booking-availability-controls {
        height: 20px;
    }

    .availability-date-range {
        font-size: 13px;
        line-height: 22px;
        padding: 0 9px;
    }

    .date-picker-btn-container,
    .btn-cbit-calendar-icon,
    .date-picker-btn-container input[type="date"] {
        width: 15px;
    }

    .date-picker-btn-container {
        margin-left: 9px;
    }

    .btn-consultation-calendar {
        width: 15px;
        margin-bottom: 3px;
    }

    .availability-date-header {
        height: 45px;
    }

    .day-of-the-week {
        font-size: 12px;
    }

    .availability-date {
        font-size: 14px;
    }

    .available-sessions-list {
        margin-top: 5px;
        gap: 6px;
    }

    .non-available-session-item {
        font-size: 12px;
        line-height: 22px;
    }

    /* Consultant Bio - Modal */
    h2.my-profile-description-name {
        font-size: 18px;
    }

    .my-profile-full-description {
        max-width: 100%;
        padding: 12px;
    }

    .booking-availability-bio {
        width: 100%;
        max-width: 540px;
        margin: 0 auto;
    }

    /* Availability Management */
    .availability-date-container {
        margin-bottom: 19px;
    }

    .calendar-date-range.availability-date-range {
        font-size: 16px;
        min-width: 171px;
    }

    .consultation-calendar-legend {
        gap: 9px;
        margin-bottom: 19px;
        font-size: 13px;
    }

    .consultation-legend-icon {
        height: 6px;
        width: 6px;
        margin-right: 6px;
    }

    .btn-cbit-icon.btn-availability-control {
        width: 20px;
        height: 22px;
    }

    .btn-cbit-icon.btn-availability-control img {
        width: 8px;
        height: 8px;
    }

    .toastui-calendar-month .toastui-calendar-month-daygrid {
        font-size: 12px !important;
    }

    .toastui-calendar-grid-cell-date .toastui-calendar-weekday-grid-date.toastui-calendar-weekday-grid-date-decorator {
        height: 20px;
        line-height: 20px;
        width: 20px;
    }

    /* Availability Management - Modal */
    .availability-management-modal {
        width: 90% !important;
        margin: 0 auto;
    }

    .custom-modal {
        width: unset;
        max-width: 100%;
    }

    .consultation-plan-form {
        width: 100%;
        margin: 0 auto;
    }

    .form, .cbit-dialog {
        padding: 24px 19px 24px;
    }

    .cbit-dialog {
        padding-bottom: 19px;
    }

    .custom-modal .btn-cbit-icon {
        top: 10px;
        right: 10px;
        width: 10px;
    }

    h2.form-title, .cbit-header {
        font-size: 17px;
        margin: 0 0 14px;
    }

    .cbit-column {
        margin: 0 5px;
        white-space: nowrap;
    }

    .form-label, .cbit-label {
        font-size: 15px;
        white-space: nowrap;
    }

    .consultation-plan-form .form-input {
        height: 34px;
        font-size: 14px;
    }

    .consultation-plan-form .form-input,
    .consultation-plan-textarea-container {
        margin-bottom: 14px;
    }

    .text-character-limit-container {
        font-size: 15px;
    }

    .session-info,
    .consultation-type {
        display: none;
    }

    .mobile-session-info,
    .mobile-consultation-type {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .mobile-session-info-container {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .session-time-to-text {
        font-size: 15px;
        height: 36px;
    }

    .recurring-plan-container {
        gap: 9px;
    }

    .recurring-repeat-container {
        margin-bottom: 14px;
    }

    .recurring-note {
        font-size: 13px;
    }

    .upload-text {
        font-size: 13px;
        padding-bottom: 6px;
    }

    .plan-button-group {
        padding-top: 7px;
    }

    .consultation-plan-form .btn-cbit-primary,
    .consultation-plan-form .btn-cbit-minor {
        height: 30px;
        width: 115px;
    }

    .plan-button-group .btn-cbit-primary {
        margin-left: 9px;
        width: 115px;
    }

    /* Consultation Info Modal */
    .consultation-plan-form.consultation-info {
        width: 100%;
        padding: 24px 19px;
    }

    .consultation-info .view-details-text-container {
        margin-bottom: 14px;
    }

    .btn-cbit-text,
    .btn-cbit-link {
        font-size: 13px;
    }

    .consultation-info {
        font-size: 13px;
    }

    /* Consultation Info - Delete Modal */
    .custom-alert {
        padding: 24px 19px 24px;
        width: 100%;
    }

    .custom-alert-header {
        font-size: 17px;
        line-height: 28px;
        padding-bottom: 14px;
    }

    .custom-alert-text {
        font-size: 15px;
    }

    .btns-custom-alert-container {
        padding-top: 14px;
    }

    .custom-alert .btn-cbit-minor {
        min-width: 90px;
        height: 32px;
        margin-right: 9px;
    }

    .custom-alert .btn-cbit-primary {
        min-width: 120px;
        height: 32px;
    }

     .modal-content {
        width: 90%;
        margin: 0 auto;
    }

    /* Upcoming Consultations */
    .consultation-filters {
        margin-bottom: 24px;
        gap: 9px;
    }

    .form-input {
        padding: 4px 8px;
        font-size: 13px;
    }

    .upcoming-consultations {
        font-size: 14px;
    }

    .session-item {
        min-height: 200px;
        gap: 19px;
        margin-bottom: 20px;
    }

    .consultant-info-container-left.session-profile-icon {
        max-width: 75px;
    }

    .consultation-action-container {
        gap: 9px;
        margin-bottom: 16px;
    }

    .btn-consultation-edit {
        width: 130px;
        height: 30px;
    }

    .btn-consultation-delete {
        height: 30px;
        width: 100px;
    }

    /* My Previous Sessions */
    #previous-sessions.consultation-filters {
        max-height: 28px;
        gap: 9px;
        margin-bottom: 60px;
    }

    .consultation-filters-container {
        max-width: 100%;
        gap: 9px;
    }

    .consultation-filter-select {
        max-width: 204px;
    }

    #previous-sessions .consultation-filter-select {
        max-width: 160px;
    }

    #previous-sessions .empty-session-toggle {
        gap: 9px;
        margin-bottom: 14px;
    }

     #previous-sessions .empty-session-toggle span {
        font-size: 12px;
        max-width: 160px;
    }

     #previous-sessions .toggle-switch {
        width: 40px;
    }

    .btn-consultation-minor.btn-cbit-minor {
        font-size: 14px;
        line-height: 22px;
        height: 30px;
        width: 175px;
    }

    /* My Previous Sessions - Review Modal */
    .consultation-review-form {
        width: 100%;
    }

    .consultation-review-form p {
        font-size: 15px;
    }

    .review-btn-container {
        margin-bottom: 9px;
    }

    .review-btn-container span {
        margin-right: 6px;
    }

    .rating-label {
        font-size: 1.3rem;
    }

    .rating-container {
        margin-bottom: 8px;
    }

    .review-user-profile {
        max-width: 420px;
        max-height: 360px;
    }

    .form-btn-container .btn-cbit-primary {
        width: 90px;
        font-size: 15px;
    }

    #cbit-review-textarea.cbit-column {
        white-space: unset;
    }

    /* My Profile */
    .consultant-image-container {
        gap: 18px;
        margin-bottom: 19px;
    }

    .consultant-my-profile-image-container,
    .consultant-image-container .empty-profile-image {
        width: 96px;
        height: 96px;
    }

    .empty-profile-image {
        font-size: 11px;
    }

    .consultant-image-container .btn-cbit-minor {
        width: 130px;
        height: auto;
        font-size: 13px;
        padding: 4px 0;
    }

    .consultant-profile-container {
        max-width: 100%;
    }

    .consultant-profile-container p {
        height: 32px;
        font-size: 13px;
    }
    .consultant-profile-container .form-input {
        height: 34px;
    }

    .consultant-profile-container #bio{
        height: 100px;
    }

    .consultant-profile-container .cbit-btn-container {
        margin-top: 24px;
    }

    .consultant-profile-container .cbit-btn-container button {
        width: 96px;
        height: 30px;
    }

    .btn-remove-image {
        height: auto;
    }

    .consultant-upload-container,
    .consult-profile-name-container.cbit-row {
        gap: 9px;
    }

    .consultant-bio-description.form-input {
        margin-bottom: 4px;
    }

    .profile-desktop {
        display: none;
    }

    .mobile-profile-name {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .mobile-form-container {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    /* Family Support */
    .category-title-container {
        gap: 18px;
    }

    h2.category-title {
        font-size: 19px;
        margin-bottom: 19px;
    }

    .SupportContent-title {
        margin-bottom: 6px;
        font-size: 15px;
        letter-spacing: 0.5px;
    }

    /* Family Support - Admin */
    .drag-handle,
    .drag-handle svg {
        height: 24px;
        width: 8px;
    }

   .inline-basic-input {
        max-width: 100% !important;
    }

    .therapist-panel-btn-container button {
        margin-right: 6px;
    }

    .standard-content-btn-container {
        min-height: 24px;
    }

    .nav-sidebar .standard-content-btn-container {
        padding: 6px 14px 6px 14px;
        bottom: 4px;
    }

    .admin-content-menu-item,
    .admin-content-menu-item-loading {
        width: 196px;
        height: 50px;
        margin: 0 auto;
    }

    .plus-icon {
        margin: 9px;
        width: 32px;
        height: 32px;
    }

    .plus-icon svg {
        width: 32px;
        height: 32px;
    }

    .admin-content-menu-item-subheading {
        font-size: 15px;
        margin: 5px 0;
        height: 18px;
    }

    .btn-tag-add {
        margin-top: 7px;
        height: 14px;
    }

    .tag-add-text {
        font-size: 15px;
        line-height: 0.8em;
    }

    .admin-chapter-text {
        font-size: 18px;
        letter-spacing: 0.6px;
    }

    .admin-content-body {
        margin-left: 12px;
    }

    .admin-content-container {
        margin: 12px 0;
    }

    .admin-support-content {
        font-size: 12px;
    }

    /* Family Support - Admin: Modal - Edit */
    .additional-content-form {
        width: 100%;
        padding: 18px !important;
    }

    .additional-content-form .btn-cbit-primary {
        width: 96px;
        height: 30px;
    }

    /* Admin Tools */
    .admin-tools {
        padding: 12px;
    }

    .admin-tools-inner {
        flex-direction: column;
        margin-top: 9px;
        gap: 12px;
    }

    .admin-tools-item {
        width: 300px;
    }

    .admin-tools-item,
    .admin-tools-item a {
        min-height: 100px;
    }

    .admin-tools-item a {
        padding: 12px;
    }

    .admin-tool-header {
        font-size: 16px;
    }

    .admin-tool-body {
        font-size: 13px;
    }

    /* Therapist Support */
    .tag-item {
        padding-bottom: 20px;
        margin-bottom: 24px;
    }

    h4.tag-title {
        margin-bottom: 10px;
        font-size: 15px;
    }

    .tag-content-container {
        gap: 9px 9px;
    }

    .therapist-content-item {
        height: 78px;
        min-height: 39px;
        max-height: 78px;
        min-width: 274px;
        max-width: 274px;
        padding: 4px 5px 3px 5px;
    }

    .therapist-content-item-info {
        width: 24px;
    }

    .therapist-icon-container {
        width: 21px;
    }

    .content-item-duration {
        margin-top: 4px;
        font-size: 13px;
    }

    .therapist-content-title {
        font-size: 14px;
        line-height: 18px;
    }

    .tag-item .plus-icon {
        height: 14px;
        width: 14px;
    }

    .therapist-support-modal {
        position: relative;
        width: 100%;
        max-width: 384px !important;
        overflow: hidden;
    }

    .therapist-player,
    .react-player {
        width: 100% !important;
        height: 216px !important;
        max-width: 384px !important;
    }

     .therapist-player iframe {
         height: 216px !important;
         width: 100% !important;
         max-width: 384px !important;
    }

    .react-player-title {
        font-size: 14px;
    }

    .react-player-title-container {
        padding: 12px 16px;
    }

    .therapist-player-title-container {
        padding: 12px 16px;
    }

    .therapist-player-title {
        font-size: 14px;
    }

    .therapist-content-description {
        max-width: 274px;
    }

    h2, .h2 {
        font-size: 19px;
    }

    /* Therapist Support - Admin */
    .refresh-container {
         justify-content: flex-start;
         margin-bottom: 16px;
    }

    .admin.therapist-content-item .therapist-content-title {
        width: 172px;
        max-width: 172px;
    }

    .therapist-content-item .drag-handle {
        margin-right: 10px;
    }

    .therapist-content-item .therapist-panel-btn-container {
        gap: 10px;
        margin-left: 14px;
    }

    .category-title-container.main-category-container {
        gap: 5px;
        flex-direction: column;
    }

    /* Referral */
    /* Referral Modal */
    #referral-alert {
        width: 100%;
        max-width: 384px;
        margin: 0 auto;
    }

    .referral-alert {
        width: 100%;
        margin: 0 auto;
    }

    .cbit-dashboard-header {
        font-size: 19px;
        margin-bottom: 19px;
    }

    .referral-alert p {
        font-size: 13px;
        margin-bottom: 24px;
    }

    .cbit-dialog-btn-container,
    .cbit-btn-container {
        gap: 9px;
    }

    .cbit-dialog-btn-container .btn-cbit-primary,
    .cbit-dialog-btn-container .btn-cbit-minor {
        height: 32px;
        font-size: 15px;
        line-height: 24px;
        letter-spacing: 1.2px;
    }

    /* Referral Profile */
    .referral-profile-body {
        flex-direction: column;
        gap: 28px;
    }

    .referral-image-container,
    .referral-image-container .btn-cbit-minor,
    .referral-details .referral-image-container,
    .referral-details .referral-image-container .empty-image-container {
        width: 115px;
    }

    .referral-details .referral-image-container .empty-image-container {
        height: 115px;
    }

    .referral-image-container .upload-btn-container {
        margin-bottom: 7px;
    }

    .upload-btn-container {
        height: 34px;
        letter-spacing: 1.2px;
    }

    .referral-image {
        height: 115px;
    }

    .empty-image-container,
    .referral-profile .empty-image-container {
        width: 115px;
        height: 115px;
    }

    .referral-form {
        width: 100%;
    }

    .referral-container {
        gap: 20px;
    }

    .cbit-form-group {
        margin-bottom: 9px;
    }

    .cbit-column.referral-address-field {
        margin-bottom: 9px;
    }

    .user-form-checkbox {
        margin-bottom: 9px;
    }

    .user-form-checkbox-container .checkbox-label-cbit {
        font-size: 15px;
    }

    .checkbox-label-cbit input {
        margin-right: 7px;
    }

    .form-btn-container {
        gap: 9px;
    }

    /* Referral */
    /* View Referral List */
    .referral-users-container {
        padding: 0 0 14px 0;
    }

    .referral-users-container .cbit-dashboard-header {
        font-size: 16px;
    }

    .referral-users-container .cbit-row {
        gap: 9px;
    }

    .filter-age-group .user-form-checkbox {
        margin-right: 9px;
    }

    .checkbox-label-cbit {
        font-size: 13px;
    }

    .referral-state-row.cbit-row {
        flex-direction: column;
    }

    .checkbox-cbit {
        height: 13px;
        width: 13px;
    }

    .referral-address-legend {
        gap: 12px;
        margin-bottom: 9px;
        font-size: 13px;
    }

    .referral-map-popup {
        max-width: 121px;
        max-height: 66px;
        font-size: 12px;
    }

    .referral-map-popup .cbit-small-header {
        font-size: 12px;
    }

    .referral-user-item {
        gap: 9px;
        margin-bottom: 24px;
    }

    .referral-user-item-profile .referral-image-container {
        width: 36px;
    }

    .referral-user-item-profile .referral-image-container .referral-image {
        height: 36px;
    }

    .referral-user-item-name.btn-cbit-text {
        font-size: 15px;
    }

    .referral-user-marker-container {
        width: 10px;
    }

    .referral-user-item-bio {
        font-size: 13px;
    }

    .referral-user-item-btn-container {
        margin-top: 10px;
    }

    .referral-user-item-btn-container .btn-cbit-minor {
        height: 26px;
        width: auto;
        font-size: 13px;
    }

    /* Referral */
    /* View Referral List - Modal */
    .cbit-small-header {
        font-size: 16px;
    }

    .referral-details {
         width: 100%;
    }

    .referral-details .referral-info-container {
        width: 100%;
        gap: 9px;
    }

    .referral-bio-container p {
        font-size: 13px;
    }

    #referralUserMap {
        height: 115px;
    }

    /* Training */
    /* Layout */
    .nav-menu-icon {
        width: 34px;
        height: 13px;
        margin-top: 18px;
        margin-right: 16px;
    }

    .AccordionContent {
        width: 100vw !important;
    }

    .MainContent {
        height: calc(100vh - 64px);
    }

    .Viewer-Content {
        height: calc(100vh - 64px);
        top: calc(calc(100vh - 64px) * -1);
    }

    .Menu-Right-Bar {
        width: 217px;
        max-width: 217px;
        right: -217px;
        height: calc(100vh - 64px);
    }

    .isOpen .Viewer-Content {
        height:calc(100vh - 64px);
    }

    .isOpen .MainContent {
        height: calc(100vh - 64px);
    }

    .menuTab .buttonIcon {
        margin: -3px 1px 1px 7px;
        font-size: 11px;
    }

    .buttonIcon {
        margin: -8px 2px 3px 7px;
        font-size: 11px;
    }

    .btn-cbit-minor, .btn-cbit-primary {
        letter-spacing: 0;
    }

    .isOpen .menuTab {
        right: -128px;
    }

    #accordion-tab,
    .menuTab {
        top: 10px;
        height: 57px;
        width: 29px;
    }

    /* Training */
    /* NavSidebar */
    .navigation-section-label {
        padding-left: 18px;
        padding-top: 68px;
        font-size: 26px;
    }

    .accordion-body {
        height: calc(100vh - 150px);
    }

    .accordion-item-header-chapter {
        height: 50px;
    }

    .accordion-item-header-chapter-lesson {
        height: 50px;
    }

    .accordion-item-arrow {
        width: 12px;
        height: 50px;
    }

    .orange-selected {
        height: 50px;
    }

    .admin-chapter-item {
        min-height: 50px;
    }

    .admin-lesson-item {
        padding-left: 18px;
        min-height: 50px;
    }

    .lesson-type-icon {
        width: 22px;
        margin-right: 12px;
    }

    .lesson-type-icon img {
        height: 22px;
        width: 22px;
    }

    .admin-lesson-text {
        width: 100% !important;
        max-width: 220px !important;
    }

    .admin-lesson-duration {
        font-size: 12px;
    }

    .sub-lesson-item {
        padding-left: 28px;
    }

    .admin-lesson-group-items .admin-lesson-content {
        padding-left: 17px;
        min-height: 27px;
    }

    .admin-lesson-text {
        font-size: 16px;
        padding: 5px 0;
    }

    /* Training */
    /* Viewer Content */
    .video-player-background {
        min-height: 300px;
    }

    .learning-content-view-panel {
        height: calc(100vh - 64px);
    }

    .video-content-panel-header {
        margin: 12px 19px 9px;
        gap: 2px;
    }

    .video-text-title {
        max-width: 100%;
        font-size: 20px;
        line-height: 28px;
    }

    .Left-Buffer-24 {
        padding-left: 14px;
    }

    .btn-cbit,
    .btn-disabled {
        min-height: auto;
        font-size: 14px;
        letter-spacing: 0.6px;
    }

    .btn-primary,
    .btn-secondary {
        font-size: 14px;
        letter-spacing: 0.6px;
    }

    .video-text-body {
        font-size: 13px;
        padding: 0 8px;
    }

    /* Training */
    /* Additional Content */
    .addVideoName {
        margin-left: 19px;
        margin-top: 19px;
        font-size: 13px;
        letter-spacing: 1.8px;
        max-width: 144px;
    }

    .add-video-separator {
        width: 168px;
        margin-left: 19px;
        margin-bottom: 10px;
    }

    .addVideo-box {
        margin-left: 19px;
        width: 168px;
    }

    .addVideo-text {
        margin-left: 19px;
        margin-bottom: 14px;
    }

    .addVideo-titleText {
        font-size: 15px;
    }

    .addVideo-lengthText {
        font-size: 11px;
    }

    .no-content-additional-video-container {
        margin-left: 19px;
        width: 168px;
    }

    .no-content-image-container {
        width: 72px;
    }

    .no-content-text {
        font-size: 13px;
    }

    /* Training */
    /* Quiz, Combo, Text & Video Lessons */
    .quiz-content-panel,
    .combo-lesson-quiz-content-panel {
        margin-top: 39px;
        margin-left: 48px;
        margin-right: 48px;
    }

    .quiz-title,
    .combo-lesson-quiz-title {
        font-size: 19px;
        margin-bottom: 19px;
    }

    .quiz-instruction-text {
        font-size: 13px;
        margin-bottom: 19px;
    }

    .quiz-question-text {
        font-size: 13px;
        margin-bottom: 24px;
    }

    .quiz-question-title-seperation {
        margin-bottom: 9px;
    }

    .quiz-question-left-margin {
        margin-left: 9px;
    }

    .quiz-question-seperation {
        margin-left: 19px;
        margin-bottom: 9px;
    }

    .radioButtonAnswerAdjustment {
        margin-left: -22px;
    }

    .radioDesignationAddjustment {
        margin-right: 9px;
    }

    .radioTextAdjustment {
        margin-left: 9px;
    }

    .pdf-toolbox-box .addVideo-box {
        width: 168px;
        height: 90px;
    }

    .textLessonImage {
        margin-bottom: 19px;
    }

    p {
        font-size: 13px;
    }

    /* My Profile */
    .my-profile-inner {
        flex-direction: column;
    }
    .my-profile-container {
        width: 90%;
        max-width: 480px;
        min-height: 464px;
    }

    .my-profile-container .form {
        min-height: 464px;
    }

    .my-profile-container .form-textarea {
        min-height: 69px;
    }

    .my-profile-container .upload-btn-container {
        height: 32px;
        padding: 0 6px;
        letter-spacing: 1.2px;
    }

    .my-profile-upload-container {
        display: flex;
        flex-direction: column;
        height: auto;
        margin-bottom: 9px;
    }

    .my-profile-image-container {
        height: 75px;
        width: 75px;
        margin-bottom: 10px;
    }

    .profile-image-container {
        height: 75px;
        width: 75px;
    }

    .profile-image-upload-container {
        margin-left: 9px;
        width: 100%;
        max-width: 330px;
    }

    .profile-image-upload-container label {
        margin-right: 9px;
    }

    .profile-image-text {
        font-size: 15px;
    }

    .my-profile-container .btn-cbit-icon {
        height: 15px;
        width: 15px;
    }

    .my-profile-container .cbit-row {
        margin: 8px 0;
        max-height: 48px;
    }

    .my-profile-container .cbit-col:first-child {
        padding-right: 18px;
    }

    .my-profile-input,
    .my-profile-text {
        font-size: 15px;
    }

    .cbit-row.isEditing {
        margin-bottom: 20px;
    }

    .my-profile-text {
        padding: 3px 0;
    }

    .my-profile-container .my-profile-description-container {
        height: 93px;
        min-height: 93px;
    }

    .my-profile-description {
        height: calc(100% - 24px);
    }

    .submit-btn-container {
        margin: 14px 0 0 !important;
    }

    .submit-btn-container .btn-cbit-primary {
        height: 32px;
        width: 86px;
    }

    /* Password Reset */
    .password-reset-form .btn-cbit-primary {
        height: 32px;
        width: 86px;
    }

    .login-form .btn-cbit-primary,
    .forgot-form .btn-cbit-primary {
        width: 86px;
        height: 32px;
    }

    /* Sign Up */
    .user-form .btn-cbit-primary {
        width: 86px;
        height: 32px;
        margin-top: 9px;
    }

    /* Content Builder */
    .admin-dashboard {
        height: calc(100vh - 64px);
    }

    /* Expired Subscription*/
    .expired-sub.cbit-container {
        margin: 0;
    }

}