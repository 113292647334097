.attendance-view {
    max-height: 700px;
    overflow-y: auto;
}

.attendance-view-item {
    display: flex;
    /* justify-content: space-between; */
    gap: 20px;
}

.attendance-view-item .btn-cbit-link {
    width: 200px;
    text-align: left;
}


.attendance-view-item .attendance-label {
    padding: 0;
    border: none;
    pointer-events: none;    
}

.review-view-report .report-label {
    color: var(--cbit-coral-red);
    cursor: default;
}