.book-consultations-container.consultant-book-availability {
    padding-top: 32px;
}

.consultant-book-availability .empty-profile-image {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    background-color: var(--cbit-pastel-blue);
}

.consultant-book-availability .consultant-booking-name {
    font-size: 32px;    
    letter-spacing: 0;
    font-weight: normal;
}

.consultant-booking-bio-container {
    margin: 12px 0 12px 0;
}


.availability-booking-bio.consultant-booking-bio {
    margin: 0;
}


.btn-cbit-link.btn-return-consultant {
    font-size: 16px;
    line-height: 28px;
    font-weight: normal;
    text-transform: capitalize;
}

.btn-cbit-link.btn-return-consultant:hover {
    color: var(--cbit-blue)
}

.consultant-availability-info {
    height: auto;    
    margin-top:52px;
    display: flex;
    gap: 40px;

}

.consultant-info-container-right .btn-cbit-link {
    margin-bottom: 8px;
}

.consultant-profile-img-container.availability-profile-container {
    width: 160px;
    background-color: aliceblue;
}

.booking-availability-container {
    
    /* height: 100%; */
    width: 100%;
    margin-top: 30px;
}

.booking-availability-controls {
    height: 32px;
    display: flex;
    align-items: center;
}

.btn-cbit-icon.btn-availability-control {
    background-color: var(--cbit-blue);
    width: 30px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-availability-control:hover.btn-cbit-icon:hover {
    background-color: var(--cbit-dark-blue);
    border-color: var(--cbit-dark-blue);
}

.btn-cbit-icon:disabled.btn-availability-control:disabled {    
    background-color: var(--cbit-crystal-disabled);
    border-color: var(--cbit-crystal-disabled);    
}

.btn-cbit-icon.btn-availability-control img { 
    width: 100%;
}

.availability-date-range {
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
    padding: 0 16px;
    color: var(--cbit-black);
}

.btn-consultation-calendar {
    width: 25px;    
    
}

.btn-consultation-calendar img {
    width: 100%;
}

.availability-dates {
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-top: 8px;
    /* background-color: var(--cbit-light-pastel-blue); */
    /* height: 75px;     */
}

.availability-sessions {
    background-color: green;
    height: 600px;
    margin-top: 11px;
}

.availability-date-item {
    width: 100%;
}

.availability-date-header {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;    
    color: var(--cbit-dark-blue);
    text-transform: uppercase;
    height: 75px;
    width: 100%;
    background-color: var(--cbit-light-pastel-blue);
}

.day-of-the-week {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
}

.availability-date {
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
}

.available-sessions-list {
    margin-top: 11px;
    display: flex;
    flex-direction: column;
    gap:12px;
}

.availability-date-item:not(:last-child) .available-sessions-list { 
    padding-right: 6px;
}


.availability-date-item:not(:first-child) .available-sessions-list {
    padding-left: 6px;
}


.available-consultation-type {
    font-size: 18px;
    line-height: 34px;
    letter-spacing: 1.8px;
    font-weight: bold;    
}

/**
DatePicker calendar button
**/
.date-picker-btn-container {
    position: relative;
    margin-left: 16px;
    display: inline-block;
      
}

.date-picker-btn-container, 
.btn-cbit-calendar-icon, 
.date-picker-btn-container input[type="date"] {
    width: 25px;
    
}

.booking-date-picker {
    width: 100%;
    height: 100%;
}


.date-picker-btn-container input[type="date"] {
    position: absolute;
    opacity: 0;    
}
.date-picker-btn-container input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: auto;
    width: auto;
    opacity: 0;
    cursor: pointer;
    background-color: transparent;
    color: transparent;
}

/**
Consultation color types
**/
.group-color {
    color: var(--cbit-orange);
}

.individual-color {
    color: var(--cbit-dark-blue);
}

.seminar-color {
    color: var(--cbit-yellow-green);
}
