.attendance-form {
    overflow: auto;
}

.attendance-form .error-message-container {
    margin-top: 0;
    margin-bottom: 16px;
}

.attendance-legend i {
    font-size: 20px;    
}

.attendance-legend .present-icon,
.attendance-legend .report-icon, 
.present-label{
    color: var(--cbit-yellow-green);
}

.attendance-legend .absent-icon,
.absent-label {
    color: var(--cbit-coral-red);
}

.attendance-legend .late-icon,
.late-label {
    color: var(--cbit-orange);
}

.attendance-list {
    display: flex;
    flex-direction: column;
    gap:20px;
}

.attendance-list-item {
    display: flex;
    justify-content: space-between;    
    align-items: center;
    gap:1.25rem;
}

.attendance-actions {
    display: flex;    
}

.attendance-actions input,
.attendance-report {
    -webkit-appearance: none;
    appearance: none;
}

.attendance-label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 40px;
    text-align: center;
    border: 1px solid var(--cbit-light-gray);
}

.attendance-label,
.report-label {
    font-size: 25px;
    cursor: pointer;    
}

.report-label {
    color: var(--cbit-cadet-blue);
}

.report-label:hover {
    color:var(--cbit-coral-red);
}

.attendance-label:first-of-type {
    border-radius: 8px 0 0 8px;
}

.attendance-label:last-of-type {
    border-radius: 0 8px 8px 0;
}

.attendance-label:hover{
    color: #fff;
}

.attendance-label.present-label:hover,
.attendance-input:checked + .present-label {
    color: #fff;
    background-color: var(--cbit-yellow-green);
    border-color: var(--cbit-yellow-green);;
}

.attendance-label.absent-label:hover,
.attendance-input:checked + .absent-label {    
    color: #fff;
    background-color: var(--cbit-coral-red);
    border-color: var(--cbit-coral-red);
}

.attendance-label.late-label:hover,
.attendance-input:checked + .late-label {    
    color: #fff;
    background-color:var(--cbit-orange);
    border-color: var(--cbit-orange);
}

.attendance-input:checked + .report-label,
.consultation-calendar-legend  .report-icon {
    color: var(--cbit-coral-red);
}
