.module-content-item {
  cursor: pointer;
}

.content-item {
  /* width: 100%; leaking outside of admin view panel */
  width: auto;
  border: 1px solid var(--cbit-cadet-blue);
  border-radius: 4px;
  padding: 30px 24px;
  background-color: #ffffff;
  display: flex;
  align-items: center;  
}

.content-item:hover,
.content-item-dragging,
.content-item-expanded,
.empty-module-item {
  background-color: #ECFCFF;
}

.content-item-dragging {
  height: auto !important;
}

.content-item-container {
  height: 35px;
}
.content-item-expanded {  
  height: auto;  
  flex-wrap: wrap;
  /* display: inline-block;   */
}

.content-item-title {  
  float: left;
  margin: auto 0 auto 0;
  font: normal normal normal 24px/32px 'Open Sans';
  color: var(--cbit_black);    
  max-width: 597px;

}

.drag-handle + .content-item-title {
  margin: auto 0 auto 28px;
}

.content-item-type {  
  float: left;
  margin: 0 24px;
  height: 28px;
  width: 28px;  
  vertical-align: middle;
}

.content-item-type img {
  width: 100%;
}

.content-item-options {
  padding: 0;
  margin: 0 0 0 auto;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;  
  float: right;
  list-style: none;
  align-items: center;  
  color: var(--cbit-bondi-blue);
}

.content-item-options li {
  float: right;
  margin-right: 24px;
}

.content-item-options li:first-child {
  margin-right: 0;
}

.content-item .bi-trash {
  color: var(--cbit-orange);
}

.content-item .bi-caret-down-fill,
.content-item .bi-caret-left-fill {
  color: black;
}

.drag-handle {
  float: left;
  height: 35px;
  width: 13px;
  cursor: grab;
}

.drag-handle img {
  width: 100%;
  height: auto;
}

/*
    Makes an image take the full height of its parents div
*/
@supports (object-fit: cover) {
  .drag-handle img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.empty-option {
  display: inline-block;
  height: 22px;
  width: 12px;  
}

.content-item-title,
.content-item-options li {
  cursor: pointer;
}

.lesson-content-duration {
  color: var(--cbit-black) !important;
  font-size: 16px;  
  cursor: default !important;
  width: 41px;
  height: 22px;
  margin: 0;
}

.no-click-allowed {
  cursor: not-allowed;
}

.lesson-group-amount {
  text-align: right;
}

.content-action-icon {
   width: 24px;
}

.content-action-icon-visibility {
  width: 30px;
  height: 24px;
}

.content-action-icon img,
.content-action-icon-visibility img {
  width: 100%;
}