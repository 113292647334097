/*FULL SIZE*/
.dashboard-body{
    background:white;
    display:inline-flex;
    flex-direction:row ;
    flex-wrap:nowrap;
    width:calc(100vw);
}
.accordion-panel{
    flex-grow:1;
    max-width:400px;
    height:100%;
    background:#00516B;
}
.view-panel{
    flex-grow:1;
    width:auto;
    height:calc(100vh - 80px);
    background:#FFFFFF;
    overflow-y:scroll;
}
.additional-videos{
   flex-grow:1;
   max-width:400px;
}

/**
Flex 
 */
.AdditionalVideoContainer
{
    padding:0;
    overflow:hidden;
    position:relative;
}



.MainContent
{
    position:relative;
    height:calc(100vh - 80px);
    width:calc(100vw);
    overflow-y:hidden;
}
.isOpen .MainContent
{
    height:calc(100vh - 80px);
    width:calc(100vw - 362px);
    overflow-y:hidden;
}

/*scaleX( calc( calc(100vw - 400px) / calc(100vw - 800px)))*/

.AdditionalContentContainer
{
    height:100%;
    overflow-y:scroll;
    box-shadow: -3px 3px 6px #00000029;
}

.Menu-Right-Bar
{
    position:absolute;
    background:var(--cbit-white);
    top: 0;
    left: calc(100vw);
    height:calc(100vh - 80px);
    width:362px;
    max-width:362px;
    visibility: hidden;
    
    transition: all 0.1s linear 0s;
    transform: translate3d(0, 0px, 0px);

}
.isOpen .Menu-Right-Bar
{
    transition: transform 0.1s linear 0s;
    transform: translate3d(-100%, 0px, 0px);
    visibility: visible;
}

.AccordionContent
{
    position:relative;
    width:400px;
    background:lightblue;
    height:100%;
}
.Viewer-Content
{
    position:relative;
    height:calc(100vh - 80px);
    top:calc(calc(100vh - 80px)*-1);
    left:400px;
    background:white;
    width:calc(100vw - 400px);
    overflow-y: scroll;
}
.isOpen .Viewer-Content
{
    position:relative;
    height:calc(100vh - 80px);
    left:400px;
    background:white;
    width:calc(100vw - 762px);
    overflow-y: scroll;
}

.isNavOpen .AccordionContent {
    transition: all 0.1s linear 0s;
    transform: translate3d(0, 0px, 0px);
}

.buttonIcon{
    transform:rotate(90deg) scale3d(4,2,1);
    --webkit-transform:rotate(90deg) scale3d(4,2,1);
    transition: all 0s;
    margin: 5px 0 0 2px;
}

.menuTab,
.accordMenuTab
{
    position: absolute;
    top:40px;
    height:96px;
    width:48px;
    /*left:calc(100vw - 65px);*/
    right: 0;
    box-shadow: 0 3px 6px #00000029 !important;
    border-radius:10px 0 0 10px !important;

    z-index:10;
    background: var(--cbit-blue) !important;

    --webkit-transform: translate3d(-0px,0px,0px);
    transform:translate3d(-0px,0px,0px);
    transition: all 0.1s linear 0s;
    -webkit-transition: all 0.1s linear 0s;
}
.isOpen .menuTab
{
    --webkit-transform: translate3d(-345px,0px,0px);
    transform: translate3d(-345px,0px,0px);
    right: 17px;
}
.menuTab:hover
 {
     background: var(--cbit-bondi-blue) !important;
 }










.FirstBox
{
    display:inline-flex;
    width:50%;
    height:calc(100vh - 80px);
    background:#00516B;
    --webkit-transform: translate3d(-0px,0px,0px);
    transform:translate3d(-0px,0px,0px);
    transition: all 0.5s ease 0s;
    -webkit-transition: all 0.5s ease 0s;
}

.isClosed .FirstBox
{
    --webkit-transform: translate3d(400px,0px,0px);
    transform: translate3d(400px,0px,0px);
}

.SecondBox
{
    overflow-y:auto;
    
    display:inline-flex;
    width:50%;
    height:calc(100vh - 80px);
    background:lightcoral;
    --webkit-transform: translate3d(-0px,0px,0px);
    transform:translate3d(-0px,0px,0px);
    transition: all 0.5s ease 0s;
    -webkit-transition: all 0.5s ease 0s;
}
.isClosed .SecondBox
{
    --webkit-transform: translate3d(400px,0px,0px);
    transform: translate3d(400px,0px,0px);
}


.ThirdBox{
   
    width:400px;
    background-color:lightcoral;
    height:calc(100vh - 80px);
    overflow-y:scroll;
}
.Container-Body
{
    background-color:rebeccapurple;
    height:calc(100vh - 80px);
    
}
.InnerContainer-Body
{
    display:inline-block;
    background-color:lightgreen;
    width:calc(100% - 400px);
    height:calc(100vh - 80px);
}

/* Accordion Sidebar */
.navigation-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/*.nav-menu-icon-container {*/
/*    position: relative;*/
/*    display: inline-flex;*/
/*    width: 34px;*/
/*    height: 13px;*/
/*    margin-top: 18px;*/
/*    margin-right: 16px;*/
/*}*/

/* Hiding element until 1023 px */
.nav-menu-icon-container {
    display: none;
}

.nav-menu-icon {
    width: 34px;
    height: 13px;
    margin-top: 18px;
    margin-right: 16px;
}

.learning-content-loading {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.training-according-loading {
    padding: 1rem;
}