/* .consultation-attendees-container {
    max-height: 120px;
    overflow: auto;
    margin-bottom: 16px; 
} */

.consultation-plan-form .attendees-list{
    padding: 0;
}

.consultation-plan-form .attendees-list-item {
    margin-bottom: 8px;
 }