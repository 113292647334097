@import '~leaflet/dist/leaflet.css';
#leafletMap {        
    width: 64%;
    /* max-height: 1000px; */
    height: 100%;
    /*This is to prevent the nav menu item from being covered by the map*/
    z-index: 0; 
}

.leaflet-tile-loaded {
    /* filter: grayscale(100%); */
}

.referral-map-icon {
    filter: hue-rotate(520deg) brightness(114%) contrast(101%);
}


.referral-map-popup {
    max-width: 203px;
    max-height: 110px;
    font-size: 16px;
}

.referral-map-popup .cbit-small-header.text-ellipse,
.referral-map-popup div {       
    -webkit-line-clamp: 1
}

.referral-map-popup div{
    margin-bottom: 4px;
}

.referral-popup-address {
    margin-bottom: 0 !important;
}

.referral-popup-address div:last-child {
    margin-bottom: 0;
}

.referral-map-popup .cbit-small-header {
    font-size: 16px;
}

/*@media only screen and (max-width: 866px) {*/
/*    #leafletMap {*/
/*       width: 100%;*/
/*       height: 50%;*/
/*    }*/
/*  }*/