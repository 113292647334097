
.admin-content-menu-item, 
.admin-content-menu-item-custom {
  border: 1px solid var( --cbit-cadet-blue);
  border-radius: 4px;
  background-color: #fff;
  padding: 0;
}

.admin-content-menu-item,
.admin-content-menu-item-loading {
  width: 328px;
  height: 72px;
  display: flex;
}

.admin-content-menu-item-custom {
  height: 106px;
  display: flex;
  align-items: center;
}

.admin-content-menu-item-loading {
  background-color: transparent;
  border: none;
}

.admin-content-menu-title {
  text-transform: uppercase;
  color: var(--cbit-bondi-blue);
  font: normal normal 600 24px/42px 'Open Sans';
}

.admin-content-menu-text {
  color: var(--cbit-black);
  font: normal normal normal 18px/32px 'Open Sans';
  margin-bottom: 0;
}

.admin-content-menu-item-text {
  color: var(--cbit-black);
  font: normal normal normal 16px/24px var(--cbit-font);
  text-align: left;  
  height: auto;
  flex-grow: 1;
}

.content-label {
  font: normal normal 600 18px/32px var(--cbit-font);
  letter-spacing: 0.54px;
  color: var(--cbit-dark-blue);
  margin: 12px 0 0;
}

.content-menu-custom-container {
  display: flex;
  flex-direction: column;
  justify-content: left;  
  height: 100%;
}

.content-menu-custom-container,
.admin-content-menu-item-subheading {  
  display: flex;
  margin: 0 0 16px;
  height: auto;
  vertical-align: top;
}

.component-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 56px 36px;
}

.component-list ul {
  list-style: none;
  padding: 0;
}

.plus-icon {
  display: block;
  margin: 12px 16px;
  width: 48px;
  height: 48px;
}
.admin-content-menu-item-subheading {
  color: var(--cbit-bondi-blue);
  font: normal normal 600 18px/42px 'Open Sans';
  margin: 12px 0;
  width: 100%;
  height: 24px;
  text-align: left;
}
