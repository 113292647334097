.review-section {
    /* height: var(--cbit-full-height); */
}

.review-section .btn-cbit-link
{
    text-decoration: underline;
}

.review-tabs.Mui-selected {
    color: var(--cbit-orange) !important;
}

.review-viewer textarea{
    resize: none;
}