h2.past-webinars-header {
    font-size: 32px;
    font-weight: normal;
    color: var(--cbit-dark-blue);
    margin-bottom: 24px;
}

.past-webinars-container {
    display: flex;
    flex-wrap: wrap;
    width: 1100px;
    gap: 16px 16px;
}

.past-webinars-no-webinars-container {
    width: 1100px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
}

h3.past-webinars-no-webinars-header {
    color: var(--cbit-dark-blue);
    font-weight: 600;
    font-size: 24px;
    text-align: center;
}

.past-webinar-item {
    width: 350px;
    display: flex;
    flex-direction: column;
    padding: 24px;
    border: 2px solid #AFC6CC;
}

.past-webinar-item-title-container {
    width: 100%;
    min-height: 40px;
    max-height: 100px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-bottom: 16px;
}

h4.past-webinar-item-title {
    color: var(--cbit-dark-blue);
    font-weight: bold;
    font-size: 24px;
}

h5.past-webinar-item-info-title {
    color: #353B3C;
    font-weight: 700;
    font-size: 16px;
    max-width: 700px;
}

h5.past-webinar-item-info-title.instructor {
    text-transform: capitalize;
}

.past-webinar-item-description-container {
    padding-bottom: 40px;
    /*min-height: 120px;*/
    height: 150px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-bottom: 16px;
}

p.past-webinar-item-description {
    color: #353B3C;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 16px;
}

.past-webinar-item-btn-container {
    width: 100%;
}

.past-webinar-item-btn {
    width: 100%;
    padding: 12px 0 10px;
    font-weight: 500;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    border: none;
    border-radius: 4px;
    background-color: #FF6F36;
    color: #FFFFFF;
}

a.past-webinar-item-btn {
    display: inline-block;
    text-align: center;
}

.past-webinar-item-btn:hover,
a.past-webinar-item-btn:hover {
    cursor: pointer;
    background-color: #bd5127;
    text-decoration: none;
    color:#fff;
}

.past-webinars-load-more-container {
    width: 1100px;
    display: flex;
    margin: 36px 0 0;
}

.past-webinars-load-more-button {
    background: transparent;
    color: var(--cbit-blue);
    border: 2px solid var(--cbit-blue);
    border-radius: 4px;
    padding: 8px 20px 6px;
    text-transform: uppercase;
    font-weight: 600;
    margin: 0 auto;
}

.past-webinars-load-more-button:hover {
    background-color: #D6EFF5;
}

/* past webinars modal */
.past-webinars-modal {
    max-width: 1040px;
    height: 770px;
}

.past-webinars-modal-container {
    background-color: var(--cbit-transparent-black);
    color: #fff;
    position: relative;
    border-radius: 12px !important;
}

.past-webinars-modal .modal-content {
    border-radius: 12px !important;
}

.past-webinars-modal .modal-body {
    padding: 0 !important;
}

.past-webinars-modal-video-container {
    width: 100%;
    height: 588px;
}

.past-webinars-modal-video {
    width: 100%;
    height: 588px;
}

.past-webinars-modal-close-button {
    background: #fff;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    position: absolute;
    top: 16px;
    right: 16px;
}

.past-webinars-modal-close-button:hover {
    cursor: pointer;
}

.past-webinars-modal-close-button svg {
    width: 36px;
    height: 36px;
    position: absolute;
    top: 2%;
    left: 2%;
    fill: #353B3C;
}

.past-webinars-modal-text-container {
    padding: 24px 32px;
}

h3.past-webinars-modal-title {
    font-size: 24px;
}

h5.past-webinars-modal-subtext {
    font-size: 14px;
    font-weight: 300;
    font-style: italic;
    margin-bottom: 16px;
}

span.past-webinars-instructor {
    text-transform: capitalize;
}

span.past-webinars-modal-dot {
    font-weight: 700;
}

p.past-webinars-modal-text {
    font-size: 16px;
    font-weight: 400;
}