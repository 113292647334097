.consultations-container,
.book-consultations-container {
    background-color: #fff;
    width: 100%;    
    /* overflow: auto;     */
}
.consultation-header {
    font-size: 32px;
    font-weight: normal;
    margin-bottom: 32px;
    color: var(--cbit-dark-blue);
}
.consultation-sub-header {
    color: var(--cbit-blue);
    margin-bottom: 28px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 24px;    
}

.available-consultants-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 16px 16px;
}

.consultant-booking-item {
    border: 1px solid var(--cbit-cadet-blue);
    border-radius: 4px;
    /* max-width: 634px; */
    width: 590px;
    min-height: 267px;
    /* flex: 1 0 45%; */
    display: flex;
    padding: 24px;
    grid-column-gap: 24px;
    -webkit-column-gap: 24px;
    column-gap: 24px;
    /* flex-shrink: 3; */
}

.consultant-booking-item .empty-profile-image {
width: 125px;
height: 125px;
border-radius: 50%;
background-color: var(--cbit-pastel-blue);
}

.consultant-profile-img-container {
width: 125px;
border-radius: 50%;
}

.consultant-profile-img-container img {
width: 100%;
border-radius: 50%;
}

.consultant-name {
color: var(--cbit-dark-blue);
font-weight: normal;
font-size: 32px;
max-width: 700px;
text-transform: capitalize;
margin-bottom: 4px;
}

.consultant-booking-name {
color: var(--cbit-dark-blue);
font-weight: bold;
font-size: 24px;
line-height: 32px;
max-width: 435px;
max-height: 32px;
display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
overflow: hidden;
text-overflow: ellipsis;
text-transform: capitalize;
}

.consultant-booking-title {
margin: 4px 0;
color: var(--cbit-cadet-blue);
height: 100%;
max-height: 24px;
display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
text-overflow: ellipsis;
overflow: hidden;
text-transform: capitalize;

}

.consultant-booking-bio {
font-size: 16px;
line-height: 28px;
text-align: left;
max-height: 84px;
min-height: 84px;
display: -webkit-box;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;
text-overflow: ellipsis;
overflow: hidden;
margin: 12px 0 24px 0;
max-width: 700px;
}

.consultant-action-container {
width: 227px;
height: 42px;
margin: 12px 0;
}

.btn-consultation-action {
font-weight: 600;
line-height: 34px;
width: 100%;
height: 100%;

}

