.available-session-item {
    cursor: pointer;
    width: 100%;
    min-height: 66px;
    text-align: center;    
    display: flex;
    flex-direction: column;    
    text-transform: uppercase;
    border: 2px solid var(--cbit-blue);
    border-radius: 3px;
}

.available-session-item.full-session {
    background-color: var(--cbit-water-blue);
    opacity: 0.5;
}

.available-session-item:hover {
    background-color: var(--cbit-water-blue);
}

.non-available-session-item {
    text-align: center;
    line-height: 28px;
}

.available-consultation-status {
    border-top: 2px solid var(--cbit-blue);
    background-color: var(--cbit-blue);
    color: #fff;
}

.available-consultation-time {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1.3px;
    color: var(--cbit-blue);
    padding: .625rem 0;
}