.attendance-legend i {
    font-size: 20px;    
}

.attendance-legend .present-icon,
.present-label {
    color: var(--cbit-yellow-green);
}

.attendance-legend .absent-icon,
.absent-label {
    color: var(--cbit-coral-red);
}

.attendance-legend .late-icon,
.late-label {
    color: var(--cbit-orange);
}
