.referral-container {
    gap:48px;
}
.referral-upload-container.cbit-column {
    width: 192px;
}

.referral-inputs.cbit-column {
    flex-direction: column;
}

.referral-edit-btn-container button {
    height:44px;
}

.form-btn-container .referral-btn-discard.btn-cbit-minor {
    width: 234px;
}

.cbit-column.referral-address-field {
    margin-bottom: 15px;
}