.content-management {
    margin: 24px 32px 32px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;    
}
.content-management a:hover {
    text-decoration: none;
}
.content-mana-left.cbit-dialog {
    border-radius: 0;
    flex: 1;        
    padding: 36px 40px;
    max-width: 1234px;
}
.content-mana-header.cbit-dashboard-header {
    font-size: 44px;
    margin-bottom: 16px;
}
.content-mana-desc {
    margin-bottom: 2.25rem;
}
.content-mana-actions {
    display: flex;
    gap: 16px;    
    flex-wrap: wrap;
    flex-direction: row;        
    width: 100%;
}
.admin-action-item {
    min-width: 313px;
    /* max-width: 374px; */
    width: 33%;
    flex-grow:1;
    min-height: 189px;
    background-color: var(--cbit-light-pastel-blue);    
}
.admin-action-item:hover{
    background-color:var(--cbit-hover-state);
}
.admin-action-link{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap:12px;
    height: 100%;
    width: 100%;
}
.admin-action-inner {
    box-sizing: border-box;    
    padding: 24px 24px 0;
}
.admin-action-header.cbit-dashboard-header {
    font-size: 30px;
    line-height: 40px;
    text-transform: uppercase;
    margin-bottom: 12px;    
}
.admin-action-content {
    color: var(--cbit-black);    
}
.btm-banner{
    background-color: #C4E1E8;
    height: 10px;
    width: 100%;
    align-self: flex-end;
}
.content-mana-right{
    max-width: 606px;
    width: 100%;
    flex: 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
    
}
.user-management.action-full-item.cbit-container {
    background-color: var(--cbit-dark-blue);    
}
.user-management.action-full-item.cbit-container,
.user-management .admin-action-header.cbit-dashboard-header {
    color: #fff;
}

/**
Because the designer gave us a badly cropped user management icon
see icon-user-management.png file in resources
**/
.user-management .action-icon-container img {
    filter: hue-rotate(162deg);
}

@media (max-width: 1638px) {
    .admin-action-item {
        max-width: none;
    }
}