.referral-submitted {
    width: 100%;
}

.referral-submitted-text {
    width: 55%;
    margin-bottom: 32px;
}

.referral-submitted .link-to {
    text-transform: capitalize;
    color: var(--cbit-blue);
}