.admin-view-item
{
    /* display: flex;
    flex-direction: column;
    align-items: center; */
    margin-top: 26px;
    width: 100%;
    padding: 0  179px 0 89px;
}
.admin-video-player-wrapper {
    width: auto;
    height: auto;
}

.admin-video-player, .image-container {
    height: auto !important;
    aspect-ratio: 16/9;
}

.admin-video-description,
.admin-view-item-text *
 {
    font-family: var(--cbit-font) !important;
    font-size: 18px;
    line-height: 32px;    
    margin: 28px 0;
}

.admin-answer-choice-text { 
    font-size: 18px;
    line-height: 32px;
}

.admin-view-image-container img{
    max-width: 100%;
    /* max-height: 346px; */
}

.radio-btn-admin {
    margin-right: 16px;
    height: 16px;
    width: 16px;
}