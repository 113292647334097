.user-table-header {
    color: var(--cbit-blue);
    text-transform: uppercase;    
}

.cbit-data-grid {
    font-family: var(--cbit-font) sans-serif !important;
    font-size: 1rem !important;
}

.user-management-actions {
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: center;
    align-items: center;
}


.table-action-edit {
    width: 21px;
}

.table-action-edit img,
.table-action-delete img {
    width: 100%;
}


.table-action-delete {
    width: 22px;
}

.btn-cbit-link.btn-user-table {
    text-transform: capitalize;
    text-decoration: underline;
    font-size: 16px;
    font-weight: normal;
}

.btn-cbit-link.btn-user-table:hover {
    text-decoration: underline;
}

.expire-test-user {
    font-size: 22px;
}

.expire-test-user .late-icon {
    color: var(--cbit-coral-red);
    width: 100%;
}
