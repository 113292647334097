.learning-content-viewer-panel {
  background-color: lightgreen;
  width: 830px;
  height: 800px;
  padding: 1rem;
  border: 1px solid black;
  border-radius: 5px;
  overflow-y: auto;
}
.learning-content-view-header {
  width: 100%;
  height: 80px;
  text-align: left;
  font-size: 24px;
}
.learning-content-header-label {
  padding-top: 20px;
  padding-left: 20px;
}
.learning-content-view-panel{
    width:100%;
    height: calc(100vh - 80px);
    padding:0px;
}
.contnet-view-body {
}
.content-view-footer button {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0 32px 16px 0;
}

