
.btn-primary.dropdown-image-selected {    
    width: auto;
    height: auto;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: transparent !important;
}

.btn-primary.dropdown-image-selected,
.btn-primary.dropdown-image-selected:hover,
.show > .btn-primary.dropdown-toggle.btn-primary.dropdown-image-selected,
.btn-primary.btn-primary.dropdown-image-selected:active,
.show > .btn-primary.dropdown-toggle.btn-primary.dropdown-image-selected:focus,
.dropdown-image-selected.btn-primary:focus {
    background-color: transparent !important;
}

.show > .btn-primary.dropdown-toggle.btn-primary.dropdown-image-selected:focus {    
    box-shadow: none;    
}

.dropdown-image-selected.btn-primary:active { 
    box-shadow: none;      
}

.dropdown-image-selected.btn-primary:focus {
    box-shadow: none;          
}

.btn-dropdown-image .btn-primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;      
    
}

.btn-dropdown-image .dropdown-item.active,
.btn-dropdown-image .dropdown-item:active {
    background-color: var(--cbit-pale-blue);
    color: var(--cbit-black);
}

.dropdown-toggle::after {
    color: var(--cbit-black);
}

.dropdown-item-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.dropdown-image-container {
    width: 30px;
}
.dropdown-image-container img {
    width: 100%;
}

