.social-connection-single-post-main-container {
    width: 100%;
    margin: 0 auto;
}

    .social-connection-single-post-page-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 32px;
    }

    .social-connection-go-back a {
        color: var(--cbit-cadet-blue);
        font-weight: 600;
        line-height: 34px;
        width: 100%;
        max-width: 226px;
    }

    .social-connection-go-back a:visited {
        color: var(--cbit-dark-blue);
    }

.hide {
    display: none;
}

/* Post with Reply Page */
.social-connection-single-post {
    max-width: 1300px;
    display: flex;
    margin: 0 0 0 32px;
    padding: 16px 12px;
    border-bottom: 1px solid var(--cbit-cadet-blue);
}

    .social-connection-single-post-user-image {
        width: 150px;
        height: 150px;
        display: flex;
        justify-content: flex-end;
    }

    .social-connection-single-post-user-image-reply {
        width: 80px;
        height: 80px;
        border-radius: 50%;
    }

.social-connection-single-post-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 24px;
}

    .social-connection-single-post-header {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 16px;
        margin-bottom: 8px;
    }

    h5.social-connection-single-post-username {
        font-weight: 600;
        font-size: 20px;
        color: var(--cbit-dark-blue);
    }

    h5.social-connection-single-post-date {
        color: #656a6b;
        font-weight: normal;
        font-size: 16px;
        font-style: italic;
    }

    .social-connection-single-post-body {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .social-connection-single-post-title-container {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    h4.social-connection-single-post-title {
        font-weight: 600;
        font-size: 24px;
        margin-bottom: 16px;
        color: var(--cbit-dark-blue);
    }

    .social-connection-single-post-text-container {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    p.social-connection-single-post-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--cbit-dark-blue);
    }

/* AddReply */
.social-connection-post-add-reply-btn-container {
    width: 100%;
}

    .btn-social-connection-add-reply,
    .btn-social-connection-add-reply-submit {
        width: 100%;
        max-width: 160px;
        font-weight: 600;
        line-height: 34px;
        padding: 8px 20px;
        cursor: pointer !important;
        text-transform: uppercase;
    }

    .btn-social-connection-add-reply:hover {
        background-color: var(--cbit-dark-blue) !important;
        color: #fff;
    }

.social-connection-post-add-reply-container {
    width: 100%;
    max-width: 1350px;
    display: flex;
    margin: 0 auto;
    padding: 24px 12px;
}

    .social-connection-post-add-reply-center {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: 10px 24px;
    }

    .social-connection-post-add-reply-warning-text-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 4px;
    }

   p.social-connection-post-add-reply-warning-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
    }

    .social-connection-post-add-reply-center textarea {
        width: 100%;
        flex: 1;
        height: 100px;
        border: 1px solid var(--cbit-cadet-blue);
        border-radius: 4px;
        padding: 8px;
        margin-bottom: 16px;
    }

    .social-connection-post-add-reply-center textarea:focus {
        outline: none;
        border: 1px solid var(--cbit-dark-blue);
    }

    .social-connection-post-add-reply-textarea {
        width: 100%;
        height: 100%;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: var(--cbit-dark-blue);
        border: slategrey 1px solid;
        border-radius: 4px;
        padding: 16px;
        outline: none;
    }

    .social-connection-post-add-reply-textarea:focus {
        border: var(--cbit-dark-blue) 1px solid !important;
    }

    .social-connection-post-add-reply-btn-container {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

/* Reply */
.social-connection-single-post-reply-container {
    max-width: 1222px;
    display: flex;
    margin: 0 0 0 161px;
}

    .social-connection-single-post-reply {
        width: 100%;
        max-width: 1138px;
        display: flex;
        flex: 1;
        margin: 0 0 0 36px;
        padding: 16px 12px;
        border-bottom: 1px solid var(--cbit-cadet-blue);
    }

    .social-connection-single-post-reply-user-image {
        width: 100px;
        height: 100px;
        display: flex;
        justify-content: flex-end;
    }

    .social-connection-single-post-reply-body {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-left: 24px;
    }

    .social-connection-single-post-reply-header {
        width: 100%;
        display: flex;
        gap: 16px;
        align-items: center;
        margin-bottom: 8px;
    }

    h5.social-connection-single-post-reply-username {
        font-weight: 600;
        font-size: 20px;
        line-height: 34px;
        color: var(--cbit-dark-blue);
    }

    p.social-connection-single-post-reply-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--cbit-dark-blue);
    }

/*  Deleted Post  */
.social-connection-single-post.deleted,
.social-connection-single-post-reply.deleted {
    border-bottom: 1px solid var(--cbit-light-gray);
}

    .social-connection-single-post-user-image-reply.deleted {
        filter: grayscale(1);
    }

    h5.social-connection-single-post-username.deleted,
    h5.social-connection-date.deleted,
    h4.social-connection-single-post-title.deleted,
    p.social-connection-single-post-text.deleted {
        color: grey;
    }

/* Deleted Reply */
.social-connection-single-post-reply.deleted {
    border-bottom: 1px solid var(--cbit-light-gray);
}

    .social-connection-single-post-user-image-reply.deleted {
        filter: grayscale(1);
    }

    h5.social-connection-single-post-reply-username.deleted {
        color: grey;
    }

    h5.social-connection-date.deleted {
        color: grey;
    }

    h5.social-connection-date.deleted span {
        /*color: red;*/
        margin-left: 10px;
        font-style: normal;
    }

    p.social-connection-single-post-reply-text.deleted {
        color: grey;
    }