h2.my-registrations-header {
    font-size: 32px;
    font-weight: normal;
    color: var(--cbit-dark-blue);
    margin-bottom: 32px;
}

.my-registrations-container {
    display: block;
    width: 1100px;
}

.my-registrations-no-webinars-container {
    width: 1100px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
}

h3.my-registrations-no-webinars-header {
    color: var(--cbit-dark-blue);
    font-weight: 600;
    font-size: 24px;
    text-align: center;
}

.my-registrations-item {
    width: 1088px;
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid #AFC6CC;
    margin-bottom: 24px;
}

h4.my-registrations-item-title {
    color: var(--cbit-dark-blue);
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 16px;
}

h5.my-registrations-item-info-title {
    color: #353B3C;
    font-weight: 700;
    font-size: 16px;
    max-width: 700px;
}

h5.my-registrations-item-info-title.instructor {
    text-transform: capitalize;
}

    h5.my-registrations-item-info-title span {
        font-weight: normal;
    }

    h5.my-registrations-item-info-title a {
        color: #353B3C;
        text-decoration: underline;
    }

.my-registrations-item-info-container {
    width: 100%;
    overflow-wrap: break-word;
    word-wrap: break-word;
}

.my-registrations-item-description-container {
    width: 100%;
    min-height: 50px;
    max-height: 100px;
    margin-bottom: 16px;
}

p.my-registrations-item-description {
    color: #353B3C;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    margin: 16px 0;
}

.my-registrations-item-btn-container {
    width: 100%;
    padding-bottom: 32px;
}

.my-registrations-item-btn {
    padding: 10px 20px 8px;
    font-weight: 600;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    background-color: transparent;
    color: var(--cbit-blue);
    border: var(--cbit-blue) 2px solid;
    border-radius: 4px;
}

.my-registrations-item-btn:hover {
    background-color: #D6EFF5;
    color: #00A3C1;
    border: #00A3C1 2px solid;
}

.my-registrations-item-btn.disabled {
    background-color: #D6EFF5;
    color: #ABD3DA;
    border: #ABD3DA 2px solid;
}


.my-registrations-load-more-container {
    width: 1100px;
    display: flex;
    margin: 36px 0 0;
}

.my-registrations-load-more-button {
    background: transparent;
    color: var(--cbit-blue);
    border: 2px solid var(--cbit-blue);
    border-radius: 4px;
    padding: 8px 20px 6px;
    text-transform: uppercase;
    font-weight: 600;
    margin: 0 auto;
}

.my-registrations-load-more-button:hover {
    background-color: #D6EFF5;
}

/* my registrations modal */
.my-registrations-modal {
    max-width: 720px;
}

h1.my-registrations-modal-title {
    color: var(--cbit-blue);
    font-weight: 400;
    font-size: 24px;
    text-transform: uppercase;
    margin-bottom: 24px;
}

.my-registrations-modal-container {
    padding: 40px;
}

p.my-registrations-modal-text {
    color: #353B3C;
    font-weight: normal;
    font-size: 18px;
}

.my-registrations-modal-btn-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    padding-top: 30px;
}

.my-registrations-modal-btn {
    width: 210px;
    padding: 10px 0 8px;
    background-color: var(--cbit-orange);
    color: #FFFFFF;
    border: none;
    text-transform: uppercase;
    font-weight: 400;
    border-radius: 4px;
    font-size: 18px;
    letter-spacing: 0.1em;
}

.my-registrations-modal-btn.cancel {
    background-color: transparent;
    border: var(--cbit-blue) 2px solid;
    color: var(--cbit-blue);
}

.my-registrations-modal-btn:hover {
    cursor: pointer;
    background-color: #bd5127;
}

.my-registrations-modal-btn.cancel:hover {
    background-color: #D6EFF5;
    color: #00A3C1;
}