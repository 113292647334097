.referral-profile-info .referral-image {
    width: 192px;
}

.referral-info-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 560px;
}

.referral-contact-list, 
.referral-licensed-states-list {
    padding: 0;
    list-style: none;

}

.referral-profile-btn-container {
    margin-top: 16px;
}

.referral-profile-btn-container .btn-cbit-minor {
    height: 44px;
    width: 192px;

}

/* .referral-info-container .cbit-small-header, */
.referral-info-container .italicize-text,
.referral-licensed-states-list,
.referral-contact-state {
    text-transform: capitalize;
}


.referral-bio-container p {
    font-size: 16px;
}

/*@media only screen and (max-width: 955px) { */
/*    .referral-profile-body.referral-profile-info {*/
/*        flex-direction: column;*/
/*    }*/

/*    .referral-info-container {*/
/*        width: 100%;*/
/*    }*/
/*}*/