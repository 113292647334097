.session-item {
    min-height: 333px;
    height: 100%;    
    border-bottom: 2px solid var(--cbit-pastel-blue);
    display: flex;
    gap: 32px;
    margin-bottom: 33px;
}

div.session-item:last-of-type {
    border-bottom: none;
} 

.consultant-info-container-left.session-profile-icon {    
    height: 100%;
    width: 100%;
    max-width: 125px;
}

.consultant-info-container-right.session-profile-info {
    height: 100%;
    width: 100%;
}

.session-profile-info .consultation-sub-header {
    margin-bottom: 0;
}

.session-link.btn-cbit-link {
    text-transform: capitalize;
}

.btn-cbit-link:hover.session-link:hover {
    text-decoration: none;
    color: var(--cbit-blue);
}
