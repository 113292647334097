body{
  margin: 0;
  padding: 0;
}
.admin-nav {
  height: calc(100vh - 80px);
  width: 400px;

}

/*
TODO remove from root**/
.admin-nav * {
    -webkit-box-sizing: initial !important;
    -moz-box-sizing: initial !important;
         box-sizing: content-box !important;
}

.admin-nav-header-container {
  width:100%;
  height:200px;
  background-color: #00516B;
}
.admin-nav-header {
  padding: 59px 24px 24px 24px;
  display: flex;
  flex-direction: column;
}

.admin-accordion-spinner {
  padding: 0 0 0 24px;
}

.admin-nav-title-container {
  width: 100%;
  min-height: 55px;
  display: flex;
}
.admin-nav-title {
  margin: 0;
  font: normal normal 300 40px/44px "Open Sans";
  letter-spacing: 0px;
  color: #FFFFFF;
  align-self: center;
}

.admin-nav-text-container {
  margin-top: 16px;  
  min-height: 46px;
}

.admin-nav-text {
  margin: 0;
  padding: 0;
  text-align: left;
  font: normal normal normal 16px/24px "Open Sans";
  letter-spacing: 0px;
  color: #9AB5BF;
  height: 100%;

}

.admin-nav-body {
  height: calc(100vh - 280.1px);
  overflow-y:scroll;
  overflow-x: hidden;
  /* background-color: #EBF3F5; */
}

.admin-chapter-item {
  min-height: 63px;
  background-color: #00A3C1;
  border-bottom: 1px solid #17B4D1;
  display: flex;
}

.admin-chapter-item, 
.admin-lessons > * {
  cursor: pointer;
}

.chapter-content-container {
  min-height: 24px;
  padding: 10px 0 10px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.admin-chapter-text {
  text-align: left;
  font: normal normal 600 18px/20px "Open Sans";
  letter-spacing: 0.9px;
  color: #FFFFFF;
  text-transform: uppercase;
  width: 284px;
}

.admin-chapter-text:hover {
  cursor: pointer;
}

.chapter-lesson-amount {
  text-align: right;
  font: normal normal normal 14px/42px "Open Sans";
  letter-spacing: 1.4px;
  color: #DEE5E7;
  margin: 0 0 0 12px;
  width: 30px;
}

.AdditionalVideoContainer .chapter-lesson-amount {
  text-align: right;
  font: normal normal normal 13px/42px "Open Sans";
  letter-spacing: 1.4px;
  margin: 0 0 0 12px;
  width: 58px;
}

.admin-icon-container {
  height: 100%;
  display: flex;
  width: 12px;
  margin: 0 11.5px 0 9px;
}

.admin-icon-container img {
  align-self: center;
}

.admin-caret-left img {
  height: 12px;
  width: 9px;
}

.admin-caret-down img {
  height: 9px;
  width: 12px;
}

/**
Lessons && sub lessons
**/
.admin-lessons {
    background-color: var(--cbit-inner-accordion-color);
}

.admin-lesson-group-item {
  padding-left: 24px;  
}

.admin-lesson-item {
  padding-left: 24px;  
  min-height: 56px;
  display: flex;
}

.admin-lesson-content {
  width: 100%;
  align-self: center;
  display: flex;
  padding: 10px 0;
  height: 100%;
}

.lesson-type-icon {  
  width: 22px;
  display: flex;
  margin-right: 16px;
}

.lesson-type-icon img {
  width: 100%;
  height: 22px;
  align-self: center;  
}

.admin-lesson-text,
.admin-lesson-duration {
  font: normal normal normal 16px/20px "Open Sans";
  letter-spacing: 0px;
  color: #353B3C;
}

.admin-lesson-text {
  width: 243px; /**Use to be 246px*/
  align-self: center;
}

.admin-lesson-duration {
  font-size: 12px;
  align-self: center;
  margin-left: 12px;
}

.admin-lessons .chapter-lesson-amount {
  color: #353B3C;
  margin-left: 13px;
  align-self: center;
}

.admin-lessons .admin-icon-container{
  align-self: center;
}

.sub-lesson-item {
  padding-left: 34px;
}
.admin-lesson-group-items .admin-lesson-content {
  border-left: 1px solid #353B3C;
  padding-left: 23px;
  min-height: 36px;
  width: auto;
}

.admin-lesson-group-items .admin-lesson-text {
  width: 214px;
}

.admin-lesson-group-items .admin-lesson-duration {
  margin-left: 7px;
}

/*
  Selected styles
*/
.admin-selected,
.admin-lesson-group-item:hover,
.admin-lesson-item:hover{
  background-color: #BEE7EE;
}

.admin-selected .admin-lesson-text {
  font-weight: bold;
}

.chapter-content-container.admin-selected,
.admin-chapter-item:hover  {
    background-color: #00BBDE;
}

.remove-background-hover:hover {
  background-color: #EBF3F5;
}

.chapter-lesson-amount-container.mobile,
.admin-lesson-text-container.mobile,
.admin-lesson-content.mobile {
  display: none;
}