.upload-pdf-container {
    display: flex;
}

.file-preview-container {
    font-weight: 400;
    align-self: center;
    display: flex;
    height: 100%;
    font-size: 18px;
}

.file-preview-container .btn-cbit-icon {
    border: none;
    width: 33px;
    margin-left: 5px;
    line-height: 16px;
    align-self: flex-start;
}
.file-preview-container img {
    width: 100%;
}
.uploader-error-text {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}

.additional-content-form .upload-instructions {
    width: 100%;
    height: auto;
    min-height: 148px;
}

.additional-content-form .file-preview-container {
    width: 70%;
    display: inline-block;
    overflow-wrap: break-word;
}

.delete-button {
    width:16px;
    margin-top:18px;
    cursor:pointer;
}
