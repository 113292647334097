.empty-editor-btns-container,
.editor-btns-container{
    width: 320px;
    height: 44px;
}

.editor-btns-container {
    
    justify-content: flex-end;
    display: flex;
    gap: 32px;
}