
.product-header.cbit-header {    
    font-weight: 600;
    color: var(--cbit-blue);
    margin: 0;
}

.product-info-container {
    min-height: 164px;
  }
  
  .product-price {
    margin-top: 12px;
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
  }
  
  .product-description {
    margin: 12px 0 24px;    
  }