
.quill > .ql-container > .ql-editor.ql-blank::before {
  color: var(--cbit-pastel-blue) !important;
  font: normal normal normal 18px/32px var(--cbit-font);
  
}

.ql-toolbar, .ql-container {
  border: 2px solid var( --cbit-cadet-blue) !important;
  
}

.ql-container {
  border-top: none !important;
  border-radius: 0 0 7px 7px;
}

.ql-editor, 
.ql-editor p{
  font: normal normal normal 18px/32px var(--cbit-font) !important;
}

.ql-editor {
  overflow-y: scroll;
  resize: vertical;
  min-height: calc(320px - 44.36px); 
  height: calc(320px - 44.36px); 
  font-style: normal;
}



.ql-toolbar {
  border-radius: 7px 7px 0 0;
}