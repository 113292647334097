.review-viewer .btn-cbit-link{
    text-decoration: underline;
}

.review-viewer-inner {
    display: flex;
    flex-direction: column;
    gap:16px;
}

.review-viewer .review-btn-container,
.review-viewer label {
    margin-bottom: 0;
}

.review-viewer .rating-label::before {
    content: '★';
}

.review-viewer .rating-label {
    color: var(--cbit-orange);
    cursor: unset;
    margin: 0;
    font-size: 2rem;
}

.review-viewer .rating-container{
    display: flex;
    gap:.625rem;
    margin: 0;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
}
